import { useEffect } from "react";
// import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getGroups } from "../../apiCalls/groupCalls";
import { Box, styled } from "@mui/material";
import GroupDemo from "../GroupDemo";

const BoxGeneralStyled = styled(Box)({
    display: "flex",
    width: "100%",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    overflowY: "visible"
});

const BoxStyled = styled(Box)(({ theme }) => ({
    margin: "0px",
    [theme.breakpoints.down("sm")]: {
        fontSize: "16px"
    }
}));

const BoxGroupsStyled = styled(Box)(({ theme }) => ({
    display: "flex",
    width: "90%",
    flexWrap: "wrap",
    justifyContent: "center",

    [theme.breakpoints.down("sm")]: {
        width: "100%"
    }
}));

const AllGroups = ({accessToken}) => {

    const { data, loading } = useSelector(state=>state.group.list);
    const dispatch = useDispatch();
    const navigate = useNavigate();


    useEffect(()=>{
        if(accessToken){
            getGroups(accessToken, dispatch);
        }
    }, [accessToken, dispatch]);

    const handleClick = (id) => {
        navigate(`/turmaDashboard/${id}`);
    }

    return (
        <BoxGeneralStyled>
            <BoxStyled>GROUPS</BoxStyled>
            <BoxGroupsStyled>
                {   
                    !loading && data.map(g=>(
                        <GroupDemo 
                            key={g.id} 
                            clickEvent={handleClick}
                            group={g} 
                        />
                    ))
                }
            </BoxGroupsStyled>
        </BoxGeneralStyled>
    )
}

export default AllGroups
