import { useEffect } from "react";
import TurmaDemo from "../TurmaDemo";
import { Box, styled } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getTeacherGroups, getAlumnGroups } from "../../apiCalls/groupCalls";
import Skeleton from "../Skeleton";

const BoxGeneralStyled = styled(Box)({
    display: "flex",
    width: "100%",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    overflowY: "visible"
});

const BoxStyled = styled(Box)(({ theme }) => ({
    margin: "0px",
    [theme.breakpoints.down("sm")]: {
        fontSize: "16px"
    }
}));

const BoxGroupsStyled = styled(Box)(({ theme }) => ({
    display: "flex",
    width: "90%",
    flexWrap: "wrap",
    justifyContent: "center",

    [theme.breakpoints.down("sm")]: {
        width: "100%"
    }
}));

const TeacherGroups = ({userVariables, accessToken}) => {

    const { data, loading } = useSelector(state=>state.group.list);
    const { success } = useSelector(state=>state.notification.notification);
    const dispatch = useDispatch();

    useEffect(() => {
        if(!data.loading && userVariables.id) {
            if(userVariables.is_teacher){
                getTeacherGroups(accessToken, dispatch, userVariables.id);
            } else {
                getAlumnGroups(accessToken, dispatch, userVariables.id);
            }
        }
    }, [data.loading, accessToken, userVariables.id, userVariables.is_teacher, dispatch, success]);

    return (
        <BoxGeneralStyled>
            <BoxStyled component="h2">GRUPOS</BoxStyled>
            <BoxGroupsStyled>
                {
                    !loading
                    && data.map(g => {
                        return <TurmaDemo 
                            key={g.id}
                            group={g}
                            userVariables={userVariables}
                            dispatch={dispatch}
                        />
                    })
                }
                {
                    (!loading && data.length === 0)
                    && <Box>NÃO ALOCADO À UM GRUPO!</Box>
                }
                {
                    loading
                    && <Skeleton type="group" />
                }
            </BoxGroupsStyled>
        </BoxGeneralStyled>
    )
}

export default TeacherGroups
