import { useState } from "react";
//import AlunoResults from "../subpages/AlunoResults";
import MyDatas from "../components/Tabs/MyDatas";
import { Box, Tab, Tabs, styled } from "@mui/material";
import Footer from "../components/Footer";
//import AlumnActivities from "../components/Tabs/AlumnActivities";
import Groups from "../components/Tabs/Groups";
import InviteDialog from "../components/dialogs/invite/invite.dialog";
import { useDispatch } from "react-redux";

const BoxStyled = styled(Box)({
    display: "flex",
    width: "100%",
    height: "100%",
    flexDirection: "column",
    overflowY: "visible",
    justifyContent: "space-between"
});

export default function HomeAluno({userVariables, accessToken}) {

    const [activated, setActivated] = useState(0);
    const dispatch = useDispatch();

    const handleChange = (e, newValue) => {
        setActivated(newValue);
    }

    return(
        <BoxStyled>
            <InviteDialog   
                dispatch={dispatch}
                accessToken={accessToken}
            />
            <Box mb={0.5}>
                <Tabs value={activated} onChange={handleChange} centered>
                    <Tab label="GRUPOS" />
                    <Tab label="MEUS DADOS" />
                </Tabs>
                {
                    activated === 0
                    && <Groups 
                        userVariables={userVariables}
                        accessToken={accessToken}
                    />
                }
                {
                    activated === 1 && <MyDatas userVariables={userVariables} accessToken={accessToken} />
                }
            </Box>
            <Footer />
        </BoxStyled>
        
    )
}