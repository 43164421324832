import firebase from "firebase";

const firebaseConfig = {
    apiKey: "AIzaSyDmQhK1MHYC3rjUO7FgqmpMc_jydnTxWe0",
    authDomain: "screening-programming.firebaseapp.com",
    projectId: "screening-programming",
    storageBucket: "screening-programming.appspot.com",
    messagingSenderId: "710000955266",
    appId: "1:710000955266:web:96e08304dac961434d099b"
};

firebase.initializeApp(firebaseConfig);

export { firebase }

//export const authConfig = initializeApp(firebaseConfig);

/*
({
    apiKey: process.env.REACT_APP_FIREBASE_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
});
*/