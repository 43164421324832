import { AppBar, Toolbar, Box, styled, Button } from "@mui/material"
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import jwt_decode from "jwt-decode";
import { useEffect, useState } from "react";
import Notifications from "./Notifications";
import { logoutCall } from "../apiCalls/userCalls";

const ToolBar = styled(Toolbar)({
    display: "flex",
    flexDirection: "row-reverse"
});

const SpanStyled = styled("span")(({ theme }) => ({
    cursor: "pointer",
    color: "white",
    fontSize: "14px",
    margin: "0px 5px",

    [theme.breakpoints.down("sm")]: {
        fontSize: "14px"
    }
}));

const BoxStyled = styled(Box)(({ theme }) => ({
    display: "flex",
    width: "150px",
    height: "45px",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "10px",

    [theme.breakpoints.down("sm")]: {
        width: "100px",
        height: "25px",
        marginRight: "5px",
        fontSize: "10px"
    }
}));

const ImageStyled = styled("img")(({ theme }) => ({
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    marginRight: "10px",
    objectFit: "cover",

    [theme.breakpoints.down("sm")]: {
        width: "25px",
        height: "25px"
    }
}));

const Navbar = () => {

    const { data } = useSelector(state => state.user.accessToken);
    const { finishSession } = useSelector(state=> state.user.finish);

    const [userAttributes, setUserAttributes] = useState({});
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const PF = 'https://screening-programming-backend.herokuapp.com/images/';


    useEffect(() => {
        if(data){
            const getDados = async () => {
                const decodedToken = jwt_decode(data);
                setUserAttributes(decodedToken);
            }
            getDados();
        }
    }, [data]);
    
    const handleLogout = async (e) => {
        logoutCall(dispatch);
    }

    const handleHome = (e) => {
        navigate("/")
    }

    return (
        <AppBar position="sticky">
            {/*<InviteDialog
                dispatch={dispatch}
            />*/}
            <ToolBar>
                {
                    (!data || finishSession)
                    ? <Link to="/" style={{textDecoration:'none', color: 'black'}}>
                        <SpanStyled>
                            ENTRAR
                        </SpanStyled>
                    </Link>
                    : <Box
                        display="flex"
                        flexDirection="row-reverse"
                        alignItems="center"
                    >
                        <BoxStyled>
                            <ImageStyled 
                                src={
                                    PF + "noAvatar.webp"
                                } 
                                alt=""
                            />
                            <SpanStyled onClick={handleLogout}>SAIR</SpanStyled>
                        </BoxStyled>
                        {
                            (!userAttributes.is_teacher && !userAttributes.is_admin)
                            && <Notifications
                                accessToken={data}
                                id={userAttributes.id}
                            />
                        }
                        <Button sx={{color: "white", marginRight: "5px"}} onClick={handleHome}>
                            INICIO
                        </Button>
                    </Box>
                }
            </ToolBar>
        </AppBar>
    )
}

export default Navbar