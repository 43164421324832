import { 
    Box, 
    Checkbox, 
    Paper, 
    Table, 
    TableBody, 
    TableCell, 
    TableContainer, 
    TableHead, 
    TableRow, 
    Typography, 
    styled,
    Button,
    Tooltip
} from '@mui/material';

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import jwt_decode from "jwt-decode";

import { getActivityWithQuestions, resetDelQuestions, setActivityDelete, setActivityDialog, setQuestionDialog, setQuestionsDelete, setQuestionsDeleteDialog } from '../apiCalls/activityCalls';

import FullImageDialog from "../components/dialogs/image/show.image.dialog";

import ActivityManageActions from "../components/active.manage.actions";
import Footer from '../components/Footer'
import DeleteQuestionDialog from '../components/dialogs/activity/question/delete.question.dialog';
import CreateOrEditDialog from '../components/dialogs/activity/create.edit.dialog';
import DeleteActivityDialog from '../components/dialogs/activity/delete.activity.dialog';
import CreateOrEditQuestionDialog from '../components/dialogs/activity/question/create.edit.dialog';
import { useState } from 'react';

const BoxStyled = styled(Box)({
    display: "flex",
    width: "100%",
    height: "100%",
    flexDirection: "column",
    overflowY: "visible",
    overflowX: "hidden",
    justifyContent: "space-between"
});

const PaperStyled = styled(Paper)(({ theme }) => ({
    display: "flex",
    width: "90%",
    minHeight: "120px",
    flexDirection: "column",
    padding: "0px 10px",
    marginBottom: "5px",

    [theme.breakpoints.down("sm")]: {
        width: "100%"
    }
}));

const ImageStyled = styled("img")(({ theme }) => ({
    width: "100px",
    objectFit: "contain",
    padding: "10px",
    cursor: "pointer",

    [theme.breakpoints.down("sm")]: {
        width: "80px"
    }
}));

const ActivityManage = () => {

    const { id } = useParams();
    
    const { data } = useSelector(state=>state.user.accessToken);
    const { 
        activity, 
        create,
        delete: deleteActivity, 
        deleteQuestions 
    } = useSelector(state=>state.activity);

    const [userVariables, setUserVariables] = useState({});

    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const PF = 'https://screening-programming-backend.herokuapp.com/images/';

    useEffect(() => {
        if (data) {
            const getDados = async () => {
                const decodedToken = jwt_decode(data);
                if (decodedToken.is_admin) {
                    setUserVariables(decodedToken);
                } else {
                    navigate("/");
                }
            }
            getDados();
        }
    }, [data, navigate]);

    useEffect(() => {
        if(data && id) {
            getActivityWithQuestions(data, dispatch, id);
            resetDelQuestions(dispatch);
        }
    }, [data, id, dispatch, userVariables]);

    useEffect(() => {
        if (deleteActivity.success) {
            setActivityDelete(dispatch, false, {});
            navigate("/")
        }
    }, [deleteActivity.success, navigate, dispatch]);

    useEffect(() => {
        if (create.success) {
            getActivityWithQuestions(data, dispatch, id);
            resetDelQuestions(dispatch);
        }
    }, [create.success, data, id, dispatch]);

    const handleDelete = () => {
        const { questions, ...others } = activity.data;
        setActivityDelete(dispatch, true, others);
    }
    
    const handleUpdate = () => {
        const { questions, ...others } = activity.data;
        setActivityDialog(dispatch, true, others);
    }

    const handleCreateQuestion = () => {
        setQuestionDialog(dispatch, true, {
            quiz_id: id,
            question_text: '',
            answer_type: "multiple",
            correct_answer: 'A',
            position: 0,
        })
    }

    const handleDeleteQuestion = () => {
        setQuestionsDeleteDialog(dispatch, deleteQuestions.ids, true);
    }

    return <BoxStyled>
        <FullImageDialog />
        
        <CreateOrEditDialog 
            dispatch={dispatch}
            accessToken={data}
        />
        <DeleteActivityDialog 
            dispatch={dispatch}
            accessToken={data}
        />

        <CreateOrEditQuestionDialog
            dispatch={dispatch}
            accessToken={data}
        />
        <DeleteQuestionDialog 
            dispatch={dispatch}
            accessToken={data}
            id={id}
        />
        <Box 
            sx={{
                display: "flex",
                justifyContent: "center"
            }}
            mb={0.5}
        >
            <PaperStyled>
                <Box 
                    component="h3"
                    width="100%"
                    textAlign="center"
                >{activity.data?.title || "Activity"}</Box>
                <Box component="span">
                    {"Image ID: "}
                    <Typography variant="caption">
                        {activity.data?.quiz_img || "image id"}
                    </Typography>
                </Box>
                <Box component="span">Activity Image pre-visualization</Box>
                <ImageStyled
                    src={
                        activity.data?.quiz_img
                        ? `https://drive.google.com/uc?export=view&id=${activity.data.quiz_img}`
                        : PF + "GENERAL.webp"
                    }
                    alt=""
                    loading="lazy"
                />
                <Box>Question count: {activity.data?.question_count || 0}</Box>
                <Box
                    display="flex"
                    justifyContent="flex-end"
                    p="0px 10px"
                >
                    <Tooltip title="Delete activity" sx={{marginRight: "10px"}}>
                        <Button onClick={handleDelete}>
                            Delete
                        </Button>
                    </Tooltip>
                    <Tooltip title="Update activity">
                        <Button variant="contained" onClick={handleUpdate}>
                            Update
                        </Button>
                    </Tooltip>
                </Box>
                <Box 
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "5px",
                        paddingTop: "5px",
                        borderTop: "1px solid lightgray"
                    }}
                >
                    <b>
                        Questions
                    </b>
                </Box>
                <Box
                    display="flex"
                    justifyContent="space-between"
                >
                    <Box>
                        <Checkbox 
                            onClick={() => {
                                deleteQuestions.ids.length === activity.data?.questions?.length
                                ? setQuestionsDelete(dispatch, [])
                                : setQuestionsDelete(dispatch, activity.data?.questions?.map(q=>q.id))
                            }}
                            checked={deleteQuestions.ids.length === activity.data?.questions?.length && activity.data?.questions?.length > 0}
                            />
                        <Button 
                            disabled={deleteQuestions.ids.length === 0}
                            onClick={handleDeleteQuestion}
                        >Remove selected</Button>
                    </Box>
                    <Box>
                        <Button 
                            variant="contained"
                            onClick={handleCreateQuestion}
                        >
                            Add question
                        </Button>
                    </Box>
                </Box>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell 
                                    align="center"
                                >
                                    #
                                </TableCell>
                                <TableCell align="center">ID</TableCell>
                                <TableCell align="center">Image ID</TableCell>
                                <TableCell align="center">Answer type</TableCell>
                                <TableCell align="center">Correct Answer</TableCell>
                                <TableCell align="center">Position</TableCell>
                                <TableCell align="center">Actions</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {
                                (!activity.loading && activity.data?.questions?.length > 0)
                                && activity.data.questions.map((q, index) => {
                                    const isSelected = deleteQuestions.ids.some(item => item === q.id);
                                    return <TableRow key={index}>
                                        <TableCell 
                                            align="center"
                                            padding="checkbox"
                                        >
                                            <Checkbox 
                                                onClick={() => {
                                                    !!isSelected 
                                                    ? setQuestionsDelete(dispatch, deleteQuestions.ids.filter(id=>id !== q.id))
                                                    : setQuestionsDelete(dispatch, deleteQuestions.ids.concat(q.id))
                                                }}
                                                checked={!!isSelected} 
                                            />
                                        </TableCell>
                                        <TableCell align="center">{q?.id || "ND"}</TableCell>
                                        <TableCell align="center">{q?.question_text || "ND"}</TableCell>
                                        <TableCell align="center">{q?.answer_type}</TableCell>
                                        <TableCell align="center">{q?.correct_answer || "ND"}</TableCell>
                                        <TableCell align="center">{q?.position}</TableCell>
                                        <TableCell align="center">
                                            <ActivityManageActions 
                                                question={q}
                                                dispatch={dispatch}
                                                id={id}
                                            />
                                        </TableCell>
                                    </TableRow>
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </PaperStyled>
        </Box>
        <Footer />
    </BoxStyled>
}

export default ActivityManage