import { 

    loadNotificationsRequest,
    loadNotificationsSuccess,
    loadNotificationsFailure,

    alterNotifications,
    setNotificationDialog,
    resetNotificationsList,

    loadAcceptInviteRequest,
    loadAcceptInviteFailure,
    loadAcceptInviteSuccess,

    loadRejectInviteRequest,
    loadRejectInviteSuccess,
    loadRejectInviteFailure,
    
    setArrivalNotifications

} from "../redux/notificationSlice";

import { finishSession } from "../redux/userSlice";
import { openSnackbar } from "../redux/snackbarSlice";

import axiosInstance from "./axios";

export const getNotifications = async (accessToken, dispatch, idUser) => {
    dispatch(loadNotificationsRequest());
    try {
        const res = await axiosInstance.get(`allNotifications/${idUser}`, {
            headers: {
                'token' : "Beader " + accessToken
            }
        });
        if(res.data.Status) {
            dispatch(loadNotificationsFailure());
            // dispatch(openSnackbar({ severity: "error", message: res.data.Status }));
        } else {
            dispatch(loadNotificationsSuccess(res.data));
        }
    } catch(err) {
        if(err === "Acesso finalizado") {
            dispatch(finishSession())
        } else {
            dispatch(loadNotificationsFailure());
            dispatch(openSnackbar({ severity: "error", message: "Erro interno" }));
        }
    }
}

export const setNotifications = (dispatch, notifications) => {
    dispatch(alterNotifications(notifications));
}

export const setNotification = (dispatch, open, notification) => {
    dispatch(setNotificationDialog({ dialog: open, notification}))
}

//id=notification id
export const acceptInvite = async (dispatch, accessToken, id) => {
    dispatch(loadAcceptInviteRequest());
    try {
        const res = await axiosInstance.delete(`accept_invite/${id}`, {
            headers: {
                token: "Bearer " + accessToken
            }
        });
        if(res.data.Status === "Convite aceito") {
            dispatch(loadAcceptInviteSuccess());
            dispatch(openSnackbar({ severity: "success", message: res.data.Status }));
        } else {
            dispatch(loadAcceptInviteFailure());
            dispatch(openSnackbar({ severity: "error", message: res.data.Status }));
        }
    } catch (err){
        if(err === "Accesso finalizado"){
            dispatch(finishSession());
        } else {
            dispatch(loadAcceptInviteFailure());
            dispatch(openSnackbar({ severity: "error", message: "Erro interno" }));
        }
    }
}

//id=notification id
export const rejectInvite = async (dispatch, accessToken, id) => {
    dispatch(loadRejectInviteRequest());
    try{
        const res = await axiosInstance.delete(`notifications/${id}`, {
            headers: {
                token: "Bearer " + accessToken
            }
        });
        if(res.data.Status === "Convite rejeitado"){
            dispatch(loadRejectInviteSuccess());
            dispatch(openSnackbar({ severity: "success", message: res.data.Status }));
        } else {
            dispatch(loadRejectInviteFailure());
            dispatch(openSnackbar({ severity: "error", message: res.data.Status }));
        }
    } catch(err) {
        if(err === "Acesso finalizado") {
            dispatch(finishSession())
        } else {
            dispatch(loadRejectInviteFailure("Erro interno"));
            dispatch(openSnackbar({ severity: "error", message: "Erro interno" }));
        }
    }
}

export const clearNotificationsList = (dispatch) => {
    dispatch(resetNotificationsList());
}

export const setArrivalNotification = (dispatch, arrivalNotification) => {
    dispatch(setArrivalNotifications({arrivalNotification}))
}