import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    file: {
        data: {},
        dialog: false
    }
}

const imageSlice = createSlice({
    name: "image",
    initialState,
    reducers: {
        //setFile
        setFileDialog: (state, action) => {
            state.file = {
                ...initialState.file,
                data: action.payload.file,
                dialog: action.payload.dialog
            }
        },
        
        //reset
        reset: (state) => {
            state = initialState;
        }
    }
})

export const { 
    //setImage
    setFileDialog,

    //reset
    reset
} = imageSlice.actions;
export default imageSlice.reducer;