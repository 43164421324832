import { 
    Box, 
    Button, 
    CircularProgress, 
    styled, 
    TextField
} from "@mui/material";
import { useState } from "react";

const BoxStyled = styled(Box)({
    display: "flex",
    width: "100%",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "space-evenly",
    flexWrap: "wrap"
});

const ButtonStyled = styled(Button)({
    width: "100px",
    margin: "10px",
    color: "white",
    backgroundColor: "#38B6FF"
});

const Open = ({ sending, send }) => {

    const [value, setValue] = useState("");

    const handleClick = () => {
        send(value);
    }

    return <BoxStyled>
        <Box
            width="95%"
            pt={1}
        >
            <TextField
                id="outlined-multiline-value"
                label="Resposta"
                fullWidth={true}
                autoFocus={true}
                multiline={true}
                minRows={1}
                maxRows={8}
                value={value}
                onChange={(e) => setValue(e.target.value)}
            />
        </Box>
        <Box
            display="flex"
            alignItems="center"
            p="5px 0px"
        >
            <ButtonStyled variant="contained" onClick={handleClick}>
                {
                    sending
                    ? <CircularProgress color="primary" size="20px" />
                    : "ENVIAR"
                }
            </ButtonStyled>
        </Box>
    </BoxStyled>
}

export default Open