import { useEffect } from 'react'

//import CadastrarTurma from "../components/CadastrarTurma";
//import ConvidarAluno from '../components/ConvidarAluno';
import {
    Box, 
    Button, 
    Paper, 
    styled,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Tooltip,
    Typography
} from "@mui/material";

import {
    AddBox,
    Edit, 
    Delete,
    DeleteOutlined,
    BarChartOutlined
} from "@mui/icons-material"

import { useDispatch, useSelector } from 'react-redux';
import { 
    changeAlumnGroupPagination, 
    getGroup, 
    setGroupCreate, 
    setGroupDelete
} from '../../apiCalls/groupCalls';
import { 
    setUserDelete, 
    showAlumnResultDialog, 
    setInviteDialog
} from '../../apiCalls/userCalls';

import DeleteAlumnGroup from '../dialogs/group/delete.alumn.group';
import CreateUpdateGroupDialog from "../dialogs/group/create.update.group";
import { useNavigate } from 'react-router-dom';
import { clearNotificationsList } from '../../apiCalls/notificationCalls';

const BoxGeneralStyled = styled(Box)({
    display: "flex",
    width: "100%",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    overflowY: "visible",
    overflowX: "hidden",
    marginTop: "5px"
});

const BoxMural = styled(Box)({
    display: "flex",
    width: "95%",
    minHeight: "80px",
    borderRadius: "5px",
    backgroundColor: "#38B6FF",
    color: "white",
    padding: "10px",
    marginBottom: "10px"
});

const BoxMuralText = styled("div")({
    display: "flex",
    flex: 4,
    flexDirection: "column",
});

const Title = styled("h1")(({ theme }) => ({
    marginTop: "0px",

    [theme.breakpoints.down("sm")]: {
        fontSize: "22px"
    }
}));

const Periode = styled("span")(({ theme }) => ({
    marginTop: "-25px",

    [theme.breakpoints.down("sm")]: {
        marginTop: "-15px"
    }
}));

const Username = styled("span")(({ theme }) => ({
    margin: "5px 0px",

    [theme.breakpoints.down("sm")]: {
        fontSize: "14px"
    }
}));

const BoxActions = styled(Box)({
    display: "flex",
    flex: 1,
    height: "25px",
    justifyContent: "flex-end"
});

const ButtonStyled = styled(Button)(({ theme }) => ({
    margin: "10px",
    [theme.breakpoints.down("sm")]: {

    }
}));

const Action = styled(Box)({
    margin: "0px 10px",
    cursor: "pointer"
});

const PaperStyled = styled(Paper)(({ theme }) => ({
    width: "90%",
    minHeight: "120px",
    overflowX: "auto",
    marginBottom: "5px",

    [theme.breakpoints.down("sm")]: {
        width: "100%"
    }
}));

const Turma = ({id, userVariables, invitation, accessToken}) => {

    const { data, loading, paginator } = useSelector(state=>state.group.group);
    const { success } = useSelector(state=>state.group.edit);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if(userVariables?.is_teacher != null && data?.teacher_id != null){
            if(userVariables.is_teacher){
                if(userVariables.id !== data.teacher_id) {
                    navigate("/")
                }
            } else {
                if(!userVariables.is_admin){
                    if(!!!data?.alumns?.find(al=>al.alumn.id === userVariables.id)){
                        navigate("/")
                    }
                }
            }
        }
    }, [userVariables.is_admin, userVariables.is_teacher, data.teacher_id, data.alumns, navigate, userVariables?.id])

    useEffect(() => {
        if(accessToken && id){
            getGroup(accessToken, dispatch, id);
        }
    }, [accessToken, id, dispatch]);
    
    useEffect(() => {
        if(success && accessToken && id) {
            getGroup(accessToken, dispatch, id);
        }
    }, [success, accessToken, dispatch, id]);

    /**
     * Gets the alumn informations to display
     * @param {Alumn} alumn 
     */
    const handleStatistics = (alumn) => {
        showAlumnResultDialog(dispatch, true, alumn.alumn);
    }

    /**
     * alumn that will be deleted
     * @param {Alumn} alumn 
     */
    const handleDeleteAlumn = (alumn) => {
        setUserDelete(dispatch, alumn, true);
    }

    /**
     * to edit the group informations
     */
    const handleEdit = () => {
        const { alumns, ...other} = data;
        setGroupCreate(dispatch, true, false, true, other);
    }
    
    const handleDelete = () => {
        const { alumns, teacher, ...other} = data;
        setGroupDelete(dispatch, other, true, false);
    }

    /**
     * to invite alumns to the group
     */
    const handleInviteAlumn = () => {
        setInviteDialog(dispatch, true);
        clearNotificationsList(dispatch);
    }

    return (
        <BoxGeneralStyled>
            <CreateUpdateGroupDialog 
                accessToken={accessToken}
                dispatch={dispatch}
                teacher_id={data?.teacher_id}
                userVariables={userVariables}
            />
            <BoxMural>
                <BoxMuralText>
                    <Title>{ data?.title }</Title>
                    <Periode>{ data?.periode }</Periode>
                    {
                        (invitation || userVariables.is_admin)
                        && <Username>PROFESSOR: { data?.teacher?.username}</Username>
                    }
                </BoxMuralText>
                {
                    (userVariables.is_admin || userVariables.is_teacher)
                    && <BoxActions>
                        <Action onClick={handleEdit}>
                            <Edit style={{ fontSize: 18 }} />
                        </Action>
                        <Action onClick={handleDelete}>
                            <Delete style={{ fontSize: 18 }} />
                        </Action>
                    </BoxActions>
                }
            </BoxMural>
            {
                (userVariables.is_teacher || userVariables.is_admin)
                && <Box
                    width="100%"
                >
                    <ButtonStyled 
                        variant="contained" 
                        startIcon={<AddBox />}
                        onClick={handleInviteAlumn}
                    >
                        Adicionar Aluno
                    </ButtonStyled>
                </Box>
            }
            <PaperStyled>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">ID</TableCell>
                                <TableCell align="center">{ userVariables.is_admin ? "Register" : "Matricula" }</TableCell>
                                <TableCell align="center">{ userVariables.is_admin ? "Alumn" : "Aluno"}</TableCell>
                                {
                                    (userVariables.is_admin || userVariables.is_teacher)
                                    && <TableCell align="center">{ userVariables.is_admin ? "Actions" : "Ações"}</TableCell>
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                !loading
                                && data?.alumns?.length > 0
                                && data
                                    ?.alumns
                                    .slice(paginator.page * paginator.rows, paginator.page * paginator.rows + paginator.rows)
                                    .map((a, index) => {
                                        return <TableRow key={index}>
                                            <TableCell align="center">
                                                <Typography variant="caption">
                                                    { a.alumn.id }
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="center">
                                                <Typography variant="caption">
                                                    { a.alumn?.register || "N/A" }
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="center">
                                                <Typography variant="caption">
                                                    { a.alumn.username }
                                                </Typography>
                                            </TableCell>
                                            {
                                                (userVariables.is_admin || userVariables.is_teacher)
                                                && <TableCell 
                                                    align="center"
                                                >
                                                    <Box
                                                        display="flex"
                                                        width="100%"
                                                        justifyContent="space-evenly"
                                                    >
                                                        <Tooltip
                                                            title={userVariables.is_admin ? "Statistics" : "Estatísticas"}
                                                        >
                                                            <Box 
                                                                display="flex"
                                                                width="30px"
                                                                height="30px"
                                                                borderRadius="50%"
                                                                alignItems="center"
                                                                justifyContent="center"
                                                                sx={{
                                                                    backgroundColor: "limegreen", 
                                                                    color: "white",
                                                                    cursor: "pointer"
                                                                }}
                                                                onClick={() => handleStatistics(a)}
                                                            >
                                                                <BarChartOutlined />
                                                            </Box>
                                                        </Tooltip>
                                                        <Tooltip
                                                            title={userVariables.is_admin ? "Delete" : "Excluir"}
                                                        >
                                                            <Box 
                                                                display="flex"
                                                                width="30px"
                                                                height="30px"
                                                                borderRadius="50%"
                                                                alignItems="center"
                                                                justifyContent="center"
                                                                sx={{
                                                                    backgroundColor: "crimson", 
                                                                    color: "white",
                                                                    cursor: "pointer",
                                                                    marginLeft: "5px"
                                                                }}
                                                                onClick={() => handleDeleteAlumn(a)}
                                                            >
                                                                <DeleteOutlined />
                                                            </Box>
                                                        </Tooltip>
                                                    </Box>
                                                </TableCell>
                                            }
                                        </TableRow>
                                    })
                                }

                        </TableBody>
                    </Table>
                </TableContainer>
                {
                    !loading
                    && !data?.alumns?.length > 0
                    && <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                        NENHUM ALUNO ALOCADO A TURMA!
                    </Box>
                }
                {
                    !loading
                    && data?.alumns?.length > 0
                    && <TablePagination
                        rowsPerPageOptions={[5, 10, 20]}
                        labelRowsPerPage="Lin. por página"
                        component="div"
                        count={paginator.pageCount}
                        rowsPerPage={paginator.rows}
                        page={paginator.page}
                        onRowsPerPageChange={(e) => {
                            changeAlumnGroupPagination(dispatch, {
                                ...paginator,
                                rows: parseInt(e.target.value)
                            })
                        }}
                        onPageChange={(e, page) => {
                            changeAlumnGroupPagination(dispatch, {
                                ...paginator,
                                page: parseInt(page)
                            })
                        }}
                    />
                }
            </PaperStyled>
            <DeleteAlumnGroup
                accessToken={accessToken} 
                dispatch={dispatch}
                idGroup={id}
            />
        </BoxGeneralStyled>
    )
}

export default Turma
