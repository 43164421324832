import { 
    Box, 
    Button, 
    Dialog, 
    DialogActions, 
    DialogContent, 
    DialogContentText, 
    DialogTitle, 
    FormControl, 
    Select,
    MenuItem, 
    TextField, 
    Switch,
    FormControlLabel
} from '@mui/material';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux'
import { createActivity, getActivitiesSummary, setActivityDialog, updateActivity } from '../../../apiCalls/activityCalls';

const label = { inputProps: { 'aria-label': 'Switch demo' } };

const CreateOrEditDialog = ({ dispatch, accessToken }) => {

    const { create, list } = useSelector(state=>state.activity);
    const [activity, setActivity] = useState({});

    useEffect(() => {
        setActivity(create.data);
    }, [create.data]);

    useEffect(() => {
        if(accessToken) {
            getActivitiesSummary(dispatch, accessToken);
        }
    }, [accessToken, dispatch]);

    const handleDialog = () => {
        setActivityDialog(dispatch, false, {});
    }

    const handleChangePrevious = (e) => {
        const value = e.target.value;
        setActivity({
            ...activity,
            previous_activity_id: value
        })
    }

    const handleChange = (e) => {
        setActivity({
            ...activity, 
            [e.target.name]: e.target.value
        })
    }

    const handleChangeChecked = (e) => {
        setActivity({
            ...activity,
            is_active: e.target.checked
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if(activity?.id != null){
            updateActivity(dispatch, accessToken, activity);
        } else {
            createActivity(dispatch, accessToken, activity);
        }
    }

    return <Dialog
        id="dialog-create"
        open={create.dialog}
        onClose={handleDialog}
    >
        <DialogTitle align="center">
            {
                activity?.id != null
                ? "UPDATE ACTIVITY"
                : "CREATE ACTIVITY"
            }
        </DialogTitle>
        <DialogContent>
            <DialogContentText align="center">Activity informations</DialogContentText>
            <Box 
                display="flex"
                flexDirection="column"
                justifyContent="center"
                mb={1}
            >
                <FormControl fullWidth={true} sx={{marginTop: "5px"}}>
                    <Select
                        onChange={handleChangePrevious}
                        displayEmpty
                        value={activity?.previous_activity_id}
                        inputProps={{ 'aria-label': 'Without label' }}
                    >
                        <MenuItem value={0}>Select the previous activity</MenuItem>
                        {
                            !list.loading
                            && list.data.map((a, index) => {
                                return <MenuItem value={a.id} key={index}>{a.title}</MenuItem>
                            })
                        }
                    </Select>
                </FormControl>
            </Box>
            <Box mt={1} component="form" onSubmit={handleSubmit}>
                <TextField
                    id="outlined-title-input"
                    label="Title"
                    name="title"
                    variant="standard"
                    fullWidth={true}
                    value={activity.title}
                    required
                    onChange={(e) => handleChange(e)}
                />
                <TextField
                    id="outlined-quiz_img-input"
                    label="Activity Image"
                    name="quiz_img"
                    variant="standard"
                    fullWidth={true}
                    value={activity.quiz_img}
                    required
                    onChange={(e) => handleChange(e)}
                />
                <Box
                    display="flex"
                >
                    <FormControlLabel
                        value="start"
                        sx={{marginLeft: "0px"}}
                        control={
                            <Switch 
                                {...label} 
                                checked={activity.is_active} 
                                onChange={handleChangeChecked}
                            />
                        }
                        label={"Display to users"}
                        labelPlacement="start"
                    />
                </Box>
                <DialogActions
                    sx={{
                        display: "flex",
                        justifyContent: "space-between"
                    }}
                >
                    <Button variant="contained" onClick={handleDialog}>BACK</Button>
                    <Button 
                        variant="contained" 
                        type="submit"
                        size="small"
                    >
                        {
                            activity?.id != null
                            ? "UPDATE"
                            : "CREATE"
                        }
                    </Button>
                </DialogActions>
            </Box>
        </DialogContent>
    </Dialog>
}

export default CreateOrEditDialog