import { 
    HighlightOff, 
    Search 
} from '@mui/icons-material'

import { 
    Box, 
    Dialog, 
    DialogContent, 
    DialogTitle, 
    IconButton, 
    InputBase, 
    Paper,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    styled,
    Tooltip,
    TableCell,
    DialogActions,
    Button
} from '@mui/material'

import React, { useRef, useState } from 'react'
import {io} from "socket.io-client";
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { setNotifications } from '../../../apiCalls/notificationCalls'
import { addToInviteList, clearUsersList, getAlumns, sendInvitation, setInviteDialog } from '../../../apiCalls/userCalls'

const DialogContentStyled = styled(DialogContent)(({ theme }) => ({
    display: "flex",
    minWidth: "400px",
    height: "350px",
    flexDirection: "column",
    alignItems: "center",
    position: "relative",
    padding: "5px 0px",
    overflowX: "hidden",
    overflowY: "visible",

    [theme.breakpoints.down("sm")]: {
        minWidth: "310px"
    }
}));

const BoxBlocked = styled(Box)({
    color: "rgba(0, 0, 0, 0.3)",
    textAlign: "center",
    padding: "5px 0px",
    margin: "2px 0px",

    "&:hover": {
        backgroundColor: "rgb(230, 230, 230)"
    },

    "transition": "0.5s ease"
});

const BoxUnlocked = styled(Box)({
    textAlign: "center",
    padding: "5px 0px",
    margin: "2px 0px",
    cursor: "pointer",

    "&:hover": {
        backgroundColor: "rgb(230, 230, 230)"
    },

    "transition": "0.5s ease"
})

const InviteAlumnDialog = ({ userVariables, dispatch, accessToken, id, title }) => {
    const { invite, list } = useSelector(state=>state.user);
    const { data } = useSelector(state=>state.notification.list);
    const { group } = useSelector(state=>state.group);
    const [searchInput, setSearchInput] = useState("");
    const socket = useRef();

    useEffect(() => {
        socket.current = io("https://screening-progr-socket.herokuapp.com/");
    }, []);

    useEffect(()=>{
        if(userVariables?.id != null){
            socket.current.emit("addUser", userVariables.id)
        }
    }, [userVariables?.id]);

    const handleDialog = () => {
        setInviteDialog(dispatch, false);
    }

    /**
     * to search for an alumn
     * @param {event} e 
     */
    const handleChangeInput = (e) => {
        setSearchInput(e.target.value);
        if(e.target.value !== ""){
            getAlumns(dispatch, accessToken, e.target.value, id);
        } else {
            clearUsersList(dispatch);
        }
    }
    
    /**
     * to insert an alumn into the invite list
     * @param {Alumn} alumn 
     */
    const insertAlumn = (alumn) => {
        addToInviteList(dispatch, invite.inviteList.concat({id: alumn.id, email: alumn.email}), id);
        setNotifications(dispatch, data.concat({
            group_id: id,
            user_id: alumn.id,
            title: "Convite para turma",
            message: `Você foi convidado(a) para participar da turma "${group.data.title.toUpperCase()}".`
        }));
        setSearchInput("");
        clearUsersList(dispatch);
    }

    /**
     * to remove an alumn of the invite list
     * @param {Alumn} alumn 
     */
    const removeAlumn = (alumn) => {
        addToInviteList(dispatch, invite.inviteList.filter(em=>em.email !== alumn.email));
        setNotifications(dispatch, data.filter(n=>n.alumn_id !== alumn.id))
    }

    /**
     * to send the invitations
     * @param {event} e 
     */
    const handleSubmit = (e) => {
        e.preventDefault();
        sendInvitation(dispatch, accessToken, invite.inviteList, data, group.data.title, userVariables, socket);
    }

    return <Dialog
        id="dialog-create"
        open={invite.dialog}
        onClose={handleDialog}
    >
        <DialogTitle align="center">
            {userVariables.is_admin ? "Invite alumns" : "Convidar alunos"}
        </DialogTitle>
        <Box component="form" onSubmit={handleSubmit}>
            <DialogContentStyled>
                <Box 
                    sx={{
                        display: "flex",
                        width: '100%',
                        maxHeight: "400px",
                        flexDirection: 'column',
                        position: 'absolute',
                        overflowY: 'visible',
                        alignItems: 'center'
                    }}
                >
                    <Paper 
                        sx={{ 
                            display: 'flex', 
                            width: "90%",  
                            alignItems: 'center'
                        }}
                    >
                        <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            value={searchInput}
                            onChange={handleChangeInput}
                            placeholder={ userVariables.is_admin ? "Alumn email" : "Email do aluno" }
                            inputProps={{ 'aria-label': 'email' }}
                        />
                        <IconButton sx={{ p: '10px' }} aria-label='search'>
                            <Search />
                        </IconButton>
                    </Paper>
                    <Box
                        mt={1}
                        sx={{
                            display: "flex",
                            width: "90%",
                            flexDirection: "column",
                            backgroundColor: "lightgray",
                            borderRadius: "3px",
                            overflowY: "auto"
                        }}
                    >
                        {
                            list.data.map((item, index) => {
                                const is_located = item?.alumn?.groups?.some(g => g.group_id === id);
                                const is_inside_invite_list = invite.inviteList.find(i=>i.email === item.email);
                                return <Box key={index}>
                                    {
                                        (is_located || !!is_inside_invite_list)
                                        ? <Tooltip title="Aluno já cadastrado na turma">
                                            <BoxBlocked>
                                                {item.email.length > 25 ? item.email.substring(0, 25) + "..." : item.email}
                                            </BoxBlocked>
                                        </Tooltip>
                                        : <BoxUnlocked onClick={() => insertAlumn(item)}>
                                            {item.email.length > 25 ? item.email.substring(0, 25) + "..." : item.email}
                                        </BoxUnlocked>
                                    }
                                    
                                </Box>
                            })
                        }
                    </Box>
                </Box>
                <Box
                    display="flex"
                    width="90%"
                    mt={7}
                >
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow align="center" sx={{fontWeight: "bold"}}>Email</TableRow>
                            </TableHead>

                            <TableBody>
                                {
                                    invite.inviteList.length > 0
                                    ? invite.inviteList.map((item, index) => {
                                        return <TableRow align="center" key={index}>
                                            <TableCell 
                                                sx={{ 
                                                    display: "flex", 
                                                    justifyContent: "center" ,
                                                    alignItems: "center",
                                                    padding: "5px 0px"
                                                }}
                                            >
                                                {item.email.length > 25 ? item.email.substring(0, 25) + "..." : item.email}
                                                <Tooltip title="Remover">
                                                    <Box 
                                                        sx={{
                                                            display: "flex", 
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            marginLeft: "5px", 
                                                            cursor: "pointer"
                                                        }}
                                                        onClick={() => removeAlumn(item)}
                                                    >
                                                        <HighlightOff />
                                                    </Box>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    })
                                    : <TableRow align="center" sx={{ fontSize: "12px"}}>
                                        { userVariables.is_admin ? "Add any student to the list" : "Adicione algum aluno à lista"}
                                    </TableRow>
                            }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </DialogContentStyled>
            <DialogActions sx={{display: "flex", justifyContent: "space-between"}}>
                <Button 
                    variant="contained" 
                    onClick={handleDialog}
                    disabled={invite.loading}
                >
                    Voltar
                </Button>
                <Button 
                    variant="contained"
                    disabled={invite.loading || invite.inviteList.length === 0}
                    type="submit"
                >
                    Convidar
                </Button>
            </DialogActions>
        </Box>
    </Dialog>
}

export default InviteAlumnDialog