import { 
    Box, 
    Button, 
    CardMedia, 
    CircularProgress, 
    Paper, 
    TextField, 
    Typography,
    styled 
} from '@mui/material';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { firebase } from "../utils/auth";
import { Link, useNavigate } from "react-router-dom";

import { loginCall } from '../apiCalls/userCalls';
import Footer from '../components/Footer';

const BoxStyled = styled(Box)(({ theme }) => ({
    display: "flex",
    width: "100%",
    height: "calc(100vh - 64px)",
    flexDirection: "column",
    justifyContent: "space-between",
    overflowY: "auto",
    overflowX: "hidden",

    [theme.breakpoints.down("sm")]: {
        height: "calc(100vh - 56px)"
    }
}));

const CardMediaStyled = styled(CardMedia)(({ theme })=>({
    display: "flex",
    width: "800px",
    height: "400px",
    top: "0",
    objectFit: "contain",
    
    [theme.breakpoints.down("sm")]: {
        width: "100%",
        height: "230px"
    }
}));

const PaperStyled = styled(Paper)(({ theme }) => ({
    display: "flex",
    height: "300px",
    width: "250px",
    padding: "20px",
    marginTop: "180px",
    borderRadius: "5px",
    position: "absolute",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "space-around",
    backgroundColor: "#c0c1c2",

    [theme.breakpoints.down("sm")]: {
        marginTop: "130px"
    }
}));

const ButtonStyled = styled(Button)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    backgroundColor: "#a5a5a5",
    borderRadius: "5px",
    padding: 0
}));

const Login = () => {

    const [user, setUser] = useState({
        email: '',
        password: ''
    });

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { loading } = useSelector(state=>state.user.accessToken);

    const handleChange = (e) => {
        setUser({
            ...user, [e.target.name]: e.target.value
        })
    }


    const handleGoogleLogin = async () => {
        try{
            const google_provider = new firebase.auth.GoogleAuthProvider();
            const res = await firebase.auth().signInWithPopup(google_provider);
            loginCall({email: res.user.email, loginType: 1}, dispatch);
        } catch(err){}
    }
    const handleRegister = () => {
        navigate("/register");
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        loginCall({email: user.email, password: user.password, loginType: 0}, dispatch);
    }

    return (
        <BoxStyled>
            <Box
                display="flex"
                width="100%"
                height="100%"
                flexDirection="column"
                alignItems="center"
                position="relative"
            >
                <CardMediaStyled
                    component="img" 
                    image="/assets/1638717038835Logotipo.webp" 
                    alt="logo"
                />
                <PaperStyled component="form" onSubmit={handleSubmit}>
                    <h3 style={{margin: 0}}>Login</h3>
                    <TextField
                        id="outlined-login-input"
                        label="Email"
                        name="email"
                        value={user.email}
                        required
                        onChange={(e) => handleChange(e)}
                        placeholder="Email"
                    />
                    <TextField
                        id="outlined-password-input"
                        label="Senha"
                        name="password"
                        value={user.password}
                        required
                        onChange={(e) => handleChange(e)}
                        placeholder="Senha"
                        type="password"
                    />
                    <Box
                        display="flex"
                        width="100%"
                        justifyContent="center"
                    >

                        <Button 
                            disabled={loading}
                            style={{
                                backgroundColor: "#38B6FF",
                                margin: "0px 5px",
                                color: "white"
                            }}
                            type="submit"
                        >
                            {
                                loading
                                ? <CircularProgress color='primary' size="20px" />
                                : "Entrar"
                            }
                        </Button>
                        <Button 
                            onClick={handleRegister}
                            disabled={loading} 
                            style={{
                                backgroundColor: "#38B6FF",
                                margin: "0px 5px",
                                color: "white"
                            }}   
                        >
                            {
                                loading
                                ? <CircularProgress color='primary' size="20px" />
                                : "Cadastrar"
                            }
                        </Button>
                    </Box>
                    <Box>
                        {
                            loading
                            ? <CircularProgress color="primary" size="20px" />
                            : (
                                <ButtonStyled onClick={handleGoogleLogin}>
                                    <img 
                                        src={"/assets/icone_do_google.webp"} 
                                        style={{
                                            display: "flex",
                                            width: "40px",
                                            height: "40px",
                                            marginRight: "5px",
                                            objectFit: "cover",
                                            borderRadius: "5px 0px 0px 5px"
                                        }}
                                        alt=""
                                    />
                                    
                                    <Typography 
                                        style={{
                                            fontSize: "14px",
                                            marginRight: "3px",
                                            color: "black"
                                        }}
                                    >
                                        Entrar com o google
                                    </Typography>
                                </ButtonStyled>
                            )
                        }
                    </Box>
                    <Link to="/recoverAccount" style={{textDecoration:'none', color:'black'}}>
                        {
                            loading
                            ? <CircularProgress color="primary" size="20px" />
                            : <span>Esqueceu sua senha?</span>
                        }
                    </Link>
                </PaperStyled>
            </Box>
            <Footer />
        </BoxStyled>
    )
}

export default Login