import axios from "axios";
// import { loginStart, loginSucess, loginFailure } from "./context/AuthActions";
import { 
    //accessToken
    loadUserRequest, 
    loadUserSuccess, 
    loadUserFailure, 
    logout, 

    //register
    loadUserRegisterRequest,
    loadUserRegisterSuccess,
    loadUserRegisterFailure,

    //user attributes
    loadUserAttributesRequest,
    loadUserAttributesSuccess,
    loadUserAttributesFailure,

    //change user
    changeUserRequest,
    changeUserSuccess,
    changeUserFailure,

    //recoverAccount
    loadRecoverAccountRequest,
    loadRecoverAccountSuccess,
    loadRecoverAccountFailure,
    
    //load users(admin)
    loadUsersRequest,
    loadUsersSuccess,
    loadUsersFailure,
    changePaginator,

    //alumn result
    loadAlumnResultRequest,
    loadAlumnResultSuccess,
    loadAlumnResultFailure,

    //delete
    setUserToDelete,
    loadDeleteRequest,
    loadDeleteSuccess,
    loadDeleteFailure,

    //create
    setUserToCreate,
    loadCreateRequest,
    loadCreateSuccess,
    loadCreateFailure,

    finishSession,
    showAlumnResultsDialog,
    openInviteDialog,
    clearList,
    changeInviteList,
    loadSendInviteRequest,
    loadSendInviteSuccess,
    loadSendInviteFailure,

    resetUserLists,
    updateUserRequest,
    updateUserSuccess,
    updateUserFailure
    
} from "../redux/userSlice";

import { getGroup } from "./groupCalls";

import axiosInstance from "./axios";
import { openSnackbar } from "../redux/snackbarSlice";

export const resetUserList = (dispatch) => {
    dispatch(resetUserLists());
}

export const loginCall = async (userCredentials, dispatch) => {
    dispatch(loadUserRequest());
    const PBD = 'https://screening-programming-backend.herokuapp.com/api/';
    //const PBD = 'http://localhost:5000/api/';
    try{
        const res = await axios.post(PBD + "login", userCredentials);
        if(res.data.Status){
            dispatch(loadUserFailure());
            dispatch(openSnackbar({ severity: "error", message: res.data.Status}));
        } else {
            dispatch(loadUserSuccess(res.data.accessToken));
        }
    } catch(err){
        dispatch(loadUserFailure());
        dispatch(openSnackbar({severity: "error", message: "Erro interno"}));
    }
}

export const logoutCall = (dispatch) => {
    dispatch(logout());
}

export const register = async (dispatch, user) => {
    dispatch(loadUserRegisterRequest());
    const PBD = 'https://screening-programming-backend.herokuapp.com/api/';
    //const PBD = 'http://localhost:5000/api/';
    
    try{
        const res = await axios.post(PBD + "users/store", user);
        if(res.data.Status === "Usuário cadastrado"){
            dispatch(loadUserRegisterSuccess());
            dispatch(openSnackbar({ severity: "success", message: res.data.Status }));
        } else {
            dispatch(loadUserRegisterFailure());
            dispatch(openSnackbar({ severity: "error", message: res.data.Status }));
        }
    } catch(err){
        dispatch(loadUserRegisterFailure());
        dispatch(openSnackbar({ severity: "error", message: "Erro interno" }));
    }
}

export const registerAdmin = async (dispatch, user, accessToken) => {
    dispatch(loadCreateRequest());
    try{
        const res = await axiosInstance.post("users/adminStore", user, {
            headers: { token: "Bearer " + accessToken}
        });
        if(res.data.Status === "Usuário cadastrado"){
            dispatch(loadCreateSuccess());
            getUsers(accessToken, dispatch);
            dispatch(openSnackbar({ severity: "success", message: res.data.Status }));
        } else {
            dispatch(loadCreateFailure());
            dispatch(openSnackbar({ severity: "error", message: res.data.Status }));
        }
    } catch(err){
        if(err === "Acesso finalizado") {
            dispatch(finishSession());
        } else {
            dispatch(loadCreateFailure())
            dispatch(openSnackbar({ severity: "error", message: "Erro interno" }));
        }
    }
}

export const getUsers = async (accessToken, dispatch) => {
    dispatch(loadUsersRequest());
    try {
        const res = await axiosInstance.get("allUsers", {
            headers: {
                'token' : "Beader " + accessToken
            }
        });
        if(res.data.Status) {
            dispatch(loadUsersFailure());
            dispatch(openSnackbar({ severity: "error", message: res.data.Status }));
        } else {
            dispatch(loadUsersSuccess(res.data.users));
        }
    } catch(err) {
        if(err === "Acesso finalizado") {
            dispatch(finishSession())
        } else {
            dispatch(loadUsersFailure());
            dispatch(openSnackbar({ severity: "error", message: "Erro interno" }));
        }
    }
}

export const getTeachers = async (accessToken, dispatch) => {
    dispatch(loadUsersRequest());
    try{
        const res = await axiosInstance.get(`allUsers?type=${'teacher'}`,{
            headers: {
                token: "Bearer " + accessToken
            }
        });
        if(res.data.Status) {
            dispatch(loadUsersFailure());
            dispatch(openSnackbar({ severity: "error", message: res.data.Status }));
        } else {
            dispatch(loadUsersSuccess(res.data.users))
        }
    } catch(err) {
        if(err === "Acesso finalizado") {
            dispatch(finishSession());
        } else {
            dispatch(loadUsersFailure());
            dispatch(openSnackbar({ severity: "error", message: "Erro interno" }));
        }
    }
}

export const changePagination = (dispatch, paginator) => {
    dispatch(changePaginator(paginator));
}

export const getUserAttributes = async (dispatch, accessToken, id) => {
    dispatch(loadUserAttributesRequest());
    try{
        const res = await axiosInstance.get(`users/${id}`, {
            headers: { token: "Bearer " + accessToken },
        });
        if(res.data.Status){
            dispatch(loadUserAttributesFailure());
            dispatch(openSnackbar({ severity: "error", message: res.data.Status }));
        } else {
            const { 
                turmas, 
                created_at, 
                is_admin, 
                is_teacher,
                is_google_login,
                form_clicked,  ...data
            } = res.data;
            const other = {
                is_admin: is_admin,
                is_teacher: is_teacher,
                form_clicked: form_clicked,
                is_google_login: is_google_login
            };
            const otherAux = {
                ...other,
                password: ""
            }
            dispatch(loadUserAttributesSuccess({data, otherAux}));
        }
    } catch(err) {
        if(err === "Acesso finalizado"){
            dispatch(finishSession());
        } else {
            dispatch(loadUserAttributesFailure());
            dispatch(openSnackbar({ severity: "error", message: "Erro interno" }));
        }
    }
}

export const changingCall = (dispatch) => {
    dispatch(changeUserRequest());
}

export const changeUserCall = async (dispatch, pass, newUserData, newUser, accessToken, is_admin) => {
    try{
        if(!is_admin){
            dispatch(changeUserRequest());
            const res = await axiosInstance.post(`users/verifPass/${newUser.id}`, {password: pass}, {
                headers: { token: "Bearer " + accessToken },
            });
            if(res.data.Status === "Ok" || newUserData.is_google_login) {
                if(newUserData.is_teacher) {
                    const resTeacher = await axiosInstance.put(`usersProfessor/${newUser.id}`, {newUserData, newUser}, {
                        headers: { token: "Bearer " + accessToken}
                    })
                    if(resTeacher.data.Status === "Usuário alterado") {
                        dispatch(changeUserSuccess(res.data.accessToken));
                        dispatch(openSnackbar({ severity: "success", message: resTeacher.data.Status }));
                    } else {
                        dispatch(changeUserFailure());
                        dispatch(openSnackbar({ severity: "error", message: resTeacher.data.Status }));
                    }
                } else {
                    const resAlumn = await axiosInstance.put(`usersAluno/${newUser.id}`, {newUserData, newUser}, {
                        headers: { token: "Bearer " + accessToken}
                    })
                    if(resAlumn.data.Status === "Usuário alterado") {
                        dispatch(changeUserSuccess({ accessToken: res.data.accessToken }));
                        dispatch(openSnackbar({ severity: "success", message: resAlumn.data.Status }));
                    } else {
                        dispatch(changeUserFailure());
                        dispatch(openSnackbar({ severity: "error", message: resAlumn.data.Status }));
                    }
                }
            } else {
                dispatch(changeUserFailure());
                dispatch(openSnackbar({ severity: "error", message: res.data.Status }));
            }
        } else {
            dispatch(updateUserRequest());
            if(newUserData.is_admin){
                const res = await axiosInstance.put(`usersAdmin/${newUser.id}`, {newUserData, newUser}, {
                    headers: { token: "Bearer " + accessToken}
                })
                if(res.data.Status === "Usuário alterado"){
                    dispatch(updateUserSuccess());
                    dispatch(openSnackbar({ severity: "success", message: res.data.Status }));
                    getUserAttributes(dispatch, accessToken, newUser.id)
                } else {
                    dispatch(updateUserFailure());
                    dispatch(openSnackbar({ severity: "error", message: res.data.Status }));
                }
            } else if(newUserData.is_teacher ){
                const res = await axiosInstance.put(`usersProfessor/${newUser.id}`, {newUserData, newUser}, {
                    headers: { token: "Bearer " + accessToken },
                });
                if(res.data.Status === "Usuário alterado"){
                    dispatch(updateUserSuccess());
                    dispatch(openSnackbar({ severity: "success", message: res.data.Status }));
                    getUserAttributes(dispatch, accessToken, newUser.id)
                } else {
                    dispatch(updateUserFailure());
                    dispatch(openSnackbar({ severity: "error", message: res.data.Status }));
                }
            } else if(!newUserData.is_teacher) {
                const res = await axiosInstance.put(`usersAluno/${newUser.id}`, {newUserData, newUser}, {
                    headers: { token: "Bearer " + accessToken },
                });
                if(res.data.Status === "Usuário alterado"){
                    dispatch(updateUserSuccess());
                    dispatch(openSnackbar({ severity: "success", message: res.data.Status }));
                    getUserAttributes(dispatch, accessToken, newUser.id)
                } else {
                    dispatch(updateUserFailure());
                    dispatch(openSnackbar({ severity: "error", message: res.data.Status }));
                }
            }
        }
    } catch(err){
        if(err === "Acesso finalizado") {
            dispatch(finishSession())
        } else {
            dispatch(changeUserFailure());
            dispatch(openSnackbar({ severity: "error", message: "Erro interno" }));
        }
    }
}

export const setUserDelete = (dispatch, user, open) => {
    dispatch(setUserToDelete({user, dialog: open}));
}

export const setUserCreate = (dispatch, open, create, id) => {
    dispatch(setUserToCreate({dialog: open, create, user_id: id}));
}

export const removeUserGroup = async (accessToken, dispatch, idAlumn, idTurma) => {
    dispatch(loadDeleteRequest());
    try{
        const res = await axiosInstance.delete(`turmas/deleteAluno/${idAlumn}`,{
            headers:{
                token: "Bearer " + accessToken
            }
        });
        if(res.data.Status === "Aluno excluido"){
            dispatch(loadDeleteSuccess());
            getGroup(accessToken, dispatch, idTurma);
            dispatch(openSnackbar({ severity: "success", message: res.data.Status }));
        } else {
            dispatch(loadDeleteFailure());
            dispatch(openSnackbar({ severity: "error", message: res.data.Status }));
        }
    } catch(err){
        if(err === "Acesso finalizado"){
            dispatch(finishSession());
        } else {
            dispatch(loadDeleteFailure());
            dispatch(openSnackbar({ severity: "error", message: "Erro interno" }));
        }
    }
}

export const deleteUser = async (dispatch, accessToken, id) => {
    dispatch(loadDeleteRequest());
    try{
        const res = await axiosInstance.delete(`users/${id}`, {
            headers: {
                token: "Bearer " + accessToken
            }
        });
        if(res.data.Status === "Usuário removido"){
            dispatch(loadDeleteSuccess());
            getUsers(accessToken, dispatch);
            dispatch(openSnackbar({ severity: "success", message: res.data.Status }));
        } else {
            dispatch(loadDeleteFailure());
            dispatch(openSnackbar({ severity: "error", message: res.data.Status }));
        }
    } catch(err){
        if(err === "Acesso finalizado"){
            dispatch(finishSession());
        } else {
            dispatch(loadDeleteFailure());
            dispatch(openSnackbar({ severity: "error", message: "Erro interno" }));
        }
    }
}

//id=user id
export const getUserResults = async (dispatch, accessToken, group_id, alumn_id) => {
    dispatch(loadAlumnResultRequest());
    try{
        const res = await axiosInstance.get(`alunoResults/${group_id}/${alumn_id}`,{
            headers: { token: "Bearer " + accessToken}
        });
        if(res.data.Status){
            dispatch(loadAlumnResultFailure());
            dispatch(openSnackbar({ severity: "error", message: res.data.Status }));
        } else {
            dispatch(loadAlumnResultSuccess(res.data));
        }
    } catch(err){
        if(err === "Acesso finalizado") {
            dispatch(finishSession());
        } else {
            dispatch(loadAlumnResultFailure());
            dispatch(openSnackbar({ severity: "error", message: "Erro interno" }));
        }
    }
}

export const showAlumnResultDialog = (dispatch, open, alumn) => {
    dispatch(showAlumnResultsDialog({ dialog: open, alumn }));
}

export const recoverAccount = async (dispatch, data) => {
    const PBD = 'https://screening-programming-backend.herokuapp.com/api/';
    //const PBD = 'http://localhost:5000/api/';
    dispatch(loadRecoverAccountRequest());
    try {
        const res = await axios.post(PBD + "recoverAccount", data);
        if(res.data.Status === "Enviado"){
            dispatch(loadRecoverAccountSuccess());
            dispatch(openSnackbar({ severity: "success", message: "Enviado, Verifique o seu email" }));
        } else {
            dispatch(loadRecoverAccountFailure());
            dispatch(openSnackbar({ severity: "error", message: res.data.Status }));
        }
    } catch(err){
        dispatch(loadRecoverAccountFailure());
        dispatch(openSnackbar({ severity: "error", message: "Erro interno" }));
    }
}

export const changePass = async (dispatch, data, id) => {
    const PBD = 'https://screening-programming-backend.herokuapp.com/api/';
    //const PBD = 'http://localhost:5000/api/';
    dispatch(loadRecoverAccountRequest());
    try {
        const res = await axios.put(PBD + `users/${id}`, data);
        if(res.data.Status === "Senha alterada"){
            dispatch(loadRecoverAccountSuccess());
            dispatch(openSnackbar({ severity: "success", message: res.data.Status }));
        } else {
            dispatch(loadRecoverAccountFailure());
            dispatch(openSnackbar({ severity: "error", message: res.data.Status }));
        }
    } catch(err){
        dispatch(loadRecoverAccountFailure());
        dispatch(openSnackbar({ severity: "error", message: "Erro interno" }));
    }
}

export const setInviteDialog = (dispatch, open) => {
    dispatch(openInviteDialog({dialog: open}))
}

export const getAlumns = async (dispatch, accessToken, email, group_id) => {
    dispatch(loadUsersRequest());
    try {
        const res = await axiosInstance.get(`alunos?email=${email}`, {
            headers: { token: "Bearer " + accessToken }
        });
        if(res.data.Status) {
            dispatch(loadUsersFailure());
            dispatch(openSnackbar({ severity: "error", message: res.data.Status }));
        } else {
            dispatch(loadUsersSuccess(res.data.alumns))
        }
    } catch(err) {
        if(err === "Acesso finalizado") {
            dispatch(finishSession())
        } else {
            dispatch(loadUsersFailure());
            dispatch(openSnackbar({ severity: "error", message: "Erro interno" }));
        }
    }
}

export const clearUsersList = (dispatch) => {
    dispatch(clearList());
}

export const addToInviteList = (dispatch, emails) => {
    dispatch(changeInviteList(emails))
}

export const sendInvitation = async (dispatch, accessToken, alumns, notifications, group_title, userVariables, socket) => {
    dispatch(loadSendInviteRequest());
    try {
        const res = await axiosInstance.post("invite", { alumns, notifications, group_title }, {
            headers: { token: "Bearer " + accessToken}
        });
        if(
            res.data.Status === "Convites enviados" || 
            res.data.Status === "Convite enviado"
        ) {
            dispatch(loadSendInviteSuccess());
            dispatch(openSnackbar({ severity: "success", message: res.data.Status }));
            //const receiverId = resp.data.aluno_id;
            alumns.map(alumn => (
                socket.current.emit("sendNotification", {
                    senderId: userVariables.id,
                    receiverId: alumn.id,
                })
            ));
        } else {
            dispatch(loadSendInviteFailure());
            dispatch(openSnackbar({ severity: "error", message: res.data.Status }));
        }
    }catch(err){
        if(err === "Acesso finalizado") {
            dispatch(finishSession())
        } else {
            dispatch(loadSendInviteFailure());
            dispatch(openSnackbar({ severity: "error", message: "Erro interno" }));
        }
    }
}