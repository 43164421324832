import axios from "axios"
import jwtDecode from "jwt-decode";

const baseUrl = 'https://screening-programming-backend.herokuapp.com/api/';
//const baseUrl = 'http://localhost:5000/api/';

const axiosInstance = axios.create({
    baseURL: baseUrl,
    headers: {
        Accept: 'application/json'
    }
});

axiosInstance.interceptors.request.use(
    async (config) => {
        const currentDate = new Date();
        const decodedToken = jwtDecode(config.headers.token.split(" ")[1]);
        if(decodedToken.exp * 1000 < currentDate.getTime()) {
            return Promise.reject("Acesso finalizado");
        }
        return config;
    }, (err) => {
        return Promise.reject("OPAAAA, e ai vei? Beleza?");
    }
)

export default axiosInstance;