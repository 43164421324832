import { 
    Button,
    Dialog, 
    DialogActions, 
    DialogContent, 
    DialogContentText, 
    DialogTitle,
    Typography,
} from "@mui/material"
import { useEffect } from "react";
import { useSelector } from "react-redux";

import { deleteUser, setUserDelete } from "../../../apiCalls/userCalls";

const DeleteUserDialog = ({ accessToken, dispatch }) => {

    const { data, loading, success, dialog } = useSelector(state=>state.user.remove);
    
    useEffect(() => {
        if(success) {
            setUserDelete(dispatch, {}, false);
        }
    }, [success, dispatch]);

    const handleDialog = () => {
        setUserDelete(dispatch, {}, false);
    }

    const handleDelete = () => {
        deleteUser(dispatch, accessToken, data.id);
    }

    return <Dialog 
        id="dialog_delete" 
        open={dialog}
        onClose={handleDialog}
    >
        <DialogTitle align="center">
            DELETE USER
        </DialogTitle>
        <DialogContent>
            <DialogContentText align="center">
                {"Are you sure you want to delete "}
                <b>PERMANENTLY</b> 
                {" the user: "}
                <Typography>
                    <b>
                        {
                            data?.admin?.username || 
                            data?.teacher?.username ||
                            data?.alumn?.username
                        }
                    </b>
                    ?
                </Typography>
            </DialogContentText>
        </DialogContent>
        <DialogActions 
            sx={{
                display: "flex",
                justifyContent: "space-between"
            }}
        >
            <Button 
                variant="contained"
                onClick={handleDialog}
                disabled={loading}
            >
                BACK
            </Button>
            <Button 
                variant="contained"
                onClick={handleDelete}
                disabled={loading}
            >
                DELETE
            </Button>
        </DialogActions>
    </Dialog>
}

export default DeleteUserDialog