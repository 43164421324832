import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import { combineReducers } from "redux";

import userReducer from "./userSlice";
import groupReducer from "./groupSlice";
import notificationReducer from "./notificationSlice";
import activityReducer from "./activitySlice";
import contactReducer from "./contactSlice";
import imageReducer from "./imageSlice";
import snackbarReducer from "./snackbarSlice";

const persistConfig = {
    key: 'root',
    storage
}

const reducer = combineReducers({
    user: userReducer,
    group: groupReducer,
    notification: notificationReducer,
    activity: activityReducer,
    contact: contactReducer,
    image: imageReducer,
    snackbar: snackbarReducer
});

const persistedReducer = persistReducer(persistConfig, reducer);

const store = configureStore({
    reducer: persistedReducer
});

export default store;