import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    activity: {
        data: {},
        dialog: false,
        loading: false,
        success: false,
        error: false
    },
    list: {
        data: [],
        loading: false,
        success: false,
        error: false,
        paginator: {
            first: 0,
            rows: 10,
            page: 0,
            pageCount: 0,
            totalRecords: 0
        }
    },
    results: {
        data: {},
        loading: false,
        success: false,
        error: false
    },
    question: {
        data: {},
        loading: false,
        success: false,
        error: false,
        sending: false,
        saved: false
    },
    answer: {
        data: {},
        loading: false,
        success: false,
        error: false
    },
    answers: {
        data: [],
        loading: false,
        success: false,
        error: false
    },
    finishedActivities: {
        data: [],
        loading: false,
        success: false,
        error: false
    },
    create: {
        data: {},
        dialog: false,
        loading: false,
        success: false,
        error: false,
        create: false
    },
    delete: {
        data: {},
        dialog: false,
        loading: false,
        success: false,
        error: false
    },
    createQuestion: {
        data: {},
        dialog: false,
        loading: false,
        success: false,
        error: false
    },
    deleteQuestions: {
        ids: [],
        dialog: false,
        loading: false,
        success: false,
        error: false,
    }
}

const activitySlice = createSlice({
    name: "activity",
    initialState,
    reducers: {
        //activity
        loadActivityRequest: (state) => {
            state.activity = {
                ...initialState.activity,
                loading: true
            }
        },
        loadActivitySuccess: (state, action) => {
            state.activity = {
                ...initialState.activity,
                success: true,
                data: action.payload
            }
            
        },
        loadActivityFailure: (state) => {
            state.activity = {
                ...initialState.activity,
                error: true,
            }
        },
        changeActivity: (state, action) => {
            state.activity.data = action.payload;
        },

        //activities count
        loadActivitiesCountRequest: (state) => {
            state.list = {
                ...initialState.list,
                loading: true
            }
        },
        loadActivitiesCountSuccess: (state, action) => {
            state.list = {
                ...initialState.list,
                success: true,
                paginator: {
                    ...initialState.list.paginator,
                    page: 0,
                    pageCount: Math.ceil(action.payload/10),
                    totalRecords: action.payload,
                }
            }
        },
        loadActivitiesCountFailure: (state) => {
            state.list = {
                ...initialState.list,
                error: true
            }
        },

        //activities
        loadActivitiesRequest: (state) => {
            state.list = {
                ...initialState.list,
                loading: true,
                paginator: {
                    ...state.list.paginator
                }
            }
        },
        loadMoreActivitiesRequest: (state) => {
            state.list = {
                ...state.list,
                loading: true
            }
        },
        loadActivitiesSuccess: (state, action) => {
            state.list = {
                ...initialState.list,
                success: true,
                data: action.payload,
                paginator: {
                    ...state.list.paginator,
                    page: state.list.paginator.page + 1
                }
            }
        },
        loadActivitiesFailure: (state) => {
            state.list = {
                ...state.list,
                error: true
            }
        },

        //results
        loadActivityResultsRequest: (state) => {
            state.results = {
                ...initialState.results,
                loading: true
            }
        },
        loadActivityResultsSuccess: (state, action) => {
            state.results = {
                ...initialState.results,
                success: true,
                data: action.payload
            }
        },
        loadActivityResultsFailure: (state) => {
            state.results = {
                ...initialState.results,
                error: true,
            }
        },

        //answers
        loadActivityAnswersRequest: (state) => {
            state.answers = {
                ...initialState.answers,
                loading: true
            }
        },
        loadActivityAnswersSuccess: (state, action) => {
            state.answers = {
                ...initialState.answers,
                success: true,
                data: action.payload
            }
        },
        loadActivityAnswersFailure: (state) => {
            state.answers = {
                ...initialState.answers,
                error: true,
            }
        },

        loadAnswerRequest: (state) => {
            state.answer = {
                ...initialState.answer,
                loading: true
            }
        },
        loadAnswerSuccess: (state, action) => {
            state.answer = {
                ...initialState.answer,
                success: true,
                data: action.payload
            }
        },
        loadAnswerFailure: (state) => {
            state.answer = {
                ...initialState.answer,
                error: true,
            }
        },

        loadFinishedActivitiesRequest: (state) => {
            state.finishedActivities = {
                ...initialState.finishedActivities,
                loading: true
            }
        },
        loadFinishedActivitiesSuccess: (state, action) => {
            state.finishedActivities = {
                ...initialState.finishedActivities,
                success: true,
                data: action.payload
            }
        },
        loadFinishedActivitiesFailure: (state) => {
            state.finishedActivities = {
                ...initialState.finishedActivities,
                error: true,
            }
        },

        //question
        loadQuestionRequest: (state) => {
            state.question = {
                ...initialState.question,
                loading: true
            }
        },
        loadQuestionSuccess: (state, action) => {
            state.question = {
                ...initialState.question,
                success: true,
                data: action.payload
            }
        },
        loadQuestionFailure: (state) => {
            state.question = {
                ...initialState.question,
                error: true,
            }
        },
        loadSaveAnswerRequest: (state) => {
            state.question = {
                ...initialState.question,
                data: state.question.data,
                sending: true,
            }
        },
        loadSaveAnswerSuccess: (state) => {
            state.question = {
                ...initialState.question,
                data: state.question.data,
                saved: true,
            }
        },
        loadSaveAnswerFailure: (state) => {
            state.question = {
                ...initialState.question,
                data: state.question.data,
                error: true,
            }
        },

        //activity dialog
        //to set a date
        setOpenActivityDialog: (state, action) => {
            state.activity = {
                ...initialState.activity,
                dialog: action.payload.dialog,
                data: action.payload.activity
            }
        },
        setFinalDateRequest: (state) => {
            state.activity = {
                ...initialState.activity,
                data: state.activity.data,
                dialog: state.activity.dialog,
                loading: true
            }
        },
        setFinalDateSuccess: (state) => {
            state.activity = {
                ...initialState.activity,
                data: state.activity.data,
                dialog: state.activity.dialog,
                success: true,
            }
        },
        setFinalDateFailure: (state) => {
            state.activity = {
                ...initialState.activity,
                data: state.activity.data,
                dialog: state.activity.dialog,
                error: true,
            }
        },

        loadChangeFinalDateRequest: (state) => {
            state.activity = {
                ...initialState.activity,
                data: state.activity.data,
                dialog: state.activity.dialog,
                loading: true
            }
        },
        loadChangeFinalDateSuccess: (state) => {
            state.activity = {
                ...initialState.activity,
                data: state.activity.data,
                dialog: state.activity.dialog,
                success: true,
            }
        },
        loadChangeFinalDateFailure: (state) => {
            state.activity = {
                ...initialState.activity,
                data: state.activity.data,
                dialog: state.activity.dialog,
                error: true,
            }
        },

        //create or update dialog
        setCreateUpdateDialog: (state, action) => {
            state.create = {
                ...initialState.create,
                data: action.payload.activity,
                dialog: action.payload.dialog
            }
        },
        loadCreateActivityRequest: (state) => {
            state.create = {
                ...initialState.create,
                data: state.create.data,
                dialog: state.create.dialog,
                create: state.create.create,
                loading: true
            }
        },
        loadCreateActivitySuccess: (state) => {
            state.create = {
                ...initialState.create,
                success: true,
            }
        },
        loadCreateActivityFailure: (state) => {
            state.create = {
                ...initialState.create,
                data: state.create.data,
                dialog: state.create.dialog,
                create: state.create.create,
                error: true,
            }
        },

        loadUpdateActivityRequest: (state) => {
            state.create = {
                ...initialState.create,
                data: state.create.data,
                dialog: state.create.dialog,
                loading: true
            }
        },
        loadUpdateActivitySuccess: (state) => {
            state.create = {
                ...initialState.create,
                success: true,
            }
        },
        loadUpdateActivityFailure: (state) => {
            state.create = {
                ...initialState.create,
                data: state.create.data,
                dialog: state.create.dialog,
                error: true
            }
        },

        //delete
        setActivityToDelete: (state, action) => {
            state.delete = {
                ...initialState.delete,
                data: action.payload.activity,
                dialog: action.payload.dialog
            }
        },
        loadDeleteActivityRequest: (state) => {
            state.delete = {
                ...initialState.delete,
                data: state.delete.data,
                dialog: state.delete.dialog,
                loading: true
            }
        },
        loadDeleteActivitySuccess: (state) => {
            state.delete = {
                ...initialState.delete,
                dialog: false,
                success: true,
            }
        },
        loadDeleteActivityFailure: (state) => {
            state.delete = {
                ...initialState.delete,
                data: state.delete.data,
                dialog: state.delete.dialog,
                error: true,
            }
        },

        // create/update question
        setCreateQuestionDialog: (state, action) => {
            state.createQuestion = {
                ...initialState.createQuestion,
                data: action.payload.question,
                dialog: action.payload.dialog
            }
        },
        loadCreateQuestionRequest: (state) => {
            state.createQuestion = {
                ...initialState.createQuestion,
                data: state.createQuestion.data,
                dialog: state.createQuestion.dialog,
                loading: true
            }
        },
        loadCreateQuestionSuccess: (state) => {
            state.createQuestion = {
                ...initialState.createQuestion,
                success: true
            }
        },
        loadCreateQuestionFailure: (state) => {
            state.createQuestion = {
                ...initialState.createQuestion,
                data: state.createQuestion.data,
                dialog: state.createQuestion.dialog,
                error: true
            }
        },

        //delete question(s)
        resetDeleteQuestions: (state) => {
            state.deleteQuestions = {
                ...initialState.deleteQuestions
            }
        },
        insertQuestionToDelete: (state, action) => {
            state.deleteQuestions = {
                ...initialState.deleteQuestions,
                ids: action.payload.ids
            }
        },
        setDeleteQuestionsDialog: (state, action) => {
            state.deleteQuestions = {
                ...initialState.deleteQuestions,
                ids: action.payload.ids,
                dialog: action.payload.dialog
            }
        },
        loadDeleteQuestionsRequest: (state) => {
            state.deleteQuestions = {
                ...initialState.deleteQuestions,
                ids: state.deleteQuestions.ids,
                dialog: state.deleteQuestions.dialog,
                loading: true
            }
        },
        loadDeleteQuestionsSuccess: (state) => {
            state.deleteQuestions = {
                ...initialState.deleteQuestions,
                success: true
            }
        },
        loadDeleteQuestionsFailure: (state) => {
            state.deleteQuestions = {
                ...initialState.deleteQuestions,
                ids: state.deleteQuestions.ids,
                dialog: state.deleteQuestions.dialog,
                error: true
            }
        },

        //paginator
        changePaginator: (state, action) => {
            state.list = {
                ...state.list,
                paginator: {
                    ...state.list.paginator,
                    rows: action.payload.rows,
                    page: action.payload.page
                }
            }
        },
        //reset
        reset: (state) => {
            state = initialState;
        },

        resetActivityLists: (state) => {
            state.list = {
                ...initialState.list
            }
            state.answers = {
                ...initialState.answers
            }
            state.finishedActivities = {
                ...initialState.finishedActivities
            }
        }
    }
})

export const { 
    //activity
    loadActivityRequest, 
    loadActivitySuccess, 
    loadActivityFailure,
    changeActivity, 

    //activities count
    loadActivitiesCountRequest,
    loadActivitiesCountSuccess,
    loadActivitiesCountFailure,

    //activities
    loadActivitiesRequest,
    loadMoreActivitiesRequest,
    loadActivitiesSuccess,
    loadActivitiesFailure,

    //create and update
    setCreateUpdateDialog,
    loadCreateActivityRequest,
    loadCreateActivitySuccess,
    loadCreateActivityFailure,
    loadUpdateActivityRequest,
    loadUpdateActivitySuccess,
    loadUpdateActivityFailure,

    //paginator
    changePaginator,

    //results
    loadActivityResultsRequest,
    loadActivityResultsSuccess,
    loadActivityResultsFailure,

    //answers
    loadActivityAnswersRequest,
    loadActivityAnswersSuccess,
    loadActivityAnswersFailure,

    loadAnswerRequest,
    loadAnswerSuccess,
    loadAnswerFailure,

    //finishedActivities
    loadFinishedActivitiesRequest,
    loadFinishedActivitiesSuccess,
    loadFinishedActivitiesFailure,

    //question
    loadQuestionRequest,
    loadQuestionSuccess,
    loadQuestionFailure,
    loadSaveAnswerRequest,
    loadSaveAnswerSuccess,
    loadSaveAnswerFailure,

    //handle final date
    setOpenActivityDialog,
    setFinalDateRequest,
    setFinalDateSuccess,
    setFinalDateFailure,
    loadChangeFinalDateRequest,
    loadChangeFinalDateSuccess,
    loadChangeFinalDateFailure,

    //delete
    setActivityToDelete,
    loadDeleteActivityRequest,
    loadDeleteActivitySuccess,
    loadDeleteActivityFailure,

    // create/update question
    setCreateQuestionDialog,
    loadCreateQuestionRequest,
    loadCreateQuestionSuccess,
    loadCreateQuestionFailure,

    //delete question(s)
    resetDeleteQuestions,
    insertQuestionToDelete,
    setDeleteQuestionsDialog,
    loadDeleteQuestionsRequest,
    loadDeleteQuestionsSuccess,
    loadDeleteQuestionsFailure,

    //reset
    reset,
    resetActivityLists
} = activitySlice.actions;
export default activitySlice.reducer;