import { useEffect, useRef, useState } from "react";
import { getNotifications, setArrivalNotification, setNotification } from "../apiCalls/notificationCalls";
import { useDispatch, useSelector } from "react-redux";
import {io} from "socket.io-client";

import { 
    Box,
    Button, 
    ClickAwayListener,
    Grow, 
    MenuItem, 
    MenuList, 
    Paper, 
    Popper, 
    Stack,
    styled
} from "@mui/material";

import Skeleton from "./Skeleton";

const NewNotification = styled(Box)({
    display: "flex",
    width: "20px",
    height: "20px",
    right: 0,
    top: 0,
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    backgroundColor: "crimson",
    borderRadius: "50%",
    border: "2px solid #1976d2"
});

export default function Notifications({ accessToken, id }){
    
    const { list } = useSelector(state=>state.notification);
    const dispatch = useDispatch();
    const socket = useRef();

    useEffect(()=>{
        socket.current = io("https://screening-progr-socket.herokuapp.com/");
        socket.current.on("getNotification", () => {
            setArrivalNotification(dispatch, true);
        })
    }, [dispatch]);

    useEffect(()=>{
        if(id){
            socket.current.emit("addUser", id)
        }
    }, [id]);

    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event?.target)) {
            return;
        }

        setOpen(false);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        } else if (event.key === 'Escape') {
            setOpen(false);
        }
    }

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);


    useEffect(() =>{
        if(accessToken && id && open){
            getNotifications(accessToken, dispatch, id);
        }
    }, [accessToken, id, open, dispatch]);
    
    useEffect(() => {
        if(list.arrivalNotification && accessToken && id && open){
            getNotifications(accessToken, dispatch, id);
        }
    }, [accessToken, id, open, list.arrivalNotification, dispatch])

    const handleClick = (notification) => {
        setNotification(dispatch, true, notification);
        handleClose()
    }

    return <Stack direction="row" spacing={2}>
        <div>
            <Button
                ref={anchorRef}
                id="composition-button"
                aria-controls={open ? 'composition-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                sx={{color: "white", position: "relative"}}
                onClick={handleToggle}
            >
                NOTIFICAÇÕES
                {
                    (list.arrivalNotification && !open)
                    && <NewNotification>{
                        list.arrivalCount < 9
                        ? list.arrivalCount
                        : "9+"
                    }</NewNotification>
                }
            </Button>
            <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                placement="bottom-start"
                transition
                disablePortal
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                            placement === 'bottom-start' ? 'left top' : 'left bottom',
                        }}
                    >
                        <Paper sx={{minWidth: "200px", maxHeight: "300px", overflowY: "auto"}}>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList
                                    autoFocusItem={open}
                                    id="composition-menu"
                                    aria-labelledby="composition-button"
                                    onKeyDown={handleListKeyDown}
                                >
                                    {
                                        !list.loading
                                        && list.data.map((n, index) => {
                                            return <MenuItem key={index} onClick={(e) => handleClick(n)}>
                                                <span>{n?.title}</span>
                                            </MenuItem>
                                        })
                                    }
                                    {
                                        !list.loading
                                        && list.data.length === 0
                                        && <Box sx={{textAlign: "center"}}>Tudo calmo</Box>
                                    }
                                    {
                                        list.loading
                                        && <Skeleton type="text" width="120" />
                                    }
                                    
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </div>
    </Stack>
}