import { useEffect, useState } from "react";
import { 
    Box,
    Paper, 
    Table, 
    TableBody, 
    TableCell, 
    TableHead, 
    TableRow, 
    styled,
    TableContainer,
    TablePagination,
    //FormControl,
    //Select,
    //MenuItem,
    TextField
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { changePagination, getUsers, setUserCreate, setUserDelete } from "../../apiCalls/userCalls";
import { 
    DeleteOutlined, 
    VisibilityOutlined
} from "@mui/icons-material";
import UserDatasDialog from "../dialogs/user/user.datas";
import DeleteUserDialog from "../dialogs/user/delete.user";

const BoxGeneralStyled = styled(Box)(({ theme }) => ({
    display: "flex",
    width: "100%",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
}))

const BoxStyled = styled(Box)(({ theme }) => ({
    margin: "0px",
    [theme.breakpoints.down("sm")]: {
        fontSize: "16px"
    }
}));

const PaperStyled = styled(Paper)(({ theme }) => ({
    width: "90%",
    overflowX: "auto",
    padding: "10px",

    [theme.breakpoints.down("sm")]: {
        width: "100%"
    }
}));

const BoxActionsStyled = styled(Box)(({ theme }) => ({
    display: "flex",
    width: "100%",
    bgcolor: "blue",
    justifyContent: "space-around",

    [theme.breakpoints.down("sm")]: {
        justifyContent: "space-between"
    }
}));



const Users = ({ accessToken }) => {

    const { data, loading, paginator } = useSelector(state=>state.user.list);

    const [search, setSearch] = useState("");
    // const [filterBy, setFilterBy] = useState("email");
    const [filteredUsers, setFilteredUsers] = useState([]);
    const dispatch = useDispatch();

    useEffect(()=>{
        if(accessToken){
            getUsers(accessToken, dispatch);
        }
    }, [accessToken, dispatch]);

    useEffect(() => {
        if(data.length > 0 && !loading) {
            setFilteredUsers(data);
        }
    }, [data, loading]);

    const handleFilter = (e) => {
        const value = e.target.value
        setSearch(value);
        if(value !== ""){
            setFilteredUsers(data.filter(u=>u.email.indexOf(value) !== -1));
            /*if(filterBy === "email"){
                setFilteredUsers(data.filter(u=>u.email.indexOf(value) !== -1));
            } else if(filterBy === "name"){
                //pensar nessa possibilidade
                setFilteredUsers(data.filter(u=>u.email.indexOf(value) !== -1));
            }*/
        } else {
            setFilteredUsers(data);
        }
    }

    const handleEdit = (id) => {
        setUserCreate(dispatch, true, false, id)
    }

    const handleDelete = (u)=>{
        setUserDelete(dispatch, u, true);
    }
    
    return (
        <BoxGeneralStyled>
            <UserDatasDialog 
                accessToken={accessToken}
                dispatch={dispatch}
            />
            <DeleteUserDialog 
                accessToken={accessToken}
                dispatch={dispatch}
            />
            <BoxStyled 
                component="h2"
            >USERS</BoxStyled>
            <PaperStyled>
                <Box
                    display="flex"
                    alignItems="flex-end"
                    p={0.5}
                    gap={1}
                >
                    {/*<FormControl sx={{ minWidth: 120 }}>
                        <Select
                            value={filterBy}
                            onChange={(e) => setFilterBy(e.target.value)}
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}
                        >
                            <MenuItem value="email">Email</MenuItem>
                            <MenuItem value="name">Name</MenuItem>
                        </Select>
                    </FormControl>*/}
                    <TextField id="standard-basic" value={search} label="Search by email" onChange={(e) =>handleFilter(e)} variant="standard" />
                </Box>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>ID</TableCell>
                                <TableCell>NAME</TableCell>
                                <TableCell>TYPE</TableCell>
                                <TableCell>EMAIL</TableCell>
                                <TableCell>ACTIONS</TableCell>
                            </TableRow>
                        </TableHead>
                        {
                            !loading
                            && <TableBody>
                                {
                                    filteredUsers
                                        .slice(paginator.page * paginator.rows, paginator.page * paginator.rows + paginator.rows )
                                        .map((u, index) => {
                                        return <TableRow key={index}>
                                            <TableCell>{u.id}</TableCell>
                                            <TableCell>
                                                {
                                                    u.is_admin
                                                    ? u.admin.username
                                                    : (
                                                        u.is_teacher
                                                        ? u.teacher.username
                                                        : u.alumn.username
                                                    )
                                                }
                                            </TableCell>
                                            <TableCell>
                                                {
                                                    u.is_admin
                                                    ? "Admin"
                                                    : (
                                                        u.is_teacher
                                                        ? "Teacher"
                                                        : "Alumn"
                                                    )
                                                }
                                            </TableCell>
                                            <TableCell>{u.email}</TableCell>
                                            <TableCell>
                                                <BoxActionsStyled>
                                                    <Box 
                                                        display="flex"
                                                        width="30px"
                                                        height="30px"
                                                        borderRadius="50%"
                                                        alignItems="center"
                                                        justifyContent="center"
                                                        sx={{backgroundColor: "lightblue", color: "white", cursor: "pointer"}}
                                                        onClick={() => handleEdit(u?.id)}
                                                    >
                                                        <VisibilityOutlined />
                                                    </Box>
                                                    <Box 
                                                        display="flex"
                                                        width="30px"
                                                        height="30px"
                                                        borderRadius="50%"
                                                        alignItems="center"
                                                        justifyContent="center"
                                                        sx={{backgroundColor: "crimson", color: "white", cursor: "pointer"}}
                                                        onClick={() => handleDelete(u)}
                                                    >
                                                        <DeleteOutlined />
                                                    </Box>
                                                </BoxActionsStyled>
                                            </TableCell>
                                        </TableRow>
                                    })
                                }
                                
                            </TableBody>
                        }
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 20]}
                    component="div"
                    count={paginator.pageCount}
                    rowsPerPage={paginator.rows}
                    page={paginator.page}
                    onRowsPerPageChange={(e) => {
                        changePagination(dispatch, {
                            ...paginator,
                            rows: parseInt(e.target.value)
                        })
                    }}
                    onPageChange={(e, page) => {
                        changePagination(dispatch, {
                            ...paginator,
                            page: parseInt(page)
                        })
                    }}
                />
            </PaperStyled>
        </BoxGeneralStyled>
    )
}

export default Users
