import{
    Dialog, 
    DialogActions, 
    DialogContent, 
    DialogContentText, 
    DialogTitle, 
    Tooltip,
    styled,
    Button,
    Typography
} from "@mui/material";
import { useSelector } from "react-redux";
import { removeUserGroup, setUserDelete } from "../../../apiCalls/userCalls";

const DialogActionsStyled = styled(DialogActions)({
    display: "flex",
    justifyContent: "space-between"
});

const DeleteAlumnGroup = ({ accessToken, dispatch, idGroup }) => {

    const { data, loading, dialog } = useSelector(state=>state.user.remove);

    const handleDialog = () => {
        setUserDelete(dispatch, {}, false);
    }

    const handleDelete = () => {
        removeUserGroup(accessToken, dispatch, data.id, idGroup);
    }

    return (
        <Dialog 
            id="dialog_delete" 
            open={dialog}
            onClose={handleDialog}
        >
            <DialogTitle id="dialog_delete_title" align="center">
                REMOVER ALUNO
            </DialogTitle>
            <DialogContent id="dialog_delete_content">
                <DialogContentText align="center">Deseja realmente remover o aluno: <b>{data?.alumn?.username}</b> do grupo?</DialogContentText>
                <DialogContentText align="center">
                    <Typography variant="caption">
                        Todas as estatísticas do aluno serão apagados ao executar esta ação!
                    </Typography>
                </DialogContentText>
            </DialogContent>
            <DialogActionsStyled id="dialog_delete_actions">
                <Tooltip title="Cancelar">
                    <Button
                        id="btn_cancel"
                        onClick={handleDialog}
                        size="small"
                        variant="contained"
                        disabled={loading}
                    >
                        CANCELAR
                    </Button>
                </Tooltip>
                <Tooltip title="Remover">
                    <Button
                        id="btn_remove_yes"
                        onClick={handleDelete}
                        size="small"
                        variant="contained"
                        disabled={loading}
                    >
                        Remover
                    </Button>
                </Tooltip>
            </DialogActionsStyled>
        </Dialog>
    )
}

export default DeleteAlumnGroup