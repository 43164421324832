import { Delete } from "@material-ui/icons";
import {
    Box,
    CardMedia,
    styled
} from "@mui/material"

import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getActivitiesAdmin, getActivitiesCount, setActivityDelete } from "../../apiCalls/activityCalls";
import { useRef, useCallback, useState, useEffect } from "react";
import useActivitySearch from "../infinite.scroll";

const BoxGeneralStyled = styled(Box)({
    display: "flex",
    width: "100%",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    overflowY: "visible"
});

const BoxStyled = styled(Box)(({ theme }) => ({
    margin: "0px",
    [theme.breakpoints.down("sm")]: {
        fontSize: "16px"
    }
}));

const BoxActivitiesStyled = styled(Box)(({ theme }) => ({
    display: "flex",
    width: "90%",
    flexWrap: "wrap",
    justifyContent: "center",

    [theme.breakpoints.down("sm")]: {
        width: "100%"
    }
}));

const ActivityContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    width: "400px",
    minHeight: "100px",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "10px",
    backgroundColor: "#38B6FF",
    borderRadius: "10px",

    [theme.breakpoints.down("sm")]: {
        minHeight: "80px",
        marginBottom: "10px"
    }
}));

const CardMediaStyled = styled(CardMedia)(({ theme }) => ({
    width: "100px",
    objectFit: "contain",
    padding: "10px",
    cursor: "pointer",

    [theme.breakpoints.down("sm")]: {
        width: "80px"
    }
}));

const ActivityWrapper = styled(Box)({
    display: "flex",
    flex: 1,
    height: "100%",
    flexDirection: "column"
});

const ActivityActions = styled(Box)({
    display: "flex",
    width: "100%",
    justifyContent: "flex-end",
});

const Action = styled(Box)(({ theme }) => ({
    display: "flex",
    width: "30px",
    height: "30px",
    cursor: "pointer",
    alignItems: "center",
    justifyContent: "center",
    margin: "0px 5px 15px 5px",
    color: "white",

    [theme.breakpoints.down("sm")]: {
        margin: "0px 5px 8px 5px"
    }
}));

const ActivityTitle = styled("span")(({ theme }) => ({
    color: "white",
    flexWrap: "wrap",
    cursor: "pointer",

    [theme.breakpoints.down("sm")]: {
        fontSize: "14px"
    }
}));

const AllActivities = ({accessToken, user}) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [next, setNext] = useState(1);
    
    const { data, loading, paginator } = useActivitySearch(dispatch, accessToken, next, "admin");


    const observer = useRef();

    const lastActivityElementRef = useCallback(node => {
        if (loading) return
        if (observer.current) observer.current.disconnect()
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && paginator.page < paginator.pageCount) {
                setNext(prev => prev + 1);
            }
        })
        if (node) observer.current.observe(node)
    }, [loading, paginator.page]);
    
    const PF = 'https://screening-programming-backend.herokuapp.com/images/';

    useEffect(()=>{
        if(accessToken){
            getActivitiesCount(dispatch, accessToken, "admin");
            getActivitiesAdmin(dispatch, accessToken, 0, [])
        }
    }, [accessToken, dispatch]);

    const handleClick = (id) => {
        navigate(`/manage_activity/${id}`);
    }

    const click = async (activity) => {
        setActivityDelete(dispatch, true, activity);
    }
    
    return <BoxGeneralStyled>
        <BoxStyled component="h2">ACTIVITIES</BoxStyled>
        <BoxActivitiesStyled>
            {   
                data.map((q, index)=>{
                    if (data.length === index + 1) {
                        return <ActivityContainer key={index} ref={lastActivityElementRef}>
                            <CardMediaStyled 
                                component="img"
                                onClick={()=>handleClick(q.id)}
                                image={ 
                                    q.quiz_img
                                    ? `https://drive.google.com/uc?export=view&id=${q?.quiz_img}`
                                    : PF + "GENERAL.webp"
                                }  
                            />
                            <ActivityWrapper>
                                <ActivityActions>
                                    <Action onClick={() => click(q)}>
                                        <Delete />
                                    </Action>
                                </ActivityActions>
                                <ActivityTitle onClick={()=>handleClick(q.id)}><b>{q.title}</b></ActivityTitle>
                            </ActivityWrapper>
                        </ActivityContainer>
                    } else {
                        return <ActivityContainer key={index}>
                            <CardMediaStyled 
                                component="img"
                                onClick={()=>handleClick(q.id)}
                                image={ 
                                    q.quiz_img
                                    ? `https://drive.google.com/uc?export=view&id=${q?.quiz_img}`
                                    : PF + "GENERAL.webp"
                                }  
                            />
                            <ActivityWrapper>
                                <ActivityActions>
                                    <Action onClick={() => click(q)}>
                                        <Delete />
                                    </Action>
                                </ActivityActions>
                                <ActivityTitle onClick={()=>handleClick(q.id)}><b>{q.title}</b></ActivityTitle>
                            </ActivityWrapper>
                        </ActivityContainer>
                    }
                })
            }
        </BoxActivitiesStyled>
    </BoxGeneralStyled>
}

export default AllActivities
