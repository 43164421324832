import { useEffect } from "react";
import { useSelector } from "react-redux";
import { getActivitiesAnswers } from "../apiCalls/activityCalls";
import Question from './Question';

import {
    Box,
    styled
} from "@mui/material"

const Container = styled(Box)({
    display: "flex",
    width: "100%",
    flexDirection: "column"
});

const BoxTitle = styled(Box)(({ theme }) => ({
    [theme.breakpoints.down("sm")]: {
        fontSize: "14px"
    }
}));

const QuizStatistics = ({ id, accessToken, group_id, dispatch }) => {

    const { answers } = useSelector(state=>state.activity);


    useEffect(()=>{
        if(id && group_id){
            getActivitiesAnswers(dispatch, accessToken, id, group_id)
        }
    }, [id, group_id, dispatch, accessToken]);


    return (
        <Container>
            <BoxTitle
                width="100%"
                textAlign="center"
                component="h4"
            >
                Pressione a imagem para ver em tela cheia
            </BoxTitle>
            {
                !answers.loading
                && answers.data.map((item, index) =>{
                    return <Question 
                        key={index} 
                        question={item} 
                        loading = {answers.loading} 
                        dispatch={dispatch}
                    />
                })
            }
        </Container>
    )
}

export default QuizStatistics
