import {
    openSnackbar,
    closeSnackbar
} from "../redux/snackbarSlice";

export const open = (dispatch, open, severity, message) => {
    dispatch(openSnackbar({open, severity, message}));
}

export const close = (dispatch, open) => {
    dispatch(closeSnackbar({open}));
}