import { useEffect, useState } from "react";
import { CheckCircle, Lock } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { Box, Button, styled, Tooltip } from "@mui/material";
import { useSelector } from "react-redux";

const BoxStyled = styled(Box)({
    display: "flex",
    margin: "5px",
    flexDirection: "column",
    position: "relative"
});

const BoxLockContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    width: "250px",
    height: "300px",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    zIndex: "4",
    borderRadius: "5px",
    backgroundColor: "rgba(75, 75, 75, 0.8)",
    cursor: "not-allowed",

    [theme.breakpoints.down("sm")]: {
        width: "200px",
        height: "245px"
    }
}));

const BoxLockWrapper = styled(Box)({
    display: "flex",
    width: "100%",
    flexDirection: "column",
    alignItems: "center"
});

const Span = styled("span")(({ theme }) => ({
    color: "white",
    textAlign: "center",

    [theme.breakpoints.down("sm")]: {
        fontSize: "14px"
    }
}));

const BoxContent = styled(Box)(({ theme }) => ({
    display: "flex",
    width: "250px",
    height: "180px",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    borderRadius: "10px 10px 0px 0px",
    position: "relative",
    marginTop: "60px",
    color: "white",
    backgroundColor: "#38B6FF",

    [theme.breakpoints.down("sm")]: {
        width: "200px",
        height: "150px"
    }
}));

const BoxWhiteBack = styled(Box)(({ theme }) => ({
    backgroundColor: "white",
    width: "130px",
    height: "130px",
    borderRadius: "50%",
    marginLeft: "5px",
    marginBottom: "160px",
    zIndex: "2",
    position: "absolute",

    [theme.breakpoints.down("sm")]: {
        width: "90px",
        height: "90px",
        marginLeft: "0px",
        marginBottom: "140px"
    }
}));

const Image = styled("img")(({ theme }) => ({
    width: "180px",
    objectFit: "contain",
    zIndex: 3,
    position: "absolute",
    marginBottom: "113px",
    marginLeft: "44px",

    [theme.breakpoints.down("sm")]: {
        width: "125px",
        marginLeft: "27px",
        marginTop: "6px"
    }
}));

const Title = styled("span")(({ theme }) => ({
    display: "flex",
    width: "90%",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    fontSize: "22px",
    marginTop: "120px",
    wordWrap: "break-word",

    [theme.breakpoints.down("sm")]: {
        fontSize: "14px",
        marginTop: "60px"
    }
}));

const ButtonStyled = styled(Button)(({ theme }) => ({
    display: "flex",
    width: "250px",
    height: "50px",
    cursor: "pointer",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "10px",
    backgroundColor: "#38B6FF",
    borderRadius: "5px",
    border: "none",
    color: "white",
    fontSize: "16px",

    [theme.breakpoints.down("sm")]: {
        width: "100%",
        height: "30px",
        marginTop: "5px",
        borderRadius: "5px",
        fontSize: "12px"
    }
}));

export default function QuizzDemo({ activity, is_first, prev_activity_id }) {

    const navigate = useNavigate();
    const { data, loading } = useSelector(state=>state.activity.finishedActivities);
    const { answers } = useSelector(state=>state.activity);

    const [disable, setDisable] = useState(true);
    const [msg, setMsg] = useState("");
    const [buttonTitle, setButtonTitle] = useState("INICIAR TESTE");
    const PF = 'http://localhost:5000/images/';
    //const PF = 'https://screening-programming-backend.herokuapp.com/images/';
    useEffect(() => {
        if(is_first != null && activity?.id != null && !loading){
            if(is_first){
                if(data?.finishedActivities?.length > 0){
                    if(data.finishedActivities.some(a=>a.quiz_id === activity.id)){
                        setButtonTitle("FINALIZADA");
                    } else {
                        if(answers.data.length > 0) {
                            if(answers.data.some(an=>an.question.quiz_id === activity.id)) {
                                setButtonTitle("CONTINUAR RESOLUÇÃO")
                            }
                        }
                        setDisable(false);
                    }
                } else {
                    if(answers.data.length > 0) {
                        if(answers.data.some(an=>an.question.quiz_id === activity.id)) {
                            setButtonTitle("CONTINUAR RESOLUÇÃO")
                        }
                    }
                    setDisable(false);
                }
            } else {
                if(data.finishedActivities?.length > 0 && prev_activity_id != null) {
                    if(data.finishedActivities.some(a=>a.quiz_id === prev_activity_id)){
                        if(data.finishedActivities.some(a=>a.quiz_id === activity.id)){
                            setButtonTitle("FINALIZADA");
                        } else {
                            if(answers.data.length > 0) {
                                if(answers.data.some(an=>an.question.quiz_id === activity.id)) {
                                    setButtonTitle("CONTINUAR RESOLUÇÃO")
                                }
                            }
                            setDisable(false);
                        }
                    } else {
                        setMsg("FINALIZE A ATIVIDADE ANTERIOR");
                    }
                } else {
                    setMsg("FINALIZE A ATIVIDADE ANTERIOR");
                }
            }
        }
    }, [is_first, activity, answers, loading, data.finishedActivities, prev_activity_id]);

    const handleClick = (e) => {
        e.preventDefault();
        navigate(`/activity/${activity.id}`);
    }

    return(
        <>
            {/*
                isLoading ? 
                <Skeleton type={"quizDemo"} /> :*/
                <BoxStyled>
                    {
                        disable 
                        && <Tooltip title="Atividade finalizada">
                            <BoxLockContainer>
                                {
                                    buttonTitle === "FINALIZADA" ? 
                                    <CheckCircle style={{
                                        color: "greenyellow", 
                                        fontSize: "52px",
                                    }} /> :
                                    <BoxLockWrapper>
                                        <Lock style={{color: "red", fontSize: "52px"}} />
                                        <Span><b>{msg}</b></Span>
                                    </BoxLockWrapper>
                                }
                            </BoxLockContainer>
                        </Tooltip>
                    }
                    <BoxContent>
                        <BoxWhiteBack />
                        <Image 
                            src={ 
                                activity && (
                                    activity?.quiz_img 
                                    ? `https://drive.google.com/uc?export=view&id=${activity.quiz_img}` 
                                    : PF + "GENERAL.webp"
                                )
                            } 
                            alt=""
                            loading="lazy" 
                        />
                        <Title>{activity.title}</Title>
                    </BoxContent>
                    <ButtonStyled variant="contained" onClick={handleClick}>{buttonTitle}</ButtonStyled>
                </BoxStyled>
            }
        </>
    )
}