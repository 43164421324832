import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: {
        open: false,
        severity: "error",
        message: ""
    }
}

const snackbarSlice = createSlice({
    name: "snackbar",
    initialState,
    reducers: {
        openSnackbar: (state, action) => {
            state.data = {
                ...initialState.data,
                open: true,
                severity: action.payload.severity,
                message: action.payload.message
            }
        },
        closeSnackbar: (state, action) => {
            state.data = {
                ...initialState.data,
                open: action.payload.open
            }
        }
    }
});

export const {
    openSnackbar,
    closeSnackbar
} = snackbarSlice.actions;

export default snackbarSlice.reducer;