import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { 
    Delete, 
    Edit 
} from "@mui/icons-material";

import { 
    Box, 
    styled, 
    Tooltip, 
    Typography 

} from "@mui/material"

import { 
    resetRemoveGroup,
    setGroupCreate, 
    setGroupDelete 
} from "../apiCalls/groupCalls";


const BoxStyled = styled(Box)({
    display: "flex",
    minWidth: "210px",
    maxWidth: "300px",
    alignItems: "center",
    padding: "5px",
    margin: "10px",
    backgroundColor: "#38B6FF",
    borderRadius: "10px"
});

const Icon = styled(Box)(({ theme }) => ({
    display: "flex",
    width: "30px",
    height: "30px",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    borderRadius: "50%",
    margin: "0px 5px",

    "&:hover": {
        backgroundColor: "#b9e5ff"
    },

    [theme.breakpoints.down("sm")]: {
        width: "20px",
        height: "20px",
    }
}));

const GroupDemo = ({ group }) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleClick = () => {
        resetRemoveGroup(dispatch);
        navigate(`/group_dashboard/${group.id}`);
    }

    /**
     * dispatch, dialog, create, single, group
     */
    const handleEdit = () => {
        const { alumnms, ...other } = group;
        setGroupCreate(dispatch, true, false, false, other);
    }
    
    /**
     * dispatch, 
     * {boolean} dialog to open the dialog 
     * {boolean} single to check if it has to get all groups or only one 
     * {*} group group that will be deleted
     */
    const handleDelete = () => {
        const { alumns, teacher, ...other } = group;
        setGroupDelete(dispatch, other, true, false)
    }

    return (
        <BoxStyled>
            <Box
                m="10px 5px 0px 5px"
            >
                <Box
                    onClick={handleClick}
                    sx={{cursor: "pointer"}}
                >
                    <Box mb={1}><b>{group.title.toUpperCase()}</b></Box>
                    <Box display="flex" alignItems="center">
                        Teacher: 
                        <Typography>
                            {group?.teacher?.username}
                        </Typography>
                    </Box>
                    <Box>Period: {group?.periode}</Box>
                    <Box>Alumn amount: {group?.alumn_count}</Box>
                </Box>
                <Box 
                    display="flex"
                    gap={.5}
                    mt={1}
                    sx={{
                        borderTop: "1px solid lightgray",
                        padding: "5px 0px"
                    }}
                >
                    <Tooltip title="Edit">
                        <Icon onClick={handleEdit}>
                            <Edit />
                        </Icon>
                    </Tooltip>
                    <Tooltip title="Delete">
                        <Icon onClick={handleDelete}>
                            <Delete />
                        </Icon>
                    </Tooltip>
                </Box>
            </Box>
        </BoxStyled>
    )
}

export default GroupDemo