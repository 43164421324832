import jwt_decode from 'jwt-decode';
import { Box, Button, Paper, styled } from "@mui/material";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getActivity, getLastAnswer, getQuestion, resetActivityList } from "../apiCalls/activityCalls";
import Footer from "../components/Footer";
import Progressbar from "../components/Progressbar";
import Trivia from "../components/Trivia";
import ShowImageDialog from '../components/dialogs/image/show.image.dialog';
import { resetUserList } from '../apiCalls/userCalls';
import { resetGroupList } from '../apiCalls/groupCalls';

const BoxStyled = styled(Box)({
    display: "flex",
    width: "100%",
    height: "100%",
    flexDirection: "column",
    overflowY: "visible",
    overflowX: "hidden",
    justifyContent: "space-between"
});

const PaperStyled = styled(Paper)(({ theme }) => ({
    display: "flex",
    width: "90%",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "10px",
    
    [theme.breakpoints.down("sm")]: {
        width: "95%"
    }
}));

const Title = styled("h2")(({ theme }) => ({
    margin: "5px 10px",
    textAlign: "center",

    [theme.breakpoints.down("sm")]: {
        fontSize: "18px"
    }
}));

const SubTitle = styled("span")({
    marginLeft: "10px"
});

const ProgressbarContainer = styled(Box)({
    width: "90%",
    height: "40px"
})

const Activity = () => {

    const { id } = useParams();

    const { activity, question, answer } = useSelector(state=>state.activity);
    const { group } = useSelector(state=>state.group);
    const { data } = useSelector(state=>state.user.accessToken);
    const { saved } = useSelector(state=>state.activity.question);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    
    const [alumn, setAlumn] = useState(0);
    const [completed, setCompleted] = useState(0);
    const [userVariables, setUserVariables] = useState({});


    /*
    to set the alumn group
    */
    useEffect(() => {
        if(group.data?.alumns?.length != null && userVariables?.id != null) {
            setAlumn(group.data?.alumns.find(al=>al.alumn.id === userVariables.id))
        }
    }, [group, userVariables]);

    /*
    to get the activity data
    */
    useEffect(() => {
        if(group?.data != null && data != null && id != null) {
            getActivity(data, dispatch, id);
        }
    }, [group, id, data, dispatch]);

    /*
    to get the last answer, if exists
    */
    useEffect(() => {
        if(alumn?.id != null && activity.data?.id != null) {
            getLastAnswer(data, dispatch, alumn.id, activity.data.id);
        }
    }, [activity, alumn, dispatch, data]);

    /*
    after save, gets the next question
    */
    useEffect(() => {
        //setOpen(false);
        if(saved && alumn?.id != null){
            setCompleted((prev) => prev + 1);
            getQuestion(dispatch, data, question.data.position, activity.data.id, alumn.id);
        }
    }, [saved, activity.data.id, alumn.id, data, dispatch, question.data.position]);

    /*
    after got the last answer, the alumn group and the activity,
    if there is last answer gets the next answer, otherwise, gets the first question
    */
    useEffect(() => {
        if(activity.data?.id != null && !answer.loading) {
            if(answer.data?.question?.position != null && alumn?.id != null) {
                setCompleted(answer.data?.question?.position + 1);
                getQuestion(dispatch, data, answer.data.question.position, activity.data.id, alumn.id);
            } else {
                setCompleted(0);
                getQuestion(dispatch, data, -1, activity.data.id, 0);
            }
        }
    }, [activity, answer, alumn, data, dispatch]);

    /*
    to decode the user token
    */
    useEffect(() => {
        if(data){
          const getDados = async () => {
            const decodedToken = jwt_decode(data);
            if (decodedToken.is_admin || decodedToken.is_teacher) {
                navigate("/")
            } else {
                setUserVariables(decodedToken);
            }
          }
          getDados();
        }
        resetUserList(dispatch);
        resetGroupList(dispatch);
        resetActivityList(dispatch);
    }, [data, dispatch, navigate]);

    const handleFinishActivity = () => {
        navigate(`/group_dashboard/${group.data?.id}`);
    }

    return <BoxStyled>
        <ShowImageDialog />
        <Box 
            display="flex"
            mb={0.5}
            justifyContent="center"
        >
            {
                completed < activity.data?.question_count
                ? <PaperStyled>
                    <Box width="100%">
                        <Title>{activity.data?.title}</Title>
                        <SubTitle>
                            questão: {
                                completed < activity.data?.question_count 
                                ? (completed + 1)
                                : activity.data?.question_count
                            }/{activity.data?.question_count}
                        </SubTitle>
                    </Box>
                    <Box 
                        display="flex"
                        width="100%"
                        flexDirection="column"
                        alignItems="center"
                        pb={2}
                    >
                        <ProgressbarContainer>
                            <Progressbar completed={parseInt((completed/activity.data?.question_count)*100)} />
                        </ProgressbarContainer>
                        <Trivia
                            question={question}
                            group_alumn={alumn}
                            dispatch={dispatch}
                            accessToken={data}
                            group_id={group.data?.id}
                        />
                    </Box>
                </PaperStyled>
                : <PaperStyled>
                    <Title>ATIVIDADE CONCLUÍDA!</Title>
                    <Button 
                        sx={{margin: "30px 0px 10px 0px"}}
                        variant="contained" 
                        onClick={handleFinishActivity}
                    >
                        VOLTAR PARA O INÍCIO
                    </Button>
                </PaperStyled>
            }
        </Box>
        <Footer />
    </BoxStyled>
}

export default Activity