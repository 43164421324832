import { 
    Box, 
    Button, 
    CircularProgress, 
    styled 
} from "@mui/material";

const BoxStyled = styled(Box)({
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-evenly",
    flexWrap: "wrap"
});

const ButtonStyled = styled(Button)({
    width: "100px",
    margin: "10px",
    color: "white",
    backgroundColor: "#38B6FF"
});

const CorrectFalse = ({ sending, send }) => {
    return <BoxStyled>
        <ButtonStyled disabled={sending} variant="contained" onClick={() => send("correct")}>
            {
                sending
                ? <CircularProgress color="primary" size="20px" />
                : "CORRETO"
            }
        </ButtonStyled>
        <ButtonStyled disabled={sending} variant="contained" onClick={() => send("false")}>
            {
                sending
                ? <CircularProgress color="primary" size="20px" />
                : "FALSO"
            }
        </ButtonStyled>
    </BoxStyled>
}

export default CorrectFalse