import { ZoomIn } from "@mui/icons-material";
import { 
    Box,  
    CardMedia, 
    styled, 
    Tooltip 
} from "@mui/material";
import { useState } from "react";
import { saveAnswer } from "../apiCalls/activityCalls";
import { setFile } from "../apiCalls/imageCalls";
import CorrectFalse from "./answers.type/correct.false";
import Multiple from "./answers.type/multiple";
import Open from "./answers.type/open";

const BoxImage = styled(Box)(({ theme }) => ({
    width: "80%",
    minHeight: "300px",
    position: "relative",

    [theme.breakpoints.down("sm")]: {
        width: "100%",
        minHeight: "150px"
    }
}));

const ZoomButton = styled(Box)(({ theme }) => ({
    display: "flex",
    width: "30px",
    height: "30px",
    position: "absolute",
    alignItems: "center",
    top: "10px",
    right: "10px",
    backgroundColor: "#38B6FF",
    color: "white",
    justifyContent: "center",
    borderRadius: "50%",

    [theme.breakpoints.down("sm")]: {
        width: "25px",
        height: "25px"
    }
}));

export default function Trivia({ question, group_alumn, dispatch, accessToken }){
    //const PF = 'https://screening-programming-backend.herokuapp.com/images/';
    const PF = 'http://localhost:5000/images/';

    const [loaded, setLoaded] = useState(false);

    const handleClick = (value) => {
        saveAnswer(
            dispatch, 
            accessToken, 
            {
                question_id: question.data.id, 
                group_alumn_id: group_alumn.id, 
                question_answer: value
            }
        )
    }

    const handleDialogImage = () => {
        setFile(dispatch, question.data?.question_text, true);
    }

    return <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
    >
        <BoxImage>
            <Tooltip title="Ver imagem em tela cheia">
                <ZoomButton onClick={handleDialogImage}>
                    <ZoomIn />
                </ZoomButton>
            </Tooltip>
            <CardMedia
                component="img"
                sx={
                    loaded ? {
                        width: "100%",
                        objectFit: "contain"
                    }
                    : { display: 'none' }
                }
                image = {
                    !question.loading
                    ? `https://drive.google.com/uc?export=view&id=${question.data?.question_text}`
                    : PF + "GENERALQUESTION.webp"
                }
                onLoad={() => setLoaded(true)}
            />
        </BoxImage>
        {
            question.data?.answer_type === "multiple"
            && <Multiple 
                sending={question.sending}
                send={handleClick} 
            />
        }
        {
            question.data?.answer_type === "correct_false"
            && <CorrectFalse
                sending={question.sending}
                send={handleClick}
            />
        }
        {
            question.data?.answer_type === "open"
            && <Open
                sending={question.sending}
                send={handleClick}
            />
        }
    </Box>
}