import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    user: {
        data: {},
        other: {},
        loading: false,
        success: false,
        error: false
    },
    accessToken: {
        data: undefined,
        loading: false,
        success: false,
        error: false
    },
    list: {
        data: [],
        loading: false,
        success: false,
        error: false,
        paginator: {
            first: 0,
            rows: 5,
            page: 0,
            pageCount: 0,
            totalRecords: 0
        }
    },
    edit: {
        user_id: 0,
        create: false,
        loading: false,
        success: false,
        error: false,
        dialog: false,
    },
    remove: {
        data: {},
        loading: false,
        success: false,
        error: false,
        dialog: false,
    },
    result: {
        data: [],
        loading: false,
        alumn: {},
        success: false,
        error: false,
        dialog: false
    },
    recover: {
        loading: false,
        success: false,
        error: false,
    },
    finish: {
        finishSession: false
    },
    invite: {
        inviteList: [],
        dialog: false,
        loading: false,
        success: false,
        error: false,
    },
    
}

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        //user
        loadUserRequest: (state) => {
            state.accessToken = {
                ...initialState.accessToken,
                loading: true
            }
        },
        loadUserSuccess: (state, action) => {
            state.accessToken = {
                ...initialState.accessToken,
                success: true,
                data: action.payload
            }
        },
        loadUserFailure: (state) => {
            state.accessToken = {
                ...initialState.accessToken,
                error: true
            }
        },

        logout: (state) => {
            state.accessToken = {
                ...initialState.accessToken
            }
            state.finish = {
                ...initialState.finish
            }
        },

        //register
        loadUserRegisterRequest: (state) => {
            state.user = {
                ...initialState.user,
                loading: true
            }
        },
        loadUserRegisterSuccess: (state) => {
            state.user = {
                ...initialState.user,
                success: true
            }
        },
        loadUserRegisterFailure: (state) => {
            state.user = {
                ...initialState.user,
                error: true
            }
        },

        //change user
        changeUserRequest: (state) => {
            state.user = {
                ...initialState.user,
                loading: true
            }
        },
        changeUserSuccess: (state, action) => {
            state.user = {
                ...initialState.user,
                success: true
            }
            //verificar
            state.accessToken = {
                ...state.accessToken,
                data: action.payload
            }
        },
        changeUserFailure: (state, action) => {
            state.user = {
                ...initialState.user,
                error: true
            }
        },

        //update user(just for admin)
        updateUserRequest: (state) => {
            state.user = {
                ...initialState.user,
                data: state.user.data,
                loading: true,
            }
        },
        updateUserSuccess: (state) => {
            state.user = {
                ...initialState.user,
                success: true
            }
        },
        updateUserFailure: (state) => {
            state.user = {
                ...initialState.user,
                error: true
            }
        },

        //user attributes
        loadUserAttributesRequest: (state) => {
            state.user = {
                ...initialState.user,
                loading: true
            }
        },
        loadUserAttributesSuccess: (state, action) => {
            state.user = {
                ...initialState.user,
                data: action.payload.data,
                other: action.payload.otherAux,
                success: true
            }
        },
        loadUserAttributesFailure: (state) => {
            state.user = {
                ...initialState.user,
                error: true
            }
        },

        //recoverAccount
        loadRecoverAccountRequest: (state) => {
            state.recover = {
                ...initialState.recover,
                loading: true
            }
        },
        loadRecoverAccountSuccess: (state) => {
            state.recover = {
                ...initialState.recover,
                success: true
            }
        },
        loadRecoverAccountFailure: (state) => {
            state.recover = {
                ...initialState.recover,
                error: true
            }
        },

        //users
        loadUsersRequest: (state) => {
            state.list = {
                ...initialState.list,
                loading: true
            }
        },
        loadUsersSuccess: (state, action) => {
            state.list = {
                ...initialState.list,
                success: true,
                data: action.payload,
                paginator: {
                    ...initialState.list.paginator,
                    pageCount: action.payload.length
                }
            }
        },
        loadUsersFailure: (state) => {
            state.list = {
                ...initialState.list,
                error: true
            }
        },
        clearList: (state) => {
            state.list = {
                ...initialState.list
            }
        },

        //delete
        setUserToDelete: (state, action) => {
            state.remove = {
                ...initialState.remove,
                data: action.payload.user,
                dialog: action.payload.dialog
            }
        },
        loadDeleteRequest: (state) => {
            state.remove = {
                ...initialState.remove,
                data: state.remove.data,
                dialog: state.remove.dialog,
                loading: true
            }
        },
        loadDeleteSuccess: (state) => {
            state.remove = {
                ...initialState.remove,
                dialog: false,
                success: true
            }
        },
        loadDeleteFailure: (state) => {
            state.remove = {
                ...initialState.remove,
                data: state.remove.data,
                dialog: state.remove.dialog,
                error: true
            }
        },

        //create
        setUserToCreate: (state, action) => {
            state.edit = {
                ...initialState.edit,
                user_id: action.payload?.user_id,
                create: action.payload.create,
                dialog: action.payload.dialog
            }
        },
        loadCreateRequest: (state) => {
            state.edit = {
                ...initialState.edit,
                dialog: state.edit.dialog,
                create: state.edit.create,
                user_id: state.edit.user_id,
                loading: true
            }
        },
        loadCreateSuccess: (state) => {
            state.edit = {
                ...initialState.edit,
                success: true
            }
        },
        loadCreateFailure: (state) => {
            state.edit = {
                ...initialState.edit,
                dialog: state.edit.dialog,
                create: state.edit.create,
                user_id: state.edit.user_id,
                error: true
            }
        },

        //alumn result
        loadAlumnResultRequest: (state) => {
            state.result = {
                ...initialState.result,
                dialog: state.result.dialog,
                alumn: state.result.alumn,
                loading: true,
            }
        },
        loadAlumnResultSuccess: (state, action) => {
            state.result = {
                ...initialState.result,
                dialog: state.result.dialog,
                alumn: state.result.alumn,
                data: action.payload,
                success: true
            }
        },
        loadAlumnResultFailure: (state, action) => {
            state.result = {
                ...initialState.result,
                error: true
            }
        },

        //alumn result dialog
        showAlumnResultsDialog: (state, action) => {
            state.result = {
                ...initialState.result,
                alumn: action.payload.alumn,
                dialog: action.payload.dialog
            }
        },

        //invite
        openInviteDialog: (state, action) => {
            state.invite = {
                ...initialState.invite,
                dialog: action.payload.dialog
            }
        },
        changeInviteList: (state, action) => {
            state.invite = {
                ...state.invite,
                inviteList: action.payload
            }
        },
        loadSendInviteRequest: (state) => {
            state.invite = {
                ...initialState.invite,
                inviteList: state.invite.inviteList,
                dialog: state.invite.dialog,
                loading: true
            }
        },
        loadSendInviteSuccess: (state) => {
            state.invite = {
                ...initialState.invite,
                success: true
            }
        },
        loadSendInviteFailure: (state) => {
            state.invite = {
                ...initialState.invite,
                inviteList: state.invite.inviteList,
                dialog: state.invite.dialog,
                error: true
            }
        },

        //finish the user session when the token has been expirated
        finishSession: (state) => {
            state.finish.finishSession = true;
        },

        //paginator
        changePaginator: (state, action) => {
            state.list = {
                ...state.list,
                paginator: {
                    ...state.list.paginator,
                    rows: action.payload.rows,
                    page: action.payload.page
                }
            }
        },

        //reset
        reset: (state) => {
            state = initialState
        },

        resetUserLists: (state) => {
            state.list = {
                ...initialState.list
            }
            state.invite = {
                ...initialState.invite
            }
        }
    }
})

export const { 
    //user
    loadUserRequest, 
    loadUserSuccess, 
    loadUserFailure,
    logout,

    //register
    loadUserRegisterRequest,
    loadUserRegisterSuccess,
    loadUserRegisterFailure,
    
    //changeUser
    changeUserRequest,
    changeUserSuccess,
    changeUserFailure,

    //updateUser (admin)
    updateUserRequest,
    updateUserSuccess,
    updateUserFailure,

    //userAttributes
    loadUserAttributesRequest,
    loadUserAttributesSuccess,
    loadUserAttributesFailure,
    changeUserAttributes,

    //recoverAccount
    loadRecoverAccountRequest,
    loadRecoverAccountSuccess,
    loadRecoverAccountFailure,

    //users
    loadUsersRequest, 
    loadUsersSuccess, 
    loadUsersFailure,
    clearList,

    //delete
    setUserToDelete,
    loadDeleteRequest,
    loadDeleteSuccess,
    loadDeleteFailure,

    //create
    setUserToCreate,
    loadCreateRequest,
    loadCreateSuccess,
    loadCreateFailure,

    //alumn result
    loadAlumnResultRequest,
    loadAlumnResultSuccess,
    loadAlumnResultFailure,

    //alumn result dialog
    showAlumnResultsDialog,
    loadAlumnResultDialogRequest,
    loadAlumnResultDialogSuccess,
    loadAlumnResultDialogFailure,

    //invite
    openInviteDialog,
    changeInviteList,
    loadSendInviteRequest,
    loadSendInviteSuccess,
    loadSendInviteFailure,

    //finish
    finishSession,

    //paginator
    changePaginator,

    //reset
    reset,
    resetUserLists
} = userSlice.actions;
export default userSlice.reducer;