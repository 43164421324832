import { useState } from "react";

import Footer from "../components/Footer";

import {
    Avatar,
    Box,
    Paper,
    styled,
    CircularProgress,
    TextField,
    Button
} from "@mui/material";
import { useSelector } from "react-redux";

import { sendContact } from "../apiCalls/contactCalls";
import { useDispatch } from "react-redux";

const BoxStyled = styled(Box)(({ theme }) => ({
    display: "flex",
    width: "100%",
    height: "calc(100vh - 64px)",
    flexDirection: "column",
    justifyContent: "space-between",
    overflowY: "auto",
    overflowX: "hidden",

    [theme.breakpoints.down("sm")]: {
        height: "calc(100vh - 56px)"
    }
}));

const BoxHeader = styled(Box)({
    display: "flex",
    width: "100%",
    justifyContent: "flex-start",
    alignItems: "flex-end",
});

const AvatarStyled = styled("img")(({ theme }) => ({
    display: "flex",
    width: "150px",
    height: "80px",
    objectFit: "contain",
    marginRight: "5px",

    [theme.breakpoints.down("sm")]: {
        width: "130px",
    }
}));

const BoxTitle = styled(Box)({
    display: "flex",
    backgroundColor: "#c0c1c2",
    flex: 1,
    height: "40px",
    alignItems: "center"
});

const Title = styled("h3")({
    margin: "0px 10px"
});

const BoxContent = styled(Paper)(({ theme }) => ({
    display: "flex",
    width: "70%",
    flexDirection: "column",
    marginTop: "10px",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "10px",
    textAlign: "center",

    [theme.breakpoints.down("sm")]: {
        width: "95%"
    }
}));

const BoxTop = styled(Box)({
    margin: "10px 0px"
});

const BoxMid = styled(Box)({
    display: "flex",
    width: "80%",
    flexDirection: "column"
});

const BoxBottom = styled(Box)({
    display: "flex",
    padding: "10px",
    alignItems: "center",
    justifyContent: "center"
});

const TextFieldStyled = styled(TextField)(({ theme }) => ({
    flex: "1",
    minWidth: "40%",
    margin: "10px",

    [theme.breakpoints.down("sm")]: {
        minWidth: "50%"
    }
}));

export default function Contato() {

    const { loading } = useSelector(state=>state.contact.contact);

    const dispatch = useDispatch();

    const [contact, setContact] = useState({
        username: "",
        email: "",
        subject: "",
        message: ""
    });

    const handleChange = (e) => {
        setContact({
            ...contact,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        sendContact(dispatch, contact);
    }

    return <Box sx={{ width: "100%", height: "100%" }}>
        <BoxStyled>
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center" 
                mb={.5}
            >
                <BoxHeader>
                    <Box>
                        <AvatarStyled src="/assets/1638717038835Logotipo.webp" alt="" />
                    </Box>
                    <BoxTitle>
                        <Title>CONTATO</Title>
                    </BoxTitle>
                </BoxHeader>
                <BoxContent>
                    <form onSubmit={handleSubmit}>
                        <BoxTop>
                            <Title>ENTREREMOS EM CONTATO O MAIS RÁPIDO POSSÍVEL.</Title>
                        </BoxTop>
                        <BoxMid>
                            <TextFieldStyled
                                id="outlined-username-input"
                                label="Nome"
                                name="username"
                                value={contact.username}
                                required
                                onChange={handleChange}
                                placeholder="Nome"
                                autoComplete="off"
                            />
                            <TextFieldStyled
                                id="outlined-email-input"
                                label="Email"
                                name="email"
                                value={contact.email}
                                required
                                type="email"
                                onChange={handleChange}
                                placeholder="Email"
                                autoComplete="off"
                            />
                            <TextFieldStyled
                                id="outlined-assunto-input"
                                label="Assunto"
                                name="subject"
                                value={contact.subject}
                                required
                                onChange={handleChange}
                                placeholder="Assunto"
                            />
                            <TextFieldStyled
                                id="outlined-assunto-input"
                                label="Mensagem"
                                name="message"
                                multiline
                                value={contact.message}
                                maxRows={4}
                                required
                                onChange={handleChange}
                                placeholder="Assunto"
                            />
                        </BoxMid>
                        <BoxBottom>
                            <Button 
                                style={{
                                    backgroundColor: "#38B6FF",
                                    margin: "0px 5px",
                                    color: "white"
                                }}
                                disabled={
                                    loading
                                } 
                                type="submit"
                            >
                                {
                                    loading
                                    ? <CircularProgress color="primary" size="20px" />
                                    : "Enviar"
                                }
                            </Button>
                        </BoxBottom>
                    </form>
                </BoxContent>
            </Box>
            <Footer />
        </BoxStyled>
    </Box>
}