import { 
    Box,
    styled
} from "@mui/material";

const Container = styled(Box)({
    display: "flex",
    marginTop: "3px",
    width: "90%",
    height: "30px",
    justifyContent: "space-between",
    position: "relative"
});

const Progress = styled(Box)((props) =>({
    display: "flex",
    width: `${props.completed - 4}%`,
    height: "10px",
    marginLeft: "2px",
    marginTop: "4px",
    backgroundColor: "#38B6FF",
    zIndex: 10,
    position: "absolute",
    transition: "400ms ease-in-out"
}));

const Step = styled(Box)((props) =>({
    display: "flex",
    flexDirection: "column",
    alignItems: `${props.index === 0 ? "start" : "flex-end"}`,
    zIndex: 11
}));
    
const Identification = styled(Box)((props) =>({
    display: "flex",
    width: "20px",
    height: "20px",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "14px",
    borderRadius: "50%",
    backgroundColor: `${props.active ? "#38B6FF" : "#8d8d8d"}`,
    color: `${props.active ? "white" : "black"}`,
    transition: "400ms ease-in-out"
}));

const ProgressbarCheck = ({currentStep, steps}) => {

    function getCompleted() {
        return currentStep === 1 ? 5 : (currentStep/steps.length) * 100
    }

    return <Container>
        <Progress completed={getCompleted()} />
        {
            steps.map((step, index) => {
                return <Step key={index} index={index}>
                    <Identification active={currentStep >= step.actual}>{step.value}</Identification>
                    <span>{step?.description}</span>
                </Step>
            })
        }
    </Container>
}

export default ProgressbarCheck