import { useEffect, useState } from "react"

export default function CalculateCorrectFalse(answers){
    const [loadingCorrectFalse, setLoadingCorrectFalse] = useState(false);
    const [isCorrect, setIsCorrect] = useState(0);
    const [isFalse, setIsFalse] = useState(0);
    const [hasAnswersCorrectFalse, setHasAnswersCorrectFalse] = useState(false);

    useEffect(() => {
        if(answers.length > 0) {
            setHasAnswersCorrectFalse(true);
            setLoadingCorrectFalse(true);
            for (let val of answers) {
                if (val.question_answer === "correct") {
                    setIsCorrect(prev => prev+1)
                } else if (val.question_answer === "false") {
                    setIsFalse(prev => prev+1)
                }
            }
            setLoadingCorrectFalse(false);
        }
    }, [answers]);

    return { 
        loadingCorrectFalse, 
        valuesCorrectFalse: [
            { title: "CORRETO", correto: isCorrect},
            { title: "FALSO", falso: isFalse},
        ],
        hasAnswersCorrectFalse
    }
}