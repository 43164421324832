import { useEffect } from "react";
import { useSelector } from "react-redux"
import { 
    getActivities, 
    getActivitiesAdmin 
} from "../apiCalls/activityCalls";


export default function useActivitySearch(dispatch, accessToken, next, type, group_id){
    const { data, loading, paginator } = useSelector(state=>state.activity.list);


    useEffect(() => {
        if (accessToken && next && type === "admin") {
            getActivitiesAdmin(
                dispatch, 
                accessToken, 
                data[data.length - 1]?.id != null
                ? data[data.length - 1]?.id
                : 0,
                data
            )
        }
        if (accessToken && next && type === "teacher" && group_id) {
            getActivities(
                accessToken, 
                dispatch, 
                group_id, 
                data[data.length - 1]?.id != null
                ? data[data.length - 1]?.id
                : 0,
                data
            );
        }
    }, [dispatch, accessToken, next]);

    return { data, loading, paginator }
}
