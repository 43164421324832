import { useState } from "react";
import AddBoxIcon from '@material-ui/icons/AddBox';
// import styled from "styled-components";
import { Box, Tab, Tabs, styled, Button } from "@mui/material";

//import CadastrarTurma from "../components/CadastrarTurma";
import MyDatas from "../components/Tabs/MyDatas";
import Groups from "../components/Tabs/Groups";
import Footer from "../components/Footer";
import { useDispatch } from "react-redux";
import { setGroupCreate } from "../apiCalls/groupCalls";

const BoxStyled = styled(Box)(({ theme }) => ({
    display: "flex",
    width: "100%",
    height: "calc(100vh - 64px)",
    flexDirection: "column",
    justifyContent: "space-between",
    overflowY: "auto",
    overflowX: "hidden",

    [theme.breakpoints.down("sm")]: {
        height: "calc(100vh - 56px)"
    }
}));

export default function HomeProfessor({userVariables, accessToken}) {

    const [activated, setActivated] = useState(0);
    const [changed, setChanged] = useState(false);
    const dispatch = useDispatch();

    /**
     * dispatch
     * {boolean} dialog to handle the dialog opening
     * {boolean} create to check if it's to create or update
     * {boolean} single to check if it's in groups tab or group dashboard
     * {*} group new group
     */
    const handleClick = () => {
        setGroupCreate(dispatch, true, true, false, {});
    }

    const handleChange = (e, newValue) => {
        setActivated(newValue);
    }

    return(
        <BoxStyled>
            <Box mb={0.5}>
                <Tabs value={activated} onChange={handleChange} centered>
                    <Tab label="GRUPOS" />
                    <Tab label="MEUS DADOS" />
                </Tabs>
                <Box>
                    {
                        activated === 0
                        && <Button 
                            sx={{ marginLeft: "10px", marginTop: "10px"}} 
                            variant="contained" 
                            startIcon={<AddBoxIcon />}
                            onClick={handleClick}
                        >
                            NOVO
                        </Button>
                    }
                </Box>
                {
                    activated === 0
                    && <Groups 
                        userVariables={userVariables} 
                        changed={changed} 
                        setChanged={setChanged} 
                        accessToken={accessToken}
                    />
                }
                {
                    activated === 1
                    && <MyDatas userVariables={userVariables} accessToken={accessToken} />
                }
            </Box>
            <Footer />
        </BoxStyled>
    )
}