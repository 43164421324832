import { useSelector } from "react-redux"
import { 
    Box,
    Button,
    Dialog, 
    DialogActions, 
    DialogContent, 
    DialogContentText, 
    DialogTitle,
    styled,
    Tooltip
} from "@mui/material";

import { 
    setNotification, 
    acceptInvite, 
    rejectInvite
} from "../../../apiCalls/notificationCalls";
import { HighlightOff } from "@mui/icons-material";
import { useEffect } from "react";

const ButtonClose = styled(Box)({
    display: "flex",
    position: "absolute",
    width: "27px",
    height: "27px",
    margin: "10px",
    right: 0,
    backgroundColor: "#1976d2",
    color: "white",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    cursor: "pointer"
});

const InviteDialog = ({ dispatch, accessToken }) => {

    const { data, dialog, loading, success } = useSelector(state=>state.notification.notification);

    useEffect(() => {
        if(success){
            setNotification(dispatch, false, {});
        }
    }, [success, dispatch]);

    const handleAccept = () => {
        acceptInvite(dispatch, accessToken, data.id);
    }
    
    const handleReject = () => {
        rejectInvite(dispatch, accessToken, data.id);
    }

    const handleDialog = () => {
        setNotification(dispatch, false, {});
    }

    return <Dialog
        id="dialog-create"
        open={dialog}
        onClose={handleDialog}
    >
        <Box sx={{position: "relative"}}>
            <Tooltip title="Cancelar">
                <ButtonClose disabled={loading} onClick={handleDialog}>
                    <HighlightOff />
                </ButtonClose>
            </Tooltip>
            <DialogTitle align="center">
                {data?.title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText sx={{textAlign: "center"}}>{data?.message}</DialogContentText>
            </DialogContent>
            <DialogActions 
                sx={{
                    display: "flex", 
                    justifyContent: "space-between", 
                    alignItems: "center"
                }}
            >
                <Button disabled={loading} variant="outlined" onClick={handleReject}>REJEITAR</Button>
                <Button disabled={loading} variant="contained" onClick={handleAccept}>ACEITAR</Button>
            </DialogActions>
        </Box>
    </Dialog>
}

export default InviteDialog