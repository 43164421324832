import { 
    loadGroupRequest,
    loadGroupSuccess,
    loadGroupFailure,

    loadGroupsRequest,
    loadGroupsSuccess,
    loadGroupsFailure,

    loadDeleteRequest,
    loadDeleteSuccess,
    loadDeleteFailure,

    changePaginator,

    setGroupToCreate,
    loadCreateGroupRequest,
    loadCreateGroupSuccess,
    loadCreateGroupFailure,

    loadUpdateGroupRequest,
    loadUpdateGroupSuccess,
    loadUpdateGroupFailure,

    changeAlumnGroupPaginator,
    
    setGroupToDelete,
    resetRemove,
    resetGroupLists
} from "../redux/groupSlice";

import {
    finishSession
} from "../redux/userSlice";

import axiosInstance from "./axios";
import { openSnackbar } from "../redux/snackbarSlice";

export const resetGroupList = (dispatch) => {
    dispatch(resetGroupLists())
}

export const getGroup = async(accessToken, dispatch, id) => {
    dispatch(loadGroupRequest());
    try{
        const res = await axiosInstance.get(`turmas/${id}`, {
            headers: {
                token: "Bearer " + accessToken
            }
        });
        if(res.data.Status){
            dispatch(loadGroupFailure());
            // dispatch(openSnackbar({ severity: "error", message: res.data.Status }));
        } else {
            dispatch(loadGroupSuccess(res.data));
        }
    } catch(err){
        if(err === "Acesso finalizado") {
            dispatch(finishSession())
        } else {
            dispatch(loadGroupFailure())
            dispatch(openSnackbar({ severity: "error", message: "Erro interno" }));
        }
    }
}

export const changeAlumnGroupPagination = (dispatch, paginator) => {
    dispatch(changeAlumnGroupPaginator(paginator));
}

export const getGroupAlumn = async (accessToken, dispatch, id) => {
    dispatch(loadGroupRequest());
    try{
        const res = await axiosInstance.get(`alunoTurma/${id}`, {
            headers: { token: "Bearer " + accessToken }
        })
        if(res.data.Status){
            dispatch(loadGroupFailure());
            dispatch(openSnackbar({ severity: "error", message: res.data.Status }));
        } else {
            dispatch(loadGroupSuccess(res.data));
        }
    } catch(err){
        if(err === "Acesso finalizado") {
            dispatch(finishSession())
        } else {
            dispatch(loadGroupFailure("Erro interno"));
            dispatch(openSnackbar({ severity: "error", message: "Erro interno" }));
        }
    }
}

export const getGroups = async (accessToken, dispatch) => {
    dispatch(loadGroupsRequest());
    try {
        const res = await axiosInstance.get("allGroups", {
            headers: {
                'token' : "Beader " + accessToken
            }
        });
        if(res.data.Status) {
            dispatch(loadGroupsFailure());
            dispatch(openSnackbar({ severity: "error", message: res.data.Status }));
        } else {
            dispatch(loadGroupsSuccess(res.data.groups));
        }
    } catch(err) {
        if(err === "Acesso finalizado") {
            dispatch(finishSession());
        } else {
            dispatch(loadGroupsFailure());
            dispatch(openSnackbar({ severity: "error", message: "Erro interno" }));
        }
    }
}

/*id=id do usuário*/
export const getTeacherGroups = async (accessToken, dispatch, id) => {
    dispatch(loadGroupsRequest());
    try{
        const res = await axiosInstance.get(`teacher_groups/${id}`, {
            headers: {
                token: "Bearer " + accessToken
            }
        });
        if(res.data.Status) {
            dispatch(loadGroupsFailure());
            dispatch(openSnackbar({ severity: "error", message: res.data.Status }));
        } else {
            dispatch(loadGroupsSuccess(res.data));
        }
    } catch(err){
        if(err === "Acesso finalizado") {
            dispatch(finishSession())
        } else {
            dispatch(loadGroupsFailure("Erro interno"));
            dispatch(openSnackbar({ severity: "error", message: "Erro interno" }));
        }
    }
}

/*id=id do usuário*/
export const getAlumnGroups = async (accessToken, dispatch, id) => {
    dispatch(loadGroupsRequest());
    try{
        const res = await axiosInstance.get(`alumnGroups/${id}`, {
            headers: {
                token: "Bearer " + accessToken
            }
        });
        if(res.data.Status) {
            dispatch(loadGroupsFailure(res.data.Status));
            dispatch(openSnackbar({ severity: "error", message: res.data.Status }));
        } else {
            dispatch(loadGroupsSuccess(res.data));
        }
    } catch(err){
        if(err === "Acesso finalizado") {
            dispatch(finishSession())
        } else {
            dispatch(loadGroupsFailure("Erro interno"));
            dispatch(openSnackbar({ severity: "error", message: "Erro interno" }));
        }
    }
}

export const setGroupDelete = (dispatch, group, open, single) => {
    dispatch(setGroupToDelete({group, dialog: open, single}))
}

// id = id do grupo
export const removeGroup = async (accessToken, dispatch, id, single, is_admin, teacher_id) => {
    dispatch(loadDeleteRequest());
    try{
        const res = await axiosInstance.delete(`turmas/${id}`,{
            headers:{
                token: "Bearer " + accessToken
            }
        });
        if(res.data.Status === "Turma deletada"){
            dispatch(loadDeleteSuccess());
            dispatch(openSnackbar({ severity: "success", message: res.data.Status }));
            if(!single){
                if(is_admin) {
                    getGroups(accessToken, dispatch);
                } else {
                    getTeacherGroups(accessToken, dispatch, teacher_id);
                }
            }
        } else {
            dispatch(loadDeleteFailure());
            dispatch(openSnackbar({ severity: "error", message: res.data.Status }));
        }
    } catch(err){
        if(err === "Acesso finalizado") {
            dispatch(finishSession())
        } else {
            dispatch(loadDeleteFailure())
            dispatch(openSnackbar({ severity: "error", message: "Erro interno" }));
        }
    }
}

export const changePagination = (dispatch, paginator) => {
    dispatch(changePaginator(paginator));
}

export const setGroupCreate = (dispatch, open, create, single, group) => {
    dispatch(setGroupToCreate({ dialog: open, create, single, group }));
}

export const createGroup = async (dispatch, accessToken, is_admin, teacher_id, group) => {
    dispatch(loadCreateGroupRequest());
    try{
        const res = await axiosInstance.post("turmas", group, {
            headers: { token: "Bearer " + accessToken}
        });
        if(res.data.Status === "Grupo criado com sucesso") {
            dispatch(loadCreateGroupSuccess());
            dispatch(openSnackbar({ severity: "success", message: res.data.Status }));
            if(is_admin){
                getGroups(accessToken, dispatch);
            } else {
                getTeacherGroups(accessToken, dispatch, teacher_id);
            }
        } else {
            dispatch(loadCreateGroupFailure());
            dispatch(openSnackbar({ severity: "error", message: res.data.Status }));
        }
    } catch (err) {
        if(err === "Acesso finalizado") {
            dispatch(finishSession())
        } else {
            dispatch(loadCreateGroupFailure("Erro interno"))
            dispatch(openSnackbar({ severity: "error", message: "Erro interno" }));
        }
    }
}

export const updateGroup = async (dispatch, accessToken, is_admin, teacher_id, group, single) => {
    dispatch(loadUpdateGroupRequest());
    try {
        const res = await axiosInstance.put(`turmas/${group.id}`, group, {
            headers: { token: "Bearer " + accessToken}
        });
        if(res.data.Status === "Dados do grupo alterados") {
            dispatch(loadUpdateGroupSuccess());
            dispatch(openSnackbar({ severity: "success", message: res.data.Status }));
            if (!single) {
                if(is_admin) {
                    getGroups(accessToken, dispatch);
                } else {
                    getTeacherGroups(accessToken, dispatch, teacher_id);
                }
            } else {
                getGroup(accessToken, dispatch, group.id);
            }
        } else {
            dispatch(loadUpdateGroupFailure());
            dispatch(openSnackbar({ severity: "error", message: res.data.Status }));
        }
    } catch(err){
        if(err === "Acesso finalizado") {
            dispatch(finishSession())
        } else {
            dispatch(loadUpdateGroupFailure())
            dispatch(openSnackbar({ severity: "error", message: "Erro interno" }));
        }
    }
}

export const resetRemoveGroup = (dispatch) => {
    dispatch(resetRemove());
}


