import { Navigate } from "react-router-dom";

const PrivateRoute = ({ children, accessToken, redirectTo }) => {

    if (accessToken) {
        return children
    }

    return <Navigate to={redirectTo} />
};

export default PrivateRoute;
