//import { useSelector } from "react-redux";
import { getActivities, getActivitiesCount, setOpenActivity } from "../../apiCalls/activityCalls";
import { useDispatch } from "react-redux";
import { 
    Box, 
    CardMedia, 
    styled
} from "@mui/material";
import moment from "moment";

//import ActivityDemo from "../ActivityDemo";
import { useRef, useCallback, useState, useEffect } from "react";
import useActivitySearch from "../infinite.scroll";

const BoxGeneralStyled = styled(Box)({
    display: "flex",
    width: "100%",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    overflowY: "visible"
});

const BoxStyled = styled(Box)(({ theme }) => ({
    margin: "0px",
    [theme.breakpoints.down("sm")]: {
        fontSize: "16px"
    }
}));

const BoxActivitiesStyled = styled(Box)(({ theme }) => ({
    display: "flex",
    width: "90%",
    flexWrap: "wrap",
    justifyContent: "center",

    [theme.breakpoints.down("sm")]: {
        width: "100%"
    }
}));

const BoxActivityStyled = styled(Box)(({ theme }) => ({
    display: "flex",
    width: "400px",
    minHeight: "120px",
    alignItems: "center",
    margin: "10px",
    backgroundColor: "#38B6FF",
    borderRadius: "10px",
    cursor: "pointer", 

    [theme.breakpoints.down("sm")]: {
        minHeight: "100px"
    }
}));

const CardMediaStyled = styled(CardMedia)(({ theme }) => ({
    width: "110px",
    objectFit: "contain",
    margin: "10px 10px",

    [theme.breakpoints.down("sm")]: {
        width: "90px"
    }
}));

const BoxWrapperStyled = styled(Box)({
    display: "flex",
    flexDirection: "column"
});

const Title = styled("span")(({ theme }) => ({
    color: "white",
    flexWrap: "wrap",

    [theme.breakpoints.down("sm")]: {
        fontSize: "14px"
    }
}));

const Date = styled("span")(({ theme }) => ({
    color: "white",
    fontSize: "14px",

    [theme.breakpoints.down("sm")]: {
        fontSize: "10px"
    }
}));

const Activities = ({id, accessToken}) => {

    //const { data, loading, paginator } = useSelector(state=>state.activity.list);
    const dispatch = useDispatch();
    const [next, setNext] = useState(1);
    
    const { data, loading, paginator } = useActivitySearch(dispatch, accessToken, next, "teacher", id);
    
    const observer = useRef();

    const PF = 'http://localhost:5000/images/';

    const handleClick = (activity) => {
        setOpenActivity(dispatch, true, activity);
    }

    
    useEffect(() => {
        if(id && accessToken){
            getActivitiesCount(dispatch, accessToken, "teacher", id)
            getActivities(accessToken, dispatch, id, 0, []);
        }
    }, [id, accessToken, dispatch]);


    const lastActivityElementRef = useCallback(node => {
        if (loading) return
        if (observer.current) observer.current.disconnect()
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && paginator.page < paginator.pageCount) {
                setNext(prev => prev + 1);
            }
        })
        if (node) observer.current.observe(node)
    }, [loading, paginator.page]);

    return (
        <BoxGeneralStyled>
            <BoxStyled component="h2">ATIVIDADES EXISTENTES</BoxStyled>
            <BoxActivitiesStyled>
                {   
                    data.map((q, index) =>{
                        const noDate = q?.entregas == null
                        if (data.length === index + 1) {
                            return <BoxActivityStyled 
                                key={index} 
                                onClick={() => handleClick(q)}
                                ref={lastActivityElementRef}
                            >
                                <CardMediaStyled
                                    component="img"
                                    image={
                                        q?.quiz_img
                                        ? `https://drive.google.com/uc?export=view&id=${q?.quiz_img}`
                                        : PF + "GENERAL.webp"
                                    }
                                />
                                <BoxWrapperStyled>
                                    <Title>{ q?.title }</Title>
                                    {
                                        noDate
                                        ? <Date>Entrega: Não informado</Date>
                                        : (
                                            (moment(q.entregas.final_date.substring(0, 10)).isAfter(moment().format("YYYY-MM-DD")))
                                            ? <Date>Entrega: {moment(q.entregas.final_date.substring(0, 10)).format("DD/MM/YYYY")}</Date>
                                            : <Date>Entrega: Expirado</Date>
                                        )
                                    }
                                    <Date>{`Status: ${q?.entregas?.is_active ? "Ativo" : "Não ativo"}`}</Date>
                                </BoxWrapperStyled>
                            </BoxActivityStyled>
                        } else {
                            return <BoxActivityStyled 
                                key={index} 
                                onClick={() => handleClick(q)}
                            >
                                <CardMediaStyled
                                    component="img"
                                    image={
                                        q?.quiz_img
                                        ? `https://drive.google.com/uc?export=view&id=${q?.quiz_img}`
                                        : PF + "GENERAL.webp"
                                    }
                                />
                                <BoxWrapperStyled>
                                    <Title>{ q?.title }</Title>
                                    {
                                        noDate
                                        ? <Date>Entrega: Não informado</Date>
                                        : (
                                            (moment(q.entregas.final_date.substring(0, 10)).isAfter(moment().format("YYYY-MM-DD")))
                                            ? <Date>Entrega: {moment(q.entregas.final_date.substring(0, 10)).format("DD/MM/YYYY")}</Date>
                                            : <Date>Entrega: Expirado</Date>
                                        )
                                    }
                                    <Date>{`Status: ${q?.entregas?.is_active ? "Ativo" : "Não ativo"}`}</Date>
                                </BoxWrapperStyled>
                            </BoxActivityStyled>
                        }
                    })
                }
            </BoxActivitiesStyled>
        </BoxGeneralStyled>
    )
}

export default Activities
