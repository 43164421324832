import { 
    Box,
    Button,
    styled
} from "@mui/material";
import { useDispatch } from "react-redux";
import { logoutCall } from "../apiCalls/userCalls";
import Footer from "../components/Footer";

const Container = styled(Box)({
    display: "flex",
    width: "100%",
    height: "100%",
    flexDirection: "column",
    backgroundColor: "rgba(0, 0, 0, 0.8)",
    alignItems: "center",
    justifyContent: "space-between",
    overflowX: "hidden"
});

const Wrapper = styled(Box)(({ theme }) => ({
    display: "flex",
    width: "550px",
    height: "250px",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "10px",
    marginTop: "50px",
    backgroundColor: "#c0c1c2",

    [theme.breakpoints.down("sm")]: {
        width: "90%",
        height: "150px",
        marginTop: "100px"
    }
}));

const Span = styled("span")({
    display: "flex",
    width: "100%",
    justifyContent: "center",
    marginBottom: "30px",
    textAlign: "center"
});

const TokenVerify = () => {

    const dispatch = useDispatch();

    const handleClick = (e) => {
        e.preventDefault();
        logoutCall(dispatch);
    }

    return <Container>
        <Wrapper>
            <Span><b>Seu acesso expirou, faça login novamente para continuar!</b></Span>
            <Button variant="contained" onClick={handleClick}>Login</Button>
        </Wrapper>
        <Footer />
    </Container>
}

export default TokenVerify
