import { 
    Box, 
    Button, 
    Dialog, 
    DialogActions, 
    DialogContent, 
    DialogContentText, 
    DialogTitle, 
    FormControl, 
    InputLabel, 
    MenuItem, 
    Select, 
    TextField
} from '@mui/material'
import { useState } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux'
import { createQuestion, setQuestionDialog, updateQuestion } from '../../../../apiCalls/activityCalls';

const CreateOrEditQuestionDialog = ({ dispatch, accessToken }) => {
    const { dialog, data, loading } = useSelector(state=>state.activity.createQuestion);
    
    const [question, setQuestion] = useState({});

    useEffect(() => {
        setQuestion(data);
    }, [data]);

    const handleDialog = () => {
        setQuestionDialog(dispatch, false, {
            ...question
        })
    }

    const handleChange = (e) => {
        setQuestion({
            ...question,
            [e.target.name]: e.target.value
        })
    }

    const handleQuestion = (e) => {
        const type = e.target.value;
        if (type !== "open") {
            let correct_answer = "A"
            if(type === "correct_false") {
                correct_answer = "correct"
            }
            setQuestion({
                ...question,
                answer_type: type,
                correct_answer
            })
        } else {
            setQuestion({
                ...question,
                correct_answer: undefined,
                answer_type: type
            })
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (question?.id) {
            updateQuestion(dispatch, accessToken, question);
        } else {
            createQuestion(dispatch, accessToken, question);
        }
    }

    const QuestionAnswerButton = (props) => {
        return <Box
            onClick={() => setQuestion({
                ...question,
                correct_answer: props.value
            })}
            sx={{
                display: "flex",
                minWidth: "105px",
                height: "30px",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "5px",
                margin: "10px 0px",
                backgroundColor: `${ props.correct ? "crimson" : "lightblue" }`,
                color: `${ props.correct ? "white" : "black" }`,
                cursor: "pointer"
            }}
        >
            {props.children}
        </Box>
    }

    return <Dialog
        id="dialog-create"
        open={dialog}
        onClose={handleDialog}
    >
        <DialogTitle align="center">
            {
                question?.id != null
                ? "UPDATE QUESTION"
                : "CREATE QUESTION"
            }
        </DialogTitle>
        <DialogContent>
            <DialogContentText textAlign="center">Question informations</DialogContentText>
            <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
            >
                <Box 
                    component="form" 
                    onSubmit={handleSubmit}
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center"
                    }}
                >
                    <TextField
                        id="outlined-question_id-input"
                        label="Question ID"
                        name="question_text"
                        variant="standard"
                        fullWidth={true}
                        value={question.question_text}
                        required
                        onChange={(e) => handleChange(e)}
                    />
                    <TextField
                        id="outlined-question_id-input"
                        label="Position"
                        name="position"
                        variant="standard"
                        type="number"
                        fullWidth={true}
                        placeholder="ex: 0 for the first position"
                        value={question.position}
                        required
                        onChange={(e) => handleChange(e)}
                    />
                    <FormControl fullWidth={true} sx={{marginTop: "10px"}}>
                        <InputLabel id="label-select-input">Answer type</InputLabel>
                        <Select
                            onChange={handleQuestion}
                            labelId="label-select-input"
                            label="Answer type"
                            displayEmpty
                            value={question?.answer_type}
                        >
                            <MenuItem value="multiple">Multiple</MenuItem>
                            <MenuItem value="correct_false">Correct and False</MenuItem>
                            <MenuItem value="open">Open</MenuItem>
                        </Select>
                    </FormControl>
                    {/*<FormControl fullWidth={true} sx={{marginTop: "10px"}}>
                        <InputLabel id="label-select-input" type>Position</InputLabel>
                        <Select
                            onChange={(e) => setQuestion({
                                ...question,
                                position: e.target.value
                            })}
                            labelId="label-select-input"
                            label="Position"
                            displayEmpty
                            value={question?.position}
                            // inputProps={{ 'aria-label': 'test' }}
                        >
                            {
                                Array.from({ length: 10 }).map((item, index)=> {
                                    return <MenuItem 
                                        key={index} 
                                        value={index}
                                    >{index}</MenuItem> 
                                })
                            }
                        </Select>
                    </FormControl>*/}
                    {
                        question?.answer_type === "multiple"
                        && <Box
                            display="flex"
                            justifyContent="space-between"
                            flexWrap="wrap"
                            maxWidth="300px"
                        >
                            <QuestionAnswerButton
                                correct={ question?.correct_answer === "A" }
                                value="A"
                            >A</QuestionAnswerButton>
                            <QuestionAnswerButton
                                correct={ question?.correct_answer === "B" }
                                value="B"
                            >B</QuestionAnswerButton>
                            <QuestionAnswerButton
                                correct={ question?.correct_answer === "C" }
                                value="C"
                            >C</QuestionAnswerButton>
                            <QuestionAnswerButton
                                correct={ question?.correct_answer === "D" }
                                value="D"
                            >D</QuestionAnswerButton>
                        </Box>
                    }
                    {
                        question?.answer_type === "correct_false"
                        && <Box
                            display="flex"
                            justifyContent="space-between"
                            flexWrap="wrap"
                            maxWidth="300px"
                            gap={4}
                        >
                            <QuestionAnswerButton
                                correct={ question?.correct_answer === "correct" }
                                value="correct"
                            >CORRECT</QuestionAnswerButton>
                            <QuestionAnswerButton
                                correct={ question?.correct_answer === "false" }
                                value="false"
                            >FALSE</QuestionAnswerButton>
                        </Box>
                    }
                    <DialogActions
                        sx={{
                            display: "flex",
                            justifyContent: "space-between"
                        }}
                    >
                        <Button 
                            variant="contained" 
                            onClick={handleDialog}
                            disabled={loading}
                            >
                            BACK
                        </Button>
                        <Button 
                            variant="contained" 
                            type="submit"
                            size="small"
                            disabled={loading}
                        >
                            {
                                question?.id != null
                                ? "UPDATE"
                                : "CREATE"
                            }
                        </Button>
                    </DialogActions>
                </Box>
            </Box>
        </DialogContent>
    </Dialog>
}

export default CreateOrEditQuestionDialog