import { useState } from "react";
import { 
    BarChart, 
    Bar, 
    XAxis, 
    CartesianGrid, 
    ResponsiveContainer, 
    Tooltip as TooltipStatistic
} from 'recharts';
import Skeleton from "./Skeleton";
import { setFile } from "../apiCalls/imageCalls";

import {
    Box,
    Card,
    CardMedia,
    CardActionArea,
    CardActions,
    Button,
    Tooltip,
    styled
} from "@mui/material";
import calculateMultiple from "./multiple.calc";
import calculateCorrectFalse from "./correct.false.calc";

const BoxContainer = styled(Box)({
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    margin: "10px 0px",
    overflowY: "visible"
});

const BoxWrapper = styled(Box)({
    display: "flex",
    width: "90%",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column"
});

const CardStyled = styled(Card)({
    display: "flex",
    width: "100%",
    flexDirection: "column",
    justifyContent: "center",
    position: "relative"
});

const CardMediaStyled = styled(CardMedia)(({ theme }) => ({
    width: "100%",
    objectFit: "contain",
    maxHeight: "400px",

    [theme.breakpoints.down("sm")]: {
        maxHeight: "200px"
    }
}));

const CardActionsStyled = styled(CardActions)({
    display: "flex",
    width: "100%",
    flexDirection: "column"
});

const BoxButtons = styled(Box)({
    display: "flex",
    width: "100%",
    flexWrap: "wrap",
    justifyContent: "space-evenly",
    alignItems: "center",
    marginBottom: "10px"
});

const BoxStatistic = styled(Box)({
    display: "flex",
    width: "100%",
    alignItems: "center",
    flexDirection: "column",
    margin: "10px 0px"
});

const BoxTitle = styled(Box)(({ theme }) => ({
    width: "100%",
    textAlign: "center",

    [theme.breakpoints.down("sm")]: {
        fontSize: "14px"
    }
}));

const BoxGraph = styled(Box)(({ theme }) => ({
    width: "70%",
    height: "250px",

    [theme.breakpoints.down("sm")]: {
        width: "100%"
    }
}));

const Question = ({ question, index, loading, dispatch }) => {

    const [show, setShow] = useState(false);
    const COLORS = ['#00C49F', '#E4405F', '#FFBB28', '#FF8042'];
   //const PF = 'https://screening-programming-backend.herokuapp.com/images/';
    const PF = 'http://localhost:5000/images/';

    const[loaded, setLoaded] = useState(false);

    const { loadingMultiple, valuesMultiple, hasAnswersMultiple } = calculateMultiple(question?.answers || [])
    
    const { loadingCorrectFalse, valuesCorrectFalse, hasAnswersCorrectFalse } = calculateCorrectFalse(question?.answers || [])
    

    const showFullImg = () => {
        setFile(dispatch, question.question_img, true)
    }

    const BoxStatistics = (props) => {
        return <Box 
            display={ props.show ? "flex" : "none"}
            flexDirection="column"
        >
            {props.children}
        </Box>
    }
    
    return (
        <>
            {
                loading
                ? <Skeleton type={"question"} />
                : <BoxContainer>
                    <BoxWrapper>
                        <CardStyled>
                            <CardActionArea>
                                {
                                    !loaded
                                    && <Skeleton type="image" />
                                }
                                <CardMediaStyled
                                    component="img"
                                    image={
                                        `https://drive.google.com/uc?export=view&id=${question?.question_text}`
                                    }
                                    alt=""
                                    onClick={showFullImg}
                                    onLoad={() => setLoaded(true)}
                                />
                            </CardActionArea>
                            {
                                (question?.answer_type === "multiple" && !loadingMultiple)
                                && <BoxStatistics show={question?.answer_type === "multiple"}>
                                    <CardActionsStyled>
                                        <BoxButtons
                                            gap={2}
                                        >
                                            <Button
                                                sx={{minWidth: "100px"}}
                                                variant={question.correct_answer === "A" ? "contained" : "outlined"}
                                            >A</Button>
                                            <Button
                                                sx={{minWidth: "100px"}}
                                                variant={question.correct_answer === "B" ? "contained" : "outlined"}
                                            >B</Button>
                                            <Button
                                                sx={{minWidth: "100px"}}
                                                variant={question.correct_answer === "C" ? "contained" : "outlined"}
                                            >C</Button>
                                            <Button
                                                sx={{minWidth: "100px"}}
                                                variant={question.correct_answer === "D" ? "contained" : "outlined"}
                                            >D</Button>
                                        </BoxButtons>
                                        <Box width="100%">
                                            <Button 
                                                size="small" 
                                                color="primary"
                                                onClick={()=>setShow(!show)}
                                            >
                                                Estatísticas
                                            </Button>
                                        </Box>
                                    </CardActionsStyled>
                                    {
                                        show
                                        && <BoxStatistic>
                                            <BoxTitle>Alternativas mais escolhidas</BoxTitle>
                                            {
                                                hasAnswersMultiple 
                                                ? <BoxGraph>
                                                    <ResponsiveContainer width="100%" height="100%">
                                                        <BarChart data={valuesMultiple}>
                                                            <CartesianGrid />
                                                            <XAxis dataKey="title" />
                                                            <TooltipStatistic />
                                                            <Bar dataKey="a" stackId="b" fill={COLORS[0]} />
                                                            <Bar dataKey="b" stackId="b" fill={COLORS[1]} />
                                                            <Bar dataKey="c" stackId="b" fill={COLORS[2]} />
                                                            <Bar dataKey="d" stackId="b" fill={COLORS[3]} />
                                                        </BarChart>
                                                    </ResponsiveContainer>
                                                </BoxGraph>
                                                : "NENHUMA RESPOSTA!"
                                            }
                                        </BoxStatistic>
                                    }
                                </BoxStatistics>
                            }
                            {
                                (question?.answer_type === "correct_false" && !loadingCorrectFalse)
                                && <BoxStatistic show={question?.answer_type === "correct_false"}>
                                    <CardActionsStyled>
                                        <BoxButtons
                                            gap={2}
                                        >
                                            <Button
                                                sx={{minWidth: "100px"}}
                                                variant={question.correct_answer === "correct" ? "contained" : "outlined"}
                                            >CORRETO</Button>
                                            <Button
                                                sx={{minWidth: "100px"}}
                                                variant={question.correct_answer === "false" ? "contained" : "outlined"}
                                            >FALSO</Button>
                                            
                                        </BoxButtons>
                                        <Box width="100%">
                                            <Button 
                                                size="small" 
                                                color="primary"
                                                onClick={()=>setShow(!show)}
                                            >
                                                Estatísticas
                                            </Button>
                                        </Box>
                                    </CardActionsStyled>
                                    {
                                        show
                                        && <BoxStatistic>
                                            <BoxTitle>Alternativas mais escolhidas</BoxTitle>
                                            {
                                                hasAnswersMultiple 
                                                ? <BoxGraph>
                                                    <ResponsiveContainer width="100%" height="100%">
                                                        <BarChart data={valuesCorrectFalse}>
                                                            <CartesianGrid />
                                                            <XAxis dataKey="title" />
                                                            <TooltipStatistic />
                                                            <Bar dataKey="correto" stackId="b" fill={COLORS[0]} />
                                                            <Bar dataKey="falso" stackId="b" fill={COLORS[1]} />
                                                        </BarChart>
                                                    </ResponsiveContainer>
                                                </BoxGraph>
                                                : "NENHUMA RESPOSTA!"
                                            }
                                        </BoxStatistic>
                                    }
                                </BoxStatistic>
                            }
                        </CardStyled>
                    </BoxWrapper>
                </BoxContainer>
            }
        </>
    )
}

export default Question
