import { 
    Box,
    CardMedia,
    Paper,
    Button,
    styled,
    TextField,
    CircularProgress
} from '@mui/material'
import { firebase } from "../utils/auth";
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { register } from '../apiCalls/userCalls';
import Footer from '../components/Footer';
import ProgressbarCheck from "../components/ProgressbarCheck";

const BoxStyled = styled(Box)(({ theme }) => ({
    display: "flex",
    width: "100%",
    height: "calc(100vh - 64px)",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",

    [theme.breakpoints.down("sm")]: {
        height: "calc(100vh - 56px)"
    }
}));

const CardMediaStyled = styled(CardMedia)(({ theme })=>({
    display: "flex",
    width: "800px",
    height: "400px",
    top: "0",
    objectFit: "contain",
    
    [theme.breakpoints.down("sm")]: {
        width: "100%",
        height: "230px"
    }
}));

const PaperStyled = styled(Paper)(({ theme }) => ({
    display: "flex",
    height: "340px",
    width: "250px",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0px 20px",
    marginTop: "150px",
    borderRadius: "5px",
    position: "absolute",
    flexDirection: "column",
    backgroundColor: "#c0c1c2",

    [theme.breakpoints.down("sm")]: {
        marginTop: "100px"
    }
}));

const BoxStep1 = styled(Box)({
    display: "flex",
    width: "100%",
    height: "calc(100% - 100px)",
    padding: "20px 0px",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column"
});

const BoxStep2 = styled("form")({
    display: "flex",
    width: "100%",
    height: "calc(100% - 70px)",
    padding: "20px 0px",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column"
});

const Span = styled("span")({
    display: "flex",
    width: "90%",
    height: "30px",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "5px",
    color: "white",
    backgroundColor: "#38B6FF",
    border: "none",
    cursor: "pointer",
    fontSize: "18px"
});

const HR = styled("hr")({
    width: "90%"
});

const Sep = styled(Box)({
    display: "flex",
    width: "50px",
    height: "50px",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    position: "absolute",
    backgroundColor: "#c0c1c2"
});

const GoogleButton = styled(Box)({
    display: "flex",
    alignItems: "center",
    backgroundColor: "#a5a5a5",
    borderRadius: "5px",
    cursor: "pointer"
});

const GoogleIcon = styled("img")({
    display: "flex",
    width: "40px",
    height: "40px",
    marginRight: "5px",
    objectFit: "cover",
    borderRadius: "5px 0px 0px 5px"
})

const GoogleSpan = styled("span")({
    fontSize: "14px",
    marginRight: "5px"
})

const SpanReturn = styled("span")({
    margin: "0px 10px",
    cursor: "pointer"
})

const Register = () => {

    const [step, setStep] = useState(1);
    const [type, setType] = useState("");
    const navigate = useNavigate();
    const inputStyle = { WebkitBoxShadow: "0 0 0 1000px #c0c1c2 inset" };
    const [user, setUser] = useState({
        username: '',
        email: '',
        password: ''
    });

    const dispatch = useDispatch();

    const { loading } = useSelector(state=>state.user.user);

    const handleType = (e) => {
        setType(e);
        setStep(cur => cur + 1);
    }

    const handleChange = (e) => {
        setUser({
            ...user, [e.target.name]: e.target.value
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if(type === "professor"){
            register(
                dispatch, 
                {
                    email: user.email,
                    password: user.password,
                    is_google_login: false,
                    is_admin: false,
                    is_teacher: true,
                    teacher: {
                        username: user.username
                    }
                }
            )
        } else {
            register(
                dispatch, 
                {
                    email: user.email,
                    password: user.password,
                    is_google_login: false,
                    is_admin: false,
                    is_teacher: false,
                    alumn: {
                        username: user.username
                    }
                }
            )
        }
    }

    const handleGoogleReg = async (e) => {
        e.preventDefault();
        const google_provider = new firebase.auth.GoogleAuthProvider();
        const res = await firebase.auth().signInWithPopup(google_provider);
        if(type === "professor"){
            register(
                dispatch, 
                {
                    email: res.user.email,
                    is_google_login: true,
                    is_admin: false,
                    is_teacher: true,
                    teacher: {
                        username: res.user.displayName
                    }
                }
            )
        } else {
            register(
                dispatch, 
                {
                    email: res.user.email,
                    is_google_login: true,
                    is_admin: false,
                    is_teacher: false,
                    alumn: {
                        username: res.user.displayName
                    }
                }
            )
        }
    }

    const handleLogin = (e) => {
        navigate("/")
    }

    return (
        <BoxStyled>
            <Box
                display="flex"
                width="100%"
                height="100%"
                flexDirection="column"
                alignItems="center"
                position="relative"
            >
                <CardMediaStyled
                    component="img" 
                    image="/assets/1638717038835Logotipo.webp" 
                    alt="logo"
                />
                <PaperStyled>
                    <ProgressbarCheck 
                        currentStep={step} 
                        steps={[
                            {
                                value: 1,
                                actual: 1,
                                description: "Tipo"
                            },
                            {
                                value: 2,
                                actual: 2,
                                description: "Dados"
                            },
                        ]} 
                    />
                    {
                        step === 1
                        && <BoxStep1>
                            <Span onClick={()=>handleType("professor")}>Professor</Span>
                            <Box
                                display="flex"
                                width="100%"
                                height="50px"
                                alignItems="center"
                                justifyContent="center"
                                position="relative"
                            >
                                <HR />
                                <Sep>OU</Sep>
                            </Box>
                            <Span onClick={()=>handleType("aluno")}>Aluno</Span>
                        </BoxStep1>
                    }
                    {
                        step === 2
                        && <BoxStep2 onSubmit={handleSubmit}>
                            <TextField
                                id="outlined-username-input"
                                label="Nome"
                                name="username"
                                value={user.username}
                                onChange={(e) => handleChange(e)}
                                required
                                sx={{marginBottom: "5px"}}
                                inputProps={{ style: inputStyle }}
                            />
                            <TextField
                                id="outlined-email-input"
                                label="Email"
                                name="email"
                                value={user.email}
                                onChange={(e) => handleChange(e)}
                                required
                                type="email"
                                sx={{marginBottom: "5px"}}
                                inputProps={{ style: inputStyle }}
                            />
                            <TextField
                                id="outlined-password-input"
                                label="Senha"
                                name="password"
                                value={user.password}
                                onChange={(e) => handleChange(e)}
                                required
                                type="password"
                            />
                            <Box
                                display="flex"
                                width="100%"
                                mt={0.5}
                                justifyContent="space-between"
                            >
                                <Button 
                                    disabled={loading}
                                    style={{
                                        backgroundColor: "#38B6FF",
                                        margin: "0px 5px",
                                        color: "white"
                                    }}
                                    onClick={() => setStep(cur => cur - 1)}
                                >
                                    {
                                        loading
                                        ? <CircularProgress color='primary' size="20px" />
                                        : "Voltar"
                                    }
                                </Button>
                                <Button 
                                    disabled={loading}
                                    style={{
                                        backgroundColor: "#38B6FF",
                                        margin: "0px 5px",
                                        color: "white"
                                    }}
                                    type="submit"
                                >
                                    {
                                        loading
                                        ? <CircularProgress color='primary' size="20px" />
                                        : "Cadastrar"
                                    }
                                </Button>
                            </Box>
                            <Box
                                mt={0.5}
                                mb={0.5}
                            >
                                {
                                    loading
                                    ? <CircularProgress color="primary" size="20px" />
                                    : <GoogleButton onClick={handleGoogleReg}>
                                        <GoogleIcon src={"/assets/icone_do_google.webp"} />
                                        <GoogleSpan>Cadastrar com o google</GoogleSpan>
                                    </GoogleButton>
                                }
                            </Box>
                            <SpanReturn onClick={handleLogin}>Já possui uma conta?</SpanReturn>
                        </BoxStep2>
                    }
                </PaperStyled>
            </Box>
            <Footer />
        </BoxStyled>
    )
}

export default Register