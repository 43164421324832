import { 
    Box,
    Button,
    Dialog, 
    DialogActions, 
    DialogContent, 
    DialogContentText, 
    DialogTitle, 
    FormControlLabel, 
    Switch, 
    TextField,
    Typography
} from "@mui/material"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux";
import moment from "moment";
import { changeFinalDate, createFinalDate, setOpenActivity } from "../../../apiCalls/activityCalls";

const label = { inputProps: { 'aria-label': 'Switch demo' } };

const ChangeFinalDateDialog = ({ userVariables, dispatch, accessToken }) => {

    const { activity } = useSelector(state=>state.activity);
    const { group } = useSelector(state=>state.group);

    const [data, setData] = useState({
        is_active: false,
        final_date: new Date()
    });
    const [changed, setChanged] = useState(false);
    const [dateAux, setDateAux] = useState(new Date());

    useEffect(() => {
        setChanged(false);
        setDateAux(moment().format("YYYY-MM-DD"));
        console.log(activity.data?.entregas);
        if(activity.data?.entregas != null){
            setData({
                final_date: activity.data.entregas.final_date.substring(0, 10),
                is_active: activity.data.entregas.is_active
            });
        } else {
            setData({
                ...data,
                final_date: moment().format("YYYY-MM-DD")
            });
        }
    }, [activity.data]);

    const handleChange = (e) => {
        setData({
            ...data,
            final_date: e.target.value
        });
        setChanged(true);
    }

    const handleChangeChecked = (e) => {
        setData({
            ...data,
            is_active: e.target.checked
        });
        setChanged(true);
    }

    const handleDialog = () => {
        setChanged((prev) => !prev);
        setOpenActivity(dispatch, false, {});
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if(activity.data?.entregas != null){
            changeFinalDate(dispatch, accessToken, activity.data.entregas.id, data, group.data.id);
        } else {
            createFinalDate(dispatch, accessToken, activity.data.id, data, group.data.id);
        }
    }

    return <Dialog
        id="dialog-create"
        open={activity.dialog}
        onClose={handleDialog}
    >
        <DialogTitle align="center">
            {userVariables.is_admin ? "Change the conclusion date" : "Alterar a data de conclusão"}
        </DialogTitle>
        <DialogContent>
            <DialogContentText align="center">{activity.data?.title}</DialogContentText>
            <Box mt={1} component="form" onSubmit={handleSubmit}>
                <Box
                    display="flex"
                >
                    <FormControlLabel
                        value="start"
                        sx={{marginLeft: "0px"}}
                        control={
                            <Switch 
                                {...label} 
                                checked={data.is_active} 
                                onChange={handleChangeChecked}
                            />
                        }
                        label={"Aplicar para a turma"}
                        labelPlacement="start"
                    />
                </Box>
                <TextField
                    id="outlined-final_date-input"
                    label={ userVariables?.is_admin ? "Final date" : "Data de entrega" }
                    name="title"
                    variant="standard"
                    type="date"
                    fullWidth={true}
                    value={data.final_date}
                    required
                    onChange={(e) => handleChange(e)}
                />
                {
                    <Box>
                        <Typography variant="caption">
                            {"Prazo: "}
                            {
                                moment(data.final_date).isBefore(dateAux)
                                ? "Expirado"
                                : (
                                    moment(data.final_date).diff(dateAux, 'hours') >= 24
                                    ? moment(data.final_date).diff(dateAux, 'days') + " dias"
                                    : "Hoje"
                                )
                            }
                        </Typography>
                    </Box>
                }
                <DialogActions
                    sx={{
                        display: "flex",
                        justifyContent: "space-between"
                    }}
                >
                    <Button variant="contained" onClick={handleDialog}>{userVariables?.is_admin ? "BACK" : "VOLTAR"}</Button>
                    <Button disabled={!changed} variant="contained" type="submit">{userVariables?.is_admin ? "UPDATE" : "SALVAR"}</Button>
                </DialogActions>
            </Box>
        </DialogContent>
    </Dialog>
}

export default ChangeFinalDateDialog