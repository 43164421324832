import { 
    Box, 
    styled, 
    Tooltip
} from "@mui/material";

import { 
    RemoveRedEyeOutlined, 
    DeleteOutline, 
    EditOutlined
} from '@mui/icons-material';
import { setFile } from "../apiCalls/imageCalls";
import { setQuestionDialog, setQuestionsDeleteDialog } from "../apiCalls/activityCalls";

const BoxStyled = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "center"
}));

const Action = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    width: "28px",
    height: "28px",
    cursor: "pointer",

    ":last-child": {
        marginLeft: "10px"
    },

    ":first-child": {
        marginRight: "10px"
    }
}));

const ActivityManageActions = ({ question, dispatch, id }) => {

    const ImagePreview = (props) => {
        return <Action bgcolor={props.backgroundColor} onClick={props.action}>
            {props.children}
        </Action>
    }

    const showFullImg = () => {
        setFile(dispatch, question.question_text, true);
    }

    const handleUpdateQuestion = () => {
        setQuestionDialog(dispatch, true, { ...question, quiz_id: id});
    }

    const handleDelete = () => {
        setQuestionsDeleteDialog(dispatch, [question.id], true);
    }

    return <BoxStyled>
        <ImagePreview backgroundColor="#9BA3EB" action={showFullImg}>
            <Tooltip title="Question preview">
                <RemoveRedEyeOutlined />
            </Tooltip>
        </ImagePreview>
        <ImagePreview backgroundColor="#18978F" action={handleUpdateQuestion}>
            <Tooltip title="Edit question">
                <EditOutlined />
            </Tooltip>
        </ImagePreview>
        <ImagePreview backgroundColor="#EB4747" action={handleDelete}>
            <Tooltip title="Delete question">
                <DeleteOutline />
            </Tooltip>
        </ImagePreview>
    </BoxStyled>
}

export default ActivityManageActions