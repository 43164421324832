import styled, { keyframes } from "styled-components";
import { mobileM } from "../responsive";

/*const useStyles = makeStyles(theme => ({
    "@keyframes skeleton": {
        "to": {
            opacity: 0.5
        }
    },
    boxNotification: {
        display: "flex",
        width: "95%",
        height: "30px",
        alignItems: "center",
        justifyContent: "center",
        margin: "5px 0px",
        borderRadius: "5px",
        animation: `$skeleton 1s ease infinite alternate${theme.transitions.easing.ease}`
    },
    boxSpanStyled: {
        width: "40px",
        height: "20px",
        backgroundColor: "#c0c1c2"
    }
}))*/

const skeleton = keyframes`
    to {
        opacity: 0.5;
    }
`;

const QuizSkeletonContainer = styled.div`
    display: flex;
    width: 80%;
    height: 90%;
    background-color: #c0c1c2;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
`;

const QuizWrapper = styled.div`
    display: flex;
    background-color: #a5a5a5;
`;

const QuizImage = styled.div`
    width: 100%;
    height: 300px;
    animation: ${skeleton} 1s ease infinite alternate;
`;

const QuizButtons = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
`;

const QuizButton = styled.div`
    width: 150px;
    height: 40px;
    font-size: 15px;
    border-radius: 5px;
    color: white;
    background-color: #38B6FF;
    border: none;
    margin: 5px;
    font-size: 18px;
    animation: ${skeleton} 1s ease infinite alternate;

    ${mobileM({
        width: "100px"
    })}
`;

const QuizDemoSkeletonContainer = styled.div`
    display: flex;
    margin: 5px;
    flex-direction: column;
    position: relative;
    animation: ${skeleton} 1s ease infinite alternate;
`;

const QuizDemoContent = styled.div`
    display: flex;
    width: 250px;
    height: 180px;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column;
    border-radius: 10px 10px 0px 0px;
    margin-top: 60px;
    color: white;
    background-color: #38B6FF;
    position: relative;

    ${mobileM({
        width: "200px",
        height: "150px",
    })}
`;

const QuizDemoImage = styled.div`
    display: flex;
    width: 130px;
    height: 130px;
    background-color: #c0c1c2;
    border-radius: 50%;
    position: absolute;
    margin-bottom: 140px;
    margin-right: 56px;
    z-index: 3;

    ${mobileM({
        width: "90px",
        height: "90px",
        marginRight: "52px",
    })}
`;

const QuizDemoTitle = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 22px;
    margin-top: 120px;
`;

const QuizDemoButton = styled.div`
    display: flex;
    width: 250px;
    height: 50px;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    border-radius: 10px;
    border: none;
    background-color: #38B6FF;
    color: white;
    font-size: 18px;

    ${mobileM({
        width: "100%",
        height: "30px",
        marginTop: "5px",
        borderRadius: "5px",
        fontSize: "12px"
    })}
`;

const QuestionContainer = styled.div`
    display: flex;
    width: 100%;
    height: 500px;
    flex-direction: column;
    margin: 10px 0px;
    border: 1px solid lightgray;
    background-color: rgba(120, 120, 120, 0.2);

    ${mobileM({
        height: "450px"
    })}
`;

const QuestionTop = styled.div`
    display: flex;
    width: 100%;
    height: 400px;
    justify-content: center;

    ${mobileM({height: "300px"})}
`;

const QuestionImageContainer = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
`;
    
const QuestionImage = styled.div`
    width: 80%;
    height: 100%;
    background-color: rgba(120, 120, 120, 0.2);
    ${mobileM({width: "100%"})}
    animation: ${skeleton} 1s ease infinite alternate;
`;

const QuestionBottom = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
`;

const QuestionButton = styled.div`
    width: 120px;
    height: 30px;
    border-radius: 5px;
    margin: 10px;
    background-color: rgba(120, 120, 120, 0.2);
    animation: ${skeleton} 1s ease infinite alternate;
`;
    
const StatisticsGeneralContainer = styled.div`
    display: flex;
    width: 400px;
    padding: 10px;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);

    ${mobileM({
        width: "90%"
    })}
`;
    
const StatisticsGeneralBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 400px;
`;

const StatisticsGeneralTitle = styled.div`
    width: 200px;
    height: 10px;
    margin-bottom: 10px;
    background-color: rgba(120, 120, 120, 0.2);
    border-radius: 10px;
    animation: ${skeleton} 1s ease infinite alternate;

    ${mobileM({
        width: "120px"
    })}
`;

const CircleStatistic = styled.div`
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 8px solid rgba(120, 120, 120, 0.2);
    animation: ${skeleton} 1s ease infinite alternate;
`;

const StatisticsGeneralWrapper = styled.div``;

const Text = styled.div`
    width: ${props => props.width}px;
    height: 10px;
    background-color: rgba(120, 120, 120, 0.2);
    border-radius: 10px;
    margin-bottom: 10px;
    animation: ${skeleton} 1s ease infinite alternate;
`;
    
const TextSmall = styled.div`
    width: ${props => props.width}px;
    height: 8px;
    background-color: rgba(120, 120, 120, 0.2);
    border-radius: 10px;
    margin-bottom: 10px;
    animation: ${skeleton} 1s ease infinite alternate;
`;

const GroupContainerSkeleton = styled.div`
    display: flex;
    width: 200px;
    height: 200px;
    align-items: center;
    flex-direction: column;
    background-color: #c0c1c2;
    margin: 20px;
    border-radius: 10px;

    ${mobileM({
        width: "180px",
        height: "180px",
        margin: "5px"

    })}
`;

const GroupTopSkeleton = styled.div`
    display: flex;
    width: 100%;
    height: 80%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

const GroupHeaderSkeleton = styled.div`
    display: flex;
    width: calc(100% - 20px);
    height: 30%;
    padding: 0px 10px;
    border-radius: 10px 10px 0px 0px;
    background-color: #38B6FF;
    justify-content: center;
    flex-direction: column;
`;

const GroupContentSkeleton = styled.div`
    display: flex;
    width: calc(100% - 10px);
    height: 70%;
    padding: 5px;
    flex-direction: column;
`;

const GroupBottomSkeleton = styled.div`
    display: flex;
    width: 100%;
    height: 20%;
    align-items: center;
    border-radius: 0px 0px 10px 10px;
`;

const GroupActionSkeleton = styled.div`
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin: 0px 5px;
    background-color: rgba(120, 120, 120, 0.2);
    animation: ${skeleton} 1s ease infinite alternate;

    ${mobileM({
        width: "20px",
        height: "20px"
    })}
`;

const ImgSkeleton = styled.div`
    width: 100%;
    min-height: 400px;
    border-radius: 5px;
    background-color: rgba(120, 120, 120, 0.2);
    animation: ${skeleton} 1s ease infinite alternate;
    
    ${
        mobileM({
            minHeight: "150px"
        })
    }
`;

const Skeleton = ({type}) =>{
    //const classes = useStyles();
    const QuizSkeleton = () => (
        <QuizSkeletonContainer>
            <QuizWrapper>
                <QuizImage />
            </QuizWrapper>
            <QuizButtons>
                <QuizButton />
                <QuizButton />
                <QuizButton />
                <QuizButton />
            </QuizButtons>
        </QuizSkeletonContainer>
    );

    const QuizDemoSkeleton = () => (
        <QuizDemoSkeletonContainer>
            <QuizDemoContent>
                <QuizDemoImage/>
                <QuizDemoTitle />
            </QuizDemoContent>
            <QuizDemoButton />
        </QuizDemoSkeletonContainer>
    );

    const QuestionSkeleton = () => (
        <QuestionContainer>
            <QuestionTop>
                <QuestionImageContainer>
                    <QuestionImage/>
                </QuestionImageContainer>
            </QuestionTop>
            <QuestionBottom>
                {
                    Array.from({ length: 4 }).map((elem, index)=>(
                        <QuestionButton />
                    ))
                }
            </QuestionBottom>
            <QuestionButton />
        </QuestionContainer>
    );

    const StatisticsGeneralSkeleton = () => (
        <StatisticsGeneralContainer>
            <StatisticsGeneralBox>
                <StatisticsGeneralTitle />
                <CircleStatistic />
            </StatisticsGeneralBox>
            <StatisticsGeneralWrapper>
                <Text width="120" />
                <Text width="150" />
                <Text width="90" />
            </StatisticsGeneralWrapper>
        </StatisticsGeneralContainer>
    )

    const ImageSkeleton = () => (
        <ImgSkeleton />
    )

    const GroupSkeleton = () => (
        <GroupContainerSkeleton>
            <GroupTopSkeleton>
                <GroupHeaderSkeleton>
                    <Text width="120" />
                    <TextSmall width="80" />
                </GroupHeaderSkeleton>
                <GroupContentSkeleton>
                    <Text width="70" />
                    <Text width="90" />
                </GroupContentSkeleton>
            </GroupTopSkeleton>
            <GroupBottomSkeleton>
                <GroupActionSkeleton />
                <GroupActionSkeleton />
            </GroupBottomSkeleton>
        </GroupContainerSkeleton>
    )

    if ( type === "quiz" ) return <QuizSkeleton />
    if ( type === "group" ) return <GroupSkeleton />
    if ( type === "quizDemo" ) return <QuizDemoSkeleton />
    if ( type === "question" ) return <QuestionSkeleton />
    if ( type === "statistics_general" ) return <StatisticsGeneralSkeleton />
    if ( type === "text" ) return <Text />
    if ( type === "image") return <ImageSkeleton />
    //if ( type === "notification" ) return <NotificationSkeleton />
}

export default Skeleton;