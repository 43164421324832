import { 
  Box,
  Button, 
  CircularProgress, 
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogContentText, 
  DialogTitle, 
  FormControl, 
  MenuItem, 
  Select, 
  styled, 
  TextField, 
  Tooltip 
} from "@mui/material";

import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { createGroup, updateGroup, setGroupCreate } from "../../../apiCalls/groupCalls";
import { open } from "../../../apiCalls/snackbarCalls";
import { getTeachers } from "../../../apiCalls/userCalls";

const DialogActionsStyled = styled(DialogActions)({
    display: "flex",
    justifyContent: "space-between"
});

const BoxForm = styled("form")({
    display: "flex",
    minWidth: "250px",
    flexDirection: "column",
    gap: 1
})

const INITIAL_STATE = {
    teacher_id: 0,
    title: '',
    periode: ''
}

const CreateUpdateGroupDialog = ({ accessToken, dispatch, userVariables }) => {

    const { data, loading, dialog, create, single } = useSelector(state=>state.group.edit);
    const { list } = useSelector(state=>state.user);
    const [group, setGroup] = useState(INITIAL_STATE);
    const [teacherId, setTeacherId] = useState(0);

    useEffect(() => {
        if(dialog && accessToken){
            getTeachers(accessToken, dispatch);
        }
    }, [dialog, dispatch, accessToken])

    useEffect(() => {
        if(data?.id) {
            setGroup(data);
        }
    }, [data?.id, data]);

    useEffect(() => {
        if(data?.teacher?.id) {
            setTeacherId(data.teacher.id);
        }
    }, [data?.teacher_id, data]);

    const handleDialog = () => {
        setGroupCreate(dispatch, false);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if(teacherId !== 0 || userVariables.is_teacher) {
            let newGroup
            let tId
            if(userVariables.is_teacher) {
                newGroup = {
                    ...group,
                    teacher_id: userVariables.id
                }
                tId = userVariables.id
            } else {
                newGroup = {
                    ...group,
                    teacher_id: teacherId
                }
                tId = teacherId
            }
            if(create){
                createGroup(dispatch, accessToken, userVariables.is_admin, tId, newGroup);
            } else {
                updateGroup(dispatch, accessToken, userVariables.is_admin, tId, newGroup, single);
            }
        } else {
            // alert("SELECT A TEACHER")
            open(dispatch, true, "error", "SELECT A TEACHER");
        }
    }

    return <Dialog
        id="dialog-create"
        open={dialog}
        onClose={handleDialog}
    >
        <DialogTitle align="center">
            {
                userVariables?.is_admin ? "CREATE GROUP" : "CRIAR GRUPO"
            }
        </DialogTitle>
        <DialogContent>
            
            {
                userVariables?.is_admin
                && <Box 
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    mb={1}
                >
                    <DialogContentText align="center">Teacher group</DialogContentText>
                    <FormControl fullWidth={true} sx={{marginTop: "5px"}}>
                        <Select
                            onChange={(e) => setTeacherId(e.target.value)}
                            displayEmpty
                            value={teacherId}
                            inputProps={{ 'aria-label': 'Without label' }}
                        >
                            <MenuItem value={0}>Select a teacher</MenuItem>
                            {
                                !list.loading
                                && list.data.map((t, index) => {
                                    return <MenuItem key={ index } value={ t.id }>{ t?.teacher?.username }</MenuItem>
                                })
                            }
                        </Select>
                    </FormControl>
                </Box>
            }
            <DialogContentText align="center">{ userVariables?.is_admin ? "Group informations" : "Informações do grupo" }</DialogContentText>
            <BoxForm onSubmit={handleSubmit}>
                <TextField
                    id="outlined-title-input"
                    label={ userVariables?.is_admin ? "Title" : "Título" }
                    name="title"
                    variant="standard"
                    fullWidth={true}
                    value={group.title}
                    required
                    onChange={(e) => setGroup({
                        ...group,
                        [e.target.name]: e.target.value
                    })}
                />
                <TextField
                    id="outlined-period-input"
                    label={ userVariables?.is_admin ? "Period" : "Período" }
                    name="periode"
                    variant="standard"
                    fullWidth={true}
                    value={group.periode}
                    required
                    onChange={(e) => setGroup({
                        ...group,
                        [e.target.name]: e.target.value
                    })}
                />
                <DialogActionsStyled>
                    <Tooltip title={userVariables?.is_admin ? "Cancel" : "Cancelar"}>
                        <Button
                            onClick={handleDialog}
                            size="small"
                            variant="contained"
                            disabled={loading}
                        >
                            {
                                loading
                                ? <CircularProgress color="primary" size="20px" />
                                : (
                                    userVariables?.is_admin
                                    ? "CANCEL"
                                    : "CANCELAR"
                                )
                            }
                        </Button>
                    </Tooltip>

                    <Tooltip title={userVariables?.is_admin ? "Create" : "Criar"}>
                        <Button
                            type="submit"
                            size="small"
                            variant="contained"
                            disabled={loading}
                        >
                            {
                                !loading
                                ? (
                                    create
                                    ? (
                                        userVariables?.is_admin
                                        ? "CREATE"
                                        : "CRIAR"
                                    )
                                    : (
                                        userVariables?.is_admin
                                        ? "UPDATE"
                                        : "ATUALIZAR"
                                    )
                                )
                                : <CircularProgress color="primary" size="20px" />
                            }
                        </Button>
                    </Tooltip>
                </DialogActionsStyled>
            </BoxForm>
        </DialogContent>
    </Dialog>
}

export default CreateUpdateGroupDialog