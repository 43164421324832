import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    group: {
        data: {},
        loading: false,
        success: false,
        error: false,
        paginator: {
            first: 0,
            rows: 5,
            page: 0,
            pageCount: 0,
            totalRecords: 0
        }
    },
    list: {
        data: [],
        loading: false,
        success: false,
        error: false,
        paginator: {
            first: 0,
            rows: 5,
            page: 0,
            pageCount: 0,
            totalRecords: 0
        }
    },
    edit: {
        data: {},
        loading: false,
        success: false,
        error: false,
        dialog: false,
        create: false,
        single: false,
    },
    remove: {
        data: {},
        loading: false,
        success: false,
        error: false,
        dialog: false,
        single: false
    }
}

const groupSlice = createSlice({
    name: "group",
    initialState,
    reducers: {
        //group
        loadGroupRequest: (state) => {
            state.group = {
                ...initialState.group,
                loading: true
            }
            state.edit = {
                ...initialState.edit
            }
        },
        loadGroupSuccess: (state, action) => {
            state.group = {
                ...initialState.group,
                success: true,
                data: action.payload,
                paginator: {
                    ...initialState.group.paginator,
                    pageCount: action.payload.alumns.length
                }
            }
            
        },
        loadGroupFailure: (state) => {
            state.group = {
                ...initialState.group,
                error: true
            }
        },

        //groups
        loadGroupsRequest: (state) => {
            state.list = {
                ...initialState.list,
                loading: true
            }
        },
        loadGroupsSuccess: (state, action) => {
            state.list = {
                ...initialState.list,
                success: true,
                data: action.payload,
                paginator: {
                    ...initialState.list.paginator,
                    pageCount: action.payload.length
                }
            }
        },
        loadGroupsFailure: (state) => {
            state.list = {
                ...initialState.list,
                error: true
            }
        },

        //delete
        setGroupToDelete: (state, action) => {
            state.remove = {
                ...initialState.remove,
                dialog: action.payload.dialog,
                data: action.payload.group,
                single: action.payload.single
            }
        },
        loadDeleteRequest: (state) => {
            state.remove = {
                ...initialState.remove,
                loading: true
            }
        },
        loadDeleteSuccess: (state) => {
            state.remove = {
                ...initialState.remove,
                success: true
            }
        },
        loadDeleteFailure: (state) => {
            state.remove = {
                ...initialState.remove,
                error: true
            }
        },

        //create
        loadCreateGroupRequest: (state) => {
            state.edit = {
                ...initialState.edit,
                loading: true
            }
        },
        loadCreateGroupSuccess: (state) => {
            state.edit = {
                ...initialState.edit,
                success: true
            }
        },
        loadCreateGroupFailure: (state) => {
            state.edit = {
                ...initialState.edit,
                error: true
            }
        },
        setGroupToCreate: (state, action) => {
            state.edit = {
                ...initialState.edit,
                dialog: action.payload.dialog,
                data: action.payload?.group,
                create: action.payload.create,
                single: action.payload.single
            }
        },

        //update
        loadUpdateGroupRequest: (state) => {
            state.edit = {
                ...initialState.edit,
                loading: true
            }
        },
        loadUpdateGroupSuccess: (state) => {
            state.edit = {
                ...initialState.edit,
                success: true,
                dialog: false
            }
        },
        loadUpdateGroupFailure: (state) => {
            state.edit = {
                ...initialState,
                error: true
            }
        },

        //paginator
        changePaginator: (state, action) => {
            state.list.paginator = {
                ...state.list.paginator,
                rows: action.payload.rows,
                page: action.payload.page
            }
        },
        changeAlumnGroupPaginator: (state, action) => {
            state.group.paginator = {
                ...state.group.paginator,
                rows: action.payload.rows,
                page: action.payload.page
            }
        },

        //reset
        reset: (state) => {
            state = initialState;
        },

        resetRemove: (state) => {
            state.remove = {
                ...initialState.remove
            }
        },

        resetGroupLists: (state) => {
            state.list = {
                ...initialState.list
            }
        }
    }
})

export const { 
    //group
    loadGroupRequest, 
    loadGroupSuccess, 
    loadGroupFailure,

    //groups
    loadGroupsRequest,
    loadGroupsSuccess,
    loadGroupsFailure,

    //delete
    setGroupToDelete,
    loadDeleteRequest,
    loadDeleteSuccess,
    loadDeleteFailure,

    //create
    loadCreateGroupRequest,
    loadCreateGroupSuccess,
    loadCreateGroupFailure,
    setGroupToCreate,

    //update
    loadUpdateGroupRequest,
    loadUpdateGroupSuccess,
    loadUpdateGroupFailure,

    //paginator
    changePaginator,
    changeAlumnGroupPaginator,

    //reset
    reset,
    resetRemove,
    resetGroupLists
} = groupSlice.actions;
export default groupSlice.reducer;