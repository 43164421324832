import { 
    Dialog, 
    DialogTitle,
    DialogContent,
    Input, 
    DialogActions,
    Button,
    styled,
    FormControl,
    InputLabel,
    DialogContentText

} from '@mui/material'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import moment from "moment";
import { useNavigate } from "react-router-dom"

import { getUserAttributes, setUserCreate } from '../../../apiCalls/userCalls';

const BoxStyled = styled(DialogContent)(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    overflowY: "auto",
    overflowX: "hidden",

    [theme.breakpoints.down("sm")]: {
        width: "200px"
    }
}));

const InputStyled = styled(Input)(({ theme }) => ({
    width: "100%",
}));

const ariaLabel = { 'aria-label': 'description' };

const UserDatasDialog = ({ accessToken, dispatch }) => {

    const { user, edit } = useSelector(state=>state.user);
    const navigate = useNavigate();


    useEffect(()=>{
        if(edit.user_id !== 0 && accessToken && edit.dialog && !edit.create){
            getUserAttributes(dispatch, accessToken, edit.user_id);
        }
    }, [edit.user_id, accessToken, dispatch, edit.dialog, edit.create]);

    const handleDialog = () => {
        setUserCreate(dispatch, false, false, 0);
    }

    const handleEdit = () => {
        navigate(`/changeData/${edit.user_id}`)
    }

    return <Dialog
        id="dialog-create"
        open={edit.dialog && !edit.create}
        onClose={handleDialog}
    >
        <DialogTitle align="center">{user.data?.username}</DialogTitle>
        <DialogContent sx={{overflowX: "hidden"}}>
            {
                (!user.loading && !user.other?.is_admin)
                ? <BoxStyled>
                    <FormControl fullWidth={true} sx={{ marginBottom: "5px"}}>
                        <InputLabel 
                            sx={{
                                marginLeft: "-14px",
                                marginTop: "5px"
                            }} 
                            htmlFor="input-register"
                        >
                            Register
                        </InputLabel>
                        <InputStyled 
                            disabled 
                            id="input-username"
                            defaultValue={user.data?.register || "Não informado"}
                            inputProps={ariaLabel} 
                        />
                    </FormControl>
                    <FormControl fullWidth={true} sx={{ marginBottom: "5px"}}>
                        <InputLabel 
                            sx={{ 
                                marginLeft: "-14px",
                                marginTop: "5px"
                            }} 
                            htmlFor="input-birthday">Birthday</InputLabel>
                        <InputStyled 
                            disabled 
                            id="input-birthday"
                            defaultValue={
                                user.data?.birthday != null
                                ? moment(user.data?.birthday?.substring(0, 10)).format("DD/MM/YYYY")
                                : "Não informado"
                            } 
                            inputProps={ariaLabel} 
                        />
                    </FormControl>
                    <FormControl fullWidth={true} sx={{ marginBottom: "5px"}}>
                        <InputLabel 
                            sx={{ 
                                marginLeft: "-14px",
                                marginTop: "5px"
                            }} 
                            htmlFor="input-gender">Gender</InputLabel>
                        <InputStyled 
                            disabled 
                            id="input-gender"
                            defaultValue={user.data?.gender || "Não informado"}
                            inputProps={ariaLabel} 
                        />
                    </FormControl>
                    <FormControl fullWidth={true} sx={{ marginBottom: "5px"}}>
                        <InputLabel 
                            sx={{
                                marginLeft: "-14px",
                                marginTop: "5px"
                            }} 
                            htmlFor="input-periode">Period</InputLabel>
                        <InputStyled 
                            disabled 
                            id="input-periode"
                            defaultValue={user.data?.periode || "Não informado"}
                            inputProps={ariaLabel} 
                        />
                    </FormControl>
                    <FormControl fullWidth={true} sx={{ marginBottom: "5px"}}>
                        <InputLabel 
                            sx={{
                                marginLeft: "-14px",
                                marginTop: "5px"
                            }} 
                            htmlFor="input-state">State</InputLabel>
                        <InputStyled 
                            disabled 
                            id="input-state"
                            defaultValue={user.data?.state || "Não informado"}
                            inputProps={ariaLabel} 
                        />
                    </FormControl>
                    <FormControl fullWidth={true} sx={{ marginBottom: "5px"}}>
                        <InputLabel 
                            sx={{
                                marginLeft: "-14px",
                                marginTop: "5px"
                            }} 
                            htmlFor="input-city">City</InputLabel>
                        <InputStyled 
                            disabled 
                            id="input-city"
                            defaultValue={user.data?.city || "Não informado"}
                            inputProps={ariaLabel} 
                        />
                    </FormControl>
                    <FormControl fullWidth={true}>
                        <InputLabel 
                            sx={{ 
                                marginLeft: "-14px",
                                marginTop: "5px"
                            }} 
                            htmlFor="input-institute">Institute</InputLabel>
                        <InputStyled 
                            disabled 
                            id="input-institute"
                            defaultValue={user.data?.institute || "Não informado"}
                            inputProps={ariaLabel} 
                        />
                    </FormControl>
                </BoxStyled>
                : <DialogContentText>
                    Edit the admin data
                </DialogContentText>
            }
        </DialogContent>
        <DialogActions
            sx={{
                display: "flex",

                justifyContent: "space-between"
            }}
        >
            <Button onClick={handleDialog} variant="contained">Voltar</Button>
            <Button onClick={handleEdit} variant="contained">Editar</Button>
        </DialogActions>
    </Dialog>
}

export default UserDatasDialog