import { useEffect } from "react";
import QuizzDemo from "../../components/QuizzDemo";
import { useDispatch, useSelector } from "react-redux";
import { Box, styled } from "@mui/material";
import { getActivitiesAlumn, getAlumnAnswers, getFinishedActivities } from "../../apiCalls/activityCalls";

const BoxGeneralStyled = styled(Box)({
    display: "flex",
    width: "100%",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    overflowY: "visible"
});

const BoxStyled = styled(Box)(({ theme }) => ({
    margin: "0px",
    [theme.breakpoints.down("sm")]: {
        fontSize: "16px"
    }
}));

const BoxActivitiesStyled = styled(Box)(({ theme }) => ({
    display: "flex",
    width: "90%",
    flexWrap: "wrap",
    justifyContent: "center",

    [theme.breakpoints.down("sm")]: {
        width: "100%"
    }
}));

const AlumnActivities = ({id, userVariables, accessToken}) => {

    const { data, loading } = useSelector(state=>state.activity.list);
    const { group } = useSelector(state=>state.group);
    const dispatch = useDispatch();
    //const FL = 'https://forms.gle/jyaSqas5Kjyw4XqS7';

    
    useEffect(() => {
        if(id && accessToken && group.data?.alumns != null && userVariables?.id != null){
            const alumn = group.data.alumns.find(a=> a.alumn.id === userVariables.id);
            if(alumn?.id != null){
                getActivitiesAlumn(accessToken, dispatch, id, []);
                getAlumnAnswers(accessToken, dispatch, alumn.id);
                getFinishedActivities(accessToken, dispatch, alumn.id);
            }
        }
    }, [accessToken, id, dispatch, group, userVariables?.id]);

    return (
        <BoxGeneralStyled>
            <BoxStyled component="h2">ATIVIDADES</BoxStyled>
            <BoxActivitiesStyled>
                {
                    !loading && !group.loading
                    && data.map((a, index) => {
                        const is_first = index === 0
                        return <QuizzDemo 
                            key = {index} 
                            activity={a.quiz} 
                            is_first={is_first}
                            prev_activity_id={!is_first ? data[index - 1].quiz.id : -1}
                        />
                    })
                }
            </BoxActivitiesStyled>
        </BoxGeneralStyled>
    )
}

export default AlumnActivities
