//import styled from "styled-components"
import Footer from "../components/Footer";

import {
    Avatar,
    Box,
    styled
} from "@mui/material"

const BoxStyled = styled(Box)(({ theme }) => ({
    display: "flex",
    width: "100%",
    height: "calc(100vh - 64px)",
    flexDirection: "column",
    justifyContent: "space-between",
    overflowY: "auto",
    overflowX: "hidden",

    [theme.breakpoints.down("sm")]: {
        height: "calc(100vh - 56px)"
    }
}));

const BoxHeader = styled(Box)({
    display: "flex",
    width: "100%",
    justifyContent: "flex-start",
    alignItems: "flex-end",
});

const AvatarStyled = styled(Avatar)(({ theme }) => ({
    display: "flex",
    position: "relative",
    width: "150px",
    height: "80px",
    objectFit: "contain",
    marginRight: "5px",

    [theme.breakpoints.down("sm")]: {
        width: "130px",
    }
}));

const BoxTitle = styled(Box)({
    display: "flex",
    backgroundColor: "#c0c1c2",
    flex: 1,
    height: "40px",
    alignItems: "center"
});

const Title = styled("h3")({
    margin: "0px 10px"
});

const BoxContent = styled(Box)({
    display: "flex",
    width: "90%",
    marginTop: "50px",
    justifyContent: "center",
    marginBottom: "10px",
    textAlign: "center"
});

export default function sobre() {
    return(
        <Box sx={{ width: "100%", height: "100%" }}>
            <BoxStyled>
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center" 
                    mb={.5}
                >
                    <BoxHeader>
                        <Box>
                            <AvatarStyled variant="square" src="/assets/1638717038835Logotipo.webp" alt="" />
                        </Box>
                        <BoxTitle>
                            <Title>SOBRE</Title>
                        </BoxTitle>
                    </BoxHeader>
                    <BoxContent>
                        Screening Programming é um sistema web que tem como objetivo fornecer apoio aos educadores de cursos superiores e técnicos da área de informática a mensurar habilidades cognitivas preditoras de programação. O sistema possui 4 testes para cada uma das respectivas habilidades, a saber: resolução de problemas, pensamento abstrato, raciocínio matemático e flexibilidade cognitiva. O Screening Programming utiliza a Teoria de Resposta ao Item (TRI) para mensurar as habilidades dos alunos. A TRI ganhou destaque no cenário educacional para elaborar/calibrar testes de aptidão, permitindo ajustar os dados a um modelo de representação de respostas fornecidas em avaliações. Desta forma, diferentes alunos ou o mesmo pode ter suas habilidades comparadas entre si, pois usam parâmetros que são medidos estatisticamente, independentemente da amostra usada.
                    </BoxContent>
                </Box>
                <Footer />
            </BoxStyled>
        </Box>
    )
}