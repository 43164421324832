import { 
    Button, 
    CircularProgress, 
    Dialog, 
    DialogActions, 
    DialogContent, 
    DialogContentText, 
    DialogTitle, 
    FormControl, 
    MenuItem, 
    Select, 
    styled, 
    TextField, 
    Tooltip 
} from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { registerAdmin, setUserCreate } from "../../../apiCalls/userCalls";

const DialogActionsStyled = styled(DialogActions)({
    display: "flex",
    justifyContent: "space-between"
});

const BoxForm = styled("form")({
    display: "flex",
    minWidth: "250px",
    flexDirection: "column",
    gap: 1
})

const CreateUpdateUserDialog = ({ accessToken, dispatch }) => {
    
    const { loading, create, dialog } = useSelector(state=>state.user.edit);
    const [user, setUser] = useState({
        email: "",
        password: "",
        is_admin: false,
        is_teacher: false,
    });
    const [userType, setUserType] = useState("teacher");
    const [username, setUsername] = useState("");


    const handleDialog = () => {
        setUserCreate(dispatch, false);
    }

    const handleChange = (e) => {
        setUser({
            ...user,
            [e.target.name]: e.target.value
        })
    }

    const handleUserType = (e) => {
        const value = e.target.value;
        setUserType(value);
        if(value === "teacher"){
            setUser({
                ...user,
                is_teacher: true,
                is_admin: false
            });
        }else if(value === "alumn"){
            setUser({
                ...user,
                is_teacher: false,
                is_admin: false
            })
        } else if(value === "admin"){
            setUser({
                ...user,
                is_teacher: false,
                is_admin: true
            })
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if(user.is_teacher){
            registerAdmin(
                dispatch, 
                {
                    ...user,
                    teacher: {
                        username: username
                    }
                }, 
                accessToken
            );
        } else if(!user.is_teacher){
            registerAdmin(
                dispatch, 
                {
                    ...user,
                    alumn: {
                        username: username
                    }
                }, 
                accessToken
            );
        } else if(user.is_admin){
            registerAdmin(
                dispatch, 
                {
                    ...user,
                    admin: {
                        username: username
                    }
                }, 
                accessToken
            );
        }
    }

    return <Dialog 
        id="dialog_delete" 
        open={dialog && create}
        onClose={handleDialog}
    >
        <DialogTitle id="dialog_delete_title" align="center">
            CREATE USER
        </DialogTitle>
        <DialogContent id="dialog_delete_content" >
            <DialogContentText align="center">User informations</DialogContentText>
            <FormControl sx={{ minWidth: 120, margin: "10px 0px" }}>
                <Select
                    value={userType}
                    onChange={(e) => handleUserType(e)}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                >
                    <MenuItem value="teacher">Teacher</MenuItem>
                    <MenuItem value="alumn">Alumn</MenuItem>
                    <MenuItem value="admin">Admin</MenuItem>
                </Select>
            </FormControl>
            <BoxForm onSubmit={handleSubmit}>
                <TextField
                    id="outlined-username-input"
                    label="Nome"
                    name="username"
                    variant="standard"
                    fullWidth={true}
                    value={username}
                    required
                    onChange={(e) => setUsername(e.target.value)}
                    placeholder="Nome"
                />
                <TextField
                    id="outlined-email-input"
                    label="Email"
                    name="email"
                    variant="standard"
                    fullWidth={true}
                    value={user.email}
                    required
                    onChange={(e) => handleChange(e)}
                    placeholder="Email"
                    type="email"
                />
                <TextField
                    id="outlined-password-input"
                    label="Senha"
                    name="password"
                    variant="standard"
                    fullWidth={true}
                    value={user.password}
                    required
                    onChange={(e) => handleChange(e)}
                    placeholder="Senha"
                    type="password"
                />
                <DialogActionsStyled id="dialog_delete_actions" >
                    <Tooltip title="Cancelar">
                        <Button
                            id="btn_cancel"
                            onClick={handleDialog}
                            size="small"
                            variant="contained"
                            disabled={loading}
                        >
                            {
                                loading
                                ? <CircularProgress color="primary" size="20px" />
                                : "CANCELAR"
                            }
                        </Button>
                    </Tooltip>

                    <Tooltip title="Criar">
                        <Button
                            id="btn_remove_yes"
                            type="submit"
                            size="small"
                            variant="contained"
                            disabled={loading}
                        >
                            {
                                loading
                                ? <CircularProgress color="primary" size="20px" />
                                : "CRIAR"
                            }
                        </Button>
                    </Tooltip>
                </DialogActionsStyled>
            </BoxForm>
        </DialogContent>
    </Dialog>
}

export default CreateUpdateUserDialog