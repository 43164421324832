import { useState } from "react";
import AddBoxIcon from '@material-ui/icons/AddBox';

import Users from "../components/Tabs/Users";
import AllActivities from "../components/Tabs/AllActivities";
import AllGroups from "../components/Tabs/AllGroups";
import { Box, Tab, Tabs, Button, styled } from "@mui/material";
import Footer from "../components/Footer";
import { setUserCreate } from "../apiCalls/userCalls";
import { useDispatch } from "react-redux";
import { setGroupCreate } from "../apiCalls/groupCalls";
import { setActivityDialog } from "../apiCalls/activityCalls";

const BoxStyled = styled(Box)(({ theme }) => ({
    display: "flex",
    width: "100%",
    height: "calc(100vh - 64px)",
    flexDirection: "column",
    justifyContent: "space-between",
    overflowY: "auto",
    overflowX: "hidden",

    [theme.breakpoints.down("sm")]: {
        height: "calc(100vh - 56px)"
    }
}));

const HomeAdmin = ({accessToken}) => {

    const [activated, setActivated] = useState(0);
    
    const dispatch = useDispatch();

    const handleCreate = () => {
        if(activated === 0){
            setUserCreate(dispatch, true, true);
        } else if(activated === 1){
            //navigate("/createActivity");
            setActivityDialog(dispatch, true, {
                title: '',
                quiz_img: '',
                question_count: 0,
                is_active: true,
                previous_activity_id: 0
            })
        } else if(activated === 2){
            setGroupCreate(dispatch, true, true, false, {});
        }
    }

    const handleChange = (e, newValue) => {
        setActivated(newValue);
    }

    return (
        <BoxStyled>
            <Box mb={0.5}>
                <Tabs value={activated} onChange={handleChange} centered>
                    <Tab label="USERS" />
                    <Tab label="ACTIVITIES" />
                    <Tab label="GROUPS" />
                </Tabs>
                <Box>
                    <Button 
                    onClick={handleCreate}
                        variant="contained" 
                        startIcon={<AddBoxIcon />}
                        sx={{ marginLeft: "10px", marginTop: "10px"}} 
                    >
                        NEW
                    </Button>
                </Box>
                {
                    activated === 0
                    && <Users accessToken={accessToken}  />
                }
                {
                    activated === 1
                    && <AllActivities accessToken={accessToken} />
                }
                {
                    activated === 2
                    && <AllGroups accessToken={accessToken} />
                }
            </Box>
            <Footer />
        </BoxStyled>
    )
}

export default HomeAdmin
