import {
    Box, 
    styled
} from "@mui/material"

const ProgressbarContainer = styled(Box)({
    height: 20,
    width: '100%',
    backgroundColor: "#e0e0de",
    borderRadius: 50,
    marginTop: 10
});

const ProgressbarCompletedColor = styled(Box)((props) => ({
    height: '100%',
    width: `${props.completed}%`,
    backgroundColor: '#38B6FF',
    borderRadius: 'inherit',
    textAlign: 'right',
    transition: "400ms ease-in-out"
}));

const ProgressbarLabel = styled("span")({
    padding: 5,
    height: '100%',
    color: 'black',
    fontWeight: 'bold'
})

const Progressbar = ({completed}) => {

    return <ProgressbarContainer>
        <ProgressbarCompletedColor completed={completed}>
            <ProgressbarLabel>{`${completed || "0"}%`}</ProgressbarLabel>
        </ProgressbarCompletedColor>
    </ProgressbarContainer>
}

export default Progressbar;