import { useEffect, useState } from "react";
import GeneralStatistics from "../GeneralStatistics";
import QuizStatistics from "../QuizStatistics";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getActivitiesSummary, getActivity } from "../../apiCalls/activityCalls";
import { 
    Box, 
    FormControl, 
    MenuItem, 
    Paper,
    Select,
    styled
 } from "@mui/material";

const BoxGeneralStyled = styled(Box)({
    display: "flex",
    width: "100%",
    justifyContent: "center"
});

const PaperStyled = styled(Paper)(({ theme }) => ({
    display: "flex",
    width: "90%",
    flexDirection: "column",
    overflowX: "auto",
    padding: "10px",
    marginTop: "10px",
    
    [theme.breakpoints.down("sm")]: {
        width: "100%",
        alignItems: "center",
    }
}));

const BoxStyled = styled(Box)(({ theme }) => ({
    width: "100%",
    textAlign: "center",
    margin: "0px",
    [theme.breakpoints.down("sm")]: {
        fontSize: "16px"
    }
}));

const BoxSelection = styled(Box)(({ theme }) => ({
    display: "flex",
    width: "300px",

    [theme.breakpoints.down("sm")]: {
        width: "90%"
    }
}));

const BoxTop = styled(Box)({
    display: "flex",
    width: "100%",
    flexDirection: "column",
    alignItems: "center"
});

const BoxBottom = styled(Box)({
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap"
});
const Statistics = ({id, accessToken, userVariables}) => {

    const { data, loading } = useSelector(state=>state.activity.list);
    const [activity, setActivity] = useState(0);
    const dispatch = useDispatch();

    useEffect(()=>{
        if(accessToken && id){
            getActivitiesSummary(dispatch, accessToken, id);
        }
    }, [id, accessToken, dispatch]);

    useEffect(()=>{
        if(accessToken && activity !== 0){
            getActivity(accessToken, dispatch, activity);
        }
    },[accessToken, activity, dispatch]);

    const handleFilter = (e) => {
        const value = e.target.value;
        if(value !== 0){
            setActivity(value);
        }
    }

    return <BoxGeneralStyled>
        <PaperStyled>
            <BoxStyled
                component="h2"
            >
                {
                    userVariables.is_admin ? "Statistics" : "Estatísticas"
                }
            </BoxStyled>
            <BoxSelection>
                <FormControl fullWidth={true}>
                    <Select
                        value={activity}
                        onChange={handleFilter}
                        inputProps={{ 'aria-label': 'Without label' }}
                    >
                        <MenuItem disabled value={0}>SELECIONE UMA ATIVIDADE</MenuItem>
                        {
                            !loading
                            && data.map((item, index) => {
                                return <MenuItem 
                                    key={index} 
                                    value={item.id}
                                >
                                    {item.title}
                                </MenuItem>
                            })
                        }
                    </Select>
                </FormControl>
            </BoxSelection>
            <BoxTop>
                {
                    activity !== 0
                    && <GeneralStatistics 
                        quiz_id={activity}
                        accessToken={accessToken}
                        dispatch={dispatch}
                    />
                }
            </BoxTop>
            <BoxBottom>
                {
                    activity !== 0
                    && <QuizStatistics 
                        id={activity} 
                        group_id={id}
                        accessToken={accessToken}
                        dispatch={dispatch}
                    />
                }
            </BoxBottom>
        </PaperStyled>
    </BoxGeneralStyled>
}

export default Statistics
