import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    notification: {
        data: {},
        loading: false,
        success: false,
        error: false,
        dialog: false
    },
    list: {
        data: [],
        arrivalNotification: false,
        arrivalCount: 0,
        loading: false,
        success: false,
        error: false,
    },
}

const notificationSlice = createSlice({
    name: "notifications",
    initialState,
    reducers: {
        //user
        loadNotificationRequest: (state) => {
            state.notification = {
                ...initialState.notification,
                loading: true
            }
        },
        loadNotificationSuccess: (state, action) => {
            state.notification = {
                ...initialState.notification,
                success: true,
                data: action.payload
            }
            
        },
        loadNotificationFailure: (state, action) => {
            state.notification = {
                ...initialState.notification,
                error: true
            }
        },

        //notifications
        loadNotificationsRequest: (state) => {
            state.list = {
                ...initialState.list,
                loading: true
            }
        },
        loadNotificationsSuccess: (state, action) => {
            state.list = {
                ...initialState.list,
                success: true,
                data: action.payload
            }
        },
        loadNotificationsFailure: (state) => {
            state.list = {
                ...initialState.list,
                error: true
            }
        },
        alterNotifications: (state, action) => {
            state.list = {
                ...state.list,
                data: action.payload
            }
        },
        setNotificationDialog: (state, action) => {
            state.notification = {
                ...initialState.notification,
                dialog: action.payload.dialog,
                data: action.payload.notification
            }
        },
        resetNotificationsList: (state) => {
            state.list = {
                ...initialState.list
            }
        },

        //accept invite
        loadAcceptInviteRequest: (state) => {
            state.notification = {
                ...state.notification,
                loading: true
            }
        },
        loadAcceptInviteSuccess: (state) => {
            state.notification = {
                ...initialState.notification,
                success: true
            }
        },
        loadAcceptInviteFailure: (state) => {
            state.notification = {
                ...state.notification,
                error: true,
                loading: false
            }
        },

        //reject invite
        loadRejectInviteRequest: (state) => {
            state.notification = {
                ...state.notification,
                loading: true
            }
        },
        loadRejectInviteSuccess: (state) => {
            state.notification = {
                ...initialState.notification,
                success: true
            }
        },
        loadRejectInviteFailure: (state) => {
            state.notification = {
                ...state.notification,
                loading: false,
                error: true
            }
        },

        setArrivalNotifications: (state, action) => {
            state.list = {
                ...state.list,
                arrivalNotification: action.payload.arrivalNotification,
                arrivalCount: (state.list.arrivalCount + 1)
            }
        },

        
        //reset
        reset: (state) => {
            state = initialState;
        }
    }
})

export const { 
    //notification
    loadNotificationRequest, 
    loadNotificationSuccess, 
    loadNotificationFailure,

    //notifications
    loadNotificationsRequest,
    loadNotificationsSuccess,
    loadNotificationsFailure,

    alterNotifications,
    setNotificationDialog,
    resetNotificationsList,

    //accept invite
    loadAcceptInviteRequest,
    loadAcceptInviteSuccess,
    loadAcceptInviteFailure,

    //reject invite
    loadRejectInviteRequest,
    loadRejectInviteSuccess,
    loadRejectInviteFailure,

    setArrivalNotifications,

    //reset
    reset
} = notificationSlice.actions;
export default notificationSlice.reducer;