import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    contact: {
        loading: false,
        success: false,
        error: false
    }
}

const contactSlice = createSlice({
    name: "contact",
    initialState,
    reducers: {
        loadSendContactRequest: (state) => {
            state.contact = {
                ...initialState.contact,
                loading: true
            }
        },
        loadSendContactSuccess: (state, action) => {
            state.contact = {
                ...initialState.contact,
                success: true
            }
        },
        loadSendContactFailure: (state, action) => {
            state.contact = {
                ...initialState.contact,
                error: true
            }
        }
    }
})

export const {
    loadSendContactRequest,
    loadSendContactSuccess,
    loadSendContactFailure
} = contactSlice.actions;

export default contactSlice.reducer;