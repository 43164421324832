import { useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import { CircularProgress } from "@material-ui/core";

import Footer from "../components/Footer";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { changeUserCall, getUserAttributes, setUserCreate } from "../apiCalls/userCalls";
import { 
    Box, 
    Button, 
    FormControl, 
    InputLabel, 
    NativeSelect, 
    Paper, 
    styled, 
    TextField 
} from "@mui/material";

const BoxStyled = styled(Box)(({ theme }) => ({
    display: "flex",
    width: "100%",
    height: "calc(100vh - 64px)",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    overflowY: "auto",
    overflowX: "hidden",

    [theme.breakpoints.down("sm")]: {
        height: "calc(100vh - 56px)"
    }
}));

const PaperStyled = styled(Paper)(({ theme }) => ({
    display: "flex",
    width: "95%",
    flexDirection: "column",
    margin: "10px 0px",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap",

    [theme.breakpoints.down("sm")]: {
        width: "90%"
    }
}));

const Form = styled("form")(({ theme }) => ({
    display: "flex",
    width: "80%",
    margin: "10px 0px",
    justifyContent: "center",
    flexWrap: "wrap",

    [theme.breakpoints.down("sm")]: {
        width: "90%"
    }
}));

const TextFieldStyled = styled(TextField)(({ theme }) => ({
    flex: "1",
    minWidth: "40%",
    margin: "10px",

    [theme.breakpoints.down("sm")]: {
        minWidth: "50%"
    }
}));

const BoxSelectStyled = styled(Box)(({ theme }) => ({
    flex: "1",
    minWidth: "40%",
    margin: "10px",

    [theme.breakpoints.down("sm")]: {
        minWidth: "50%"
    }
}));

const Actions = styled(Box)({
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "center"
})

const ChangenewUser = () => {

    const { id } = useParams();
    const { data, other, loading } = useSelector(state=>state.user.user);
    const { finish } = useSelector(state=>state.user.finish);

    
    const { accessToken } = useSelector(state=>state.user);
    const [newUser, setNewUser] = useState({});
    const [password, setPassword] = useState("");
    const [pass, setPass] = useState("");
    const navigate = useNavigate();
    const [userVariables, setUserVariables] = useState({});


    const dispatch = useDispatch();

    useEffect(() => {
        if(accessToken.data && id){
            getUserAttributes(dispatch, accessToken.data, id);
            setUserCreate(dispatch, false, false);
            const getDados = () => {
                const decodedToken = jwt_decode(accessToken.data);
                setUserVariables(decodedToken);
              }
              getDados();
        }
    }, [accessToken.data, id, dispatch]);

    useEffect(() => {
        if(data.id){
            setNewUser(data);
        }
    }, [data]);

    useEffect(() => {
        if(finish) {
            navigate("/finishSession")
        }
    }, [finish, navigate]);

    const handleChange = (e) => {
        setNewUser({
            ...newUser, [e.target.name]: e.target.value
        });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try{
            let newUserData = {
                password: "",
                is_admin: other.is_admin,
                is_teacher: other.is_teacher,
                form_clicked: other.form_clicked,
                is_google_login: other.is_google_login
            };
            changeUserCall(dispatch, pass, newUserData, newUser, accessToken.data, userVariables.is_admin);
        } catch(err){}
    }
    
    return (
        <Box sx={{width: "100%", height: "100%"}}>
            <BoxStyled>
                <PaperStyled>
                    <Box component="h3">Editar dados</Box>
                    <Form onSubmit={handleSubmit}>
                        <TextFieldStyled
                            id="outlined-name-input"
                            label="Nome"
                            name="username"
                            value={newUser?.username}
                            required
                            onChange={handleChange}
                            placeholder="nome"
                        />
                        {
                            !other?.is_admin
                            &&<TextFieldStyled
                                id="outlined-birthday-input"
                                label="Nascimento"
                                name="birthday"
                                type="date"
                                value={
                                    newUser?.birthday?.substring(0, 10) || new Date()
                                }
                                required
                                onChange={handleChange}
                                placeholder="Aniversário"
                            />
                        }
                        {
                            !other?.is_admin
                            && <BoxSelectStyled>
                                <FormControl fullWidth>
                                    <InputLabel variant="standard" htmlFor="uncontrolled-gender">
                                        Gênero
                                    </InputLabel>
                                    <NativeSelect
                                        defaultValue={newUser?.gender}
                                        inputProps={{
                                            name: "gender",
                                            id: "uncontrolled-gender",
                                        }}
                                    >
                                        <option value="Masculino">Masculino</option>
                                        <option value="Feminino">Feminino</option>
                                    </NativeSelect>
                                </FormControl>
                            </BoxSelectStyled>
                        }
                        {
                            !other?.is_admin
                            && <BoxSelectStyled>
                                <FormControl fullWidth>
                                    <InputLabel variant="standard" htmlFor="uncontrolled-periode">
                                        Período
                                    </InputLabel>
                                    <NativeSelect
                                        defaultValue={newUser?.periode}
                                        inputProps={{
                                            name: "periode",
                                            id: "uncontrolled-periode",
                                        }}
                                    >
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="7">7</option>
                                        <option value="8">8</option>
                                        <option value="Outro">Outro</option>
                                    </NativeSelect>
                                </FormControl>
                            </BoxSelectStyled>
                        }
                        {
                            !other?.is_admin
                            && <TextFieldStyled
                                id="outlined-matricula-input"
                                label="Matricula"
                                name="register"
                                value={newUser?.register}
                                required
                                onChange={handleChange}
                                placeholder="Matrícula"
                            />
                        }
                        {
                            !other.is_google_login
                            && <TextFieldStyled
                                id="outlined-password-input"
                                label="Alterar Senha"
                                name="password"
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder="Alterar senha atual"
                            />
                        }
                        {
                            !other?.is_admin
                            && <TextFieldStyled
                                id="outlined-city-input"
                                label="Cidade"
                                name="city"
                                value={newUser?.city}
                                required
                                onChange={handleChange}
                                placeholder="Cidade"
                            />
                        }
                        {
                            !other?.is_admin
                            && <TextFieldStyled
                                id="outlined-state-input"
                                label="Estado"
                                name="state"
                                value={newUser?.state}
                                required
                                onChange={handleChange}
                                placeholder="Estado"
                            />
                        }
                        {
                            !other?.is_admin
                            && <TextFieldStyled
                                id="outlined-institute-input"
                                label="Instituição"
                                name="institute"
                                value={newUser?.institute}
                                required
                                onChange={handleChange}
                                placeholder="Instituição"
                            />
                        }
                        {
                            (!other.is_google_login && !userVariables.is_admin)
                            && <TextFieldStyled
                                id="outlined-passwordConfirm-input"
                                label="Senha atual"
                                name="passwordConfirm"
                                type="password"
                                required={!other.is_google_login}
                                value={pass}
                                onChange={(e) => setPass(e.target.value)}
                                placeholder="Senha de confirmação"
                            />
                        }
                        <Actions>
                            <Button type="submit" variant="contained">
                                {
                                    loading
                                    ? <CircularProgress color="primary" size="20px" />
                                    : "Alterar"
                                }
                            </Button>
                        </Actions>
                    </Form>
                </PaperStyled>
                <Footer />
            </BoxStyled>
        </Box>
    )
}

export default ChangenewUser
