import { useState, useEffect } from "react";
import Skeleton from "../Skeleton";
import { useDispatch, useSelector } from "react-redux";
import { getUserResults } from "../../apiCalls/userCalls";
import { 
    Box, 
    Paper,
    styled, 
    Table, 
    TableBody, 
    TableCell, 
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";

const Container = styled(Box)({
    display: "flex",
    width: "100%",
    justifyContent: "center"
});

const Wrapper = styled(Paper)(({ theme }) => ({
    display: "flex",
    width: "80%",
    flexDirection: "column",
    justifyContent: "center",
    marginTop: "10px",

    [theme.breakpoints.down("sm")]: {
        width: "100%"
    }
}));

const AlunoResults = ({userVariables, accessToken, id}) => {

    const { data, loading } = useSelector(state=>state.user.result);
    const [result, setResult] = useState([]);
    const dispatch = useDispatch();


    useEffect(() => {
        if(accessToken && userVariables.id && id != null){
            getUserResults(dispatch, accessToken, id, userVariables.id);
        }
    }, [accessToken, userVariables.id, dispatch, id]);
    
    useEffect(()=>{
        if(data.length > 0){
            let idx = {
                hit: 0,
                question_count: 0,
                title: "GERAL"
            }
            let aux = []
            for(let i=0;i<data.length;i++){
                idx.hit += parseInt(data[i].quiz.hit || 0);
                idx.question_count += data[i].quiz.question_count; 
                aux = aux.concat(data[i].quiz);
            }
            setResult(aux.concat(idx));
        }
    }, [data]);

    return (
        <Container>
            <Wrapper>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">ATIVIDADE</TableCell>
                                <TableCell align="center">ACERTO(%)</TableCell>
                                <TableCell align="center">SCORE</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {
                                loading
                                ? <TableRow>
                                    <TableCell><Skeleton type="text" width="120" /></TableCell>
                                    <TableCell><Skeleton type="text" width="120" /></TableCell>
                                    <TableCell><Skeleton type="text" width="120" /></TableCell>
                                </TableRow>
                                : result.map((r, index) => {
                                    return <TableRow key={index} idx={index}>
                                        <TableCell align="center">{
                                            r.title
                                        }</TableCell>
                                        <TableCell align="center">{
                                            `${parseInt(((r.hit || 0)/(r.question_count || 1)) * 100)} %`
                                        }</TableCell>
                                        <TableCell align="center">{
                                            `${r.hit || 0}/${r.question_count}`
                                        }</TableCell>
                                    </TableRow> 
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Wrapper>
        </Container>
    )
}

export default AlunoResults
