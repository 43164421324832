import Footer from "../components/Footer";

import {
    Avatar,
    Box,
    Button,
    CircularProgress,
    Paper,
    styled,
    TextField
} from "@mui/material";
import { recoverAccount } from "../apiCalls/userCalls";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import GeneralSnackbar from "../components/snackbar/GeneralSnackbar";
import { useEffect } from "react";

const BoxStyled = styled(Box)(({ theme }) => ({
    display: "flex",
    width: "100%",
    height: "calc(100vh - 64px)",
    flexDirection: "column",
    justifyContent: "space-between",
    overflowY: "auto",
    overflowX: "hidden",

    [theme.breakpoints.down("sm")]: {
        height: "calc(100vh - 56px)"
    }
}));

const BoxHeader = styled(Box)({
    display: "flex",
    width: "100%",
    justifyContent: "flex-start",
    alignItems: "flex-end",
});

const AvatarStyled = styled(Avatar)(({ theme }) => ({
    display: "flex",
    position: "relative",
    width: "150px",
    height: "80px",
    objectFit: "cover",
    marginRight: "5px",

    [theme.breakpoints.down("sm")]: {
        width: "130px",
        objectFit: "cover"
    }
}));

const BoxTitle = styled(Box)({
    display: "flex",
    backgroundColor: "#c0c1c2",
    flex: 1,
    height: "40px",
    alignItems: "center"
});

const Title = styled("h3")({
    margin: "0px 10px"
});

const BoxContent = styled(Paper)(({ theme }) => ({
    display: "flex",
    width: "50%",
    flexDirection: "column",
    marginTop: "10px",
    padding: "20px 0px",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "10px",
    textAlign: "center",

    [theme.breakpoints.down("sm")]: {
        width: "95%"
    }
}));

const Header = styled(Box)({
    marginBottom: "10px"
});

const ContentTitle = styled("ContentText")({
    fontSize: "16px",
    fontWeight: "bold"
});

const Content = styled("form")({})

const RecoverAccount = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { loading, error, success, errorMessage } = useSelector(state=>state.user.recover);

    const [email, setEmail] = useState("");
    const [open, setOpen] = useState(false);
    const [type, setType] = useState("");

    useEffect(() => {
        if(error) {
            setType("error");
            setOpen(true);
        }
        if(success){
            setType("success");
            setOpen(true);
        }
    }, [error, success]);

    const handleBack = (e) => {
        e.preventDefault();
        navigate("/");
    }

    const handleSend = async (e) => {
        e.preventDefault();
        let data = {
            email: email,
            token: Math.floor((Math.random() * (100000000000000 - 1000000000000) + 1000000000000)).toString(),
            pass: Math.floor((Math.random() * (100000000000000 - 1000000000000) + 1000000000000)).toString()
        }
        recoverAccount(dispatch, data);
    }

    return (
        <Box sx={{ width: "100%", height: "100%" }}>
            <BoxStyled>
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center" 
                    mb={.5}
                >
                    <BoxHeader>
                        <Box>
                            <AvatarStyled variant="square" src="/assets/1638717038835Logotipo.webp" alt="" />
                        </Box>
                        <BoxTitle>
                            <Title>RECUPERAR CONTA</Title>
                        </BoxTitle>
                    </BoxHeader>
                    <BoxContent>
                        <Box>
                            <Header>
                                <ContentTitle>INFORMAÇÕES DE RECUPERAÇÃO</ContentTitle>
                            </Header>
                            <Content onSubmit={handleSend}>
                                <TextField 
                                    label="Email" 
                                    value={email} 
                                    required={true}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                <Box
                                    pt={2}
                                >
                                    <Button
                                        onClick={handleBack}
                                        disabled={loading} 
                                        style={{
                                            backgroundColor: "#38B6FF",
                                            margin: "0px 5px",
                                            color: "white"
                                        }} 
                                    >
                                        {
                                            loading
                                            ? <CircularProgress color='primary' size="20px" />
                                            : "VOLTAR"
                                        }
                                    </Button>
                                    <Button
                                        type="submit"
                                        disabled={loading} 
                                        style={{
                                            backgroundColor: "#38B6FF",
                                            margin: "0px 5px",
                                            color: "white"
                                        }} 
                                    >
                                        {
                                            loading
                                            ? <CircularProgress color='primary' size="20px" />
                                            : "ENVIAR"
                                        }
                                    </Button>
                                </Box>
                            </Content>
                        </Box>
                    </BoxContent>
                </Box>
                <Footer />
                <GeneralSnackbar 
                    open={open} 
                    setOpen={setOpen} 
                    errorMessage={errorMessage} 
                    errorType={type}
                />
            </BoxStyled>
        </Box>
    )
}

export default RecoverAccount