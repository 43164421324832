import {
    Box,
    styled
} from "@mui/material";

import { useNavigate } from "react-router-dom";

const BoxStyled = styled(Box)(({ theme }) => ({
    display: "flex",
    width: "100vw",
    height: "52px",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    backgroundColor: "#38B6FF",

    [theme.breakpoints.down("sm")]: {
        height: "40px"
    }
}));

const BoxTopStyled = styled(Box)({
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
});

const Text = styled("span")(({ theme }) => ({
    color: "white",

    [theme.breakpoints.down("sm")]: {
        fontSize: "12px"
    }
}));

const BoxLinkStyled = styled(Box)(({ theme }) => ({
    textDecoration: "none",
    color: "white",
    transition: "0.3s ease all",
    cursor: "pointer",
    
    "&:hover": {
        color: "lightblue"
    },
    
    [theme.breakpoints.down("sm")]: {
        fontSize: "12px"
    }
}));

export default function Footer(){

    const navigate = useNavigate();

    const links = [
        {
            text: "SOBRE ",
            link: "/sobre"
        },
        {
            text: "CREDITOS ",
            link: "/creditos"
        },
        {
            text: "CONTATOS",
            link: "/contato"
        }
    ];

    const handleClick = (link) => {
        navigate(`${link}`)
    }

    return (
        <footer>
            <BoxStyled>
                <BoxTopStyled>
                    <Text>SCREENING PROGRAMMING | 2021 - 2022</Text>
                </BoxTopStyled>
                <Box 
                    display="flex"
                    gap={.5}
                >
                    {
                        links.map((item, index) => {
                            return <Box 
                                display="flex"
                                alignItems="center"
                                gap={.8}    
                                key={index}
                            >
                                <BoxLinkStyled onClick={() => handleClick(item.link)}>
                                    {item.text}
                                </BoxLinkStyled>
                                {
                                    index < 2
                                    && <div style={{
                                        height: "10px",
                                        borderLeft: "2px solid white"
                                    }} />
                                }
                            </Box>
                        })
                    }
                </Box>
            </BoxStyled>
        </footer>
    )
}