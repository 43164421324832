import moment from "moment";
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserAttributes } from '../../apiCalls/userCalls';
import { Box, Button, Paper, styled } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const BoxGeneralStyled = styled(Box)({
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    margin: "10px 0px"
});

const BoxWrapperStyled = styled(Paper)(({ theme }) => ({
    display: "flex",
    width: "95%",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    
    [theme.breakpoints.down("sm")]: {
        width: "90%"
    }
}));

const BoxStyled = styled(Box)(({ theme }) => ({
    margin: "0px",
    [theme.breakpoints.down("sm")]: {
        fontSize: "16px"
    }
}));

const BoxUserInfo = styled(Box)({
    display: "flex",
    width: "100%",
    flexWrap: "wrap"
});

const BoxImageStyled = styled(Box)({
    display: "flex",
    flex: 1,
    justifyContent: "center"
});

const Image = styled("img")({
    width: "120px",
    height: "120px",
    border: "2px solid lightgray",
    objectFit: "cover",
    borderRadius: "50%"
});

const BoxInfoStyled = styled(Box)({
    display: "flex",
    minWidth: "300px",
    flex: 3,
    flexDirection: "column",
    padding: "0px 10px",
});

const Span = styled("span")(({ theme }) => ({
    padding: "10px",

    [theme.breakpoints.down("sm")]: {
        fontSize: "12px"
    }
}));

const Hr = styled("hr")({
    width: "95%",
    border: "1px solid lightgray"
});

const BoxOtherUserInfo = styled(Box)({
    display: "flex",
    width: "100%",
    flexDirection: "column",
    padding: "0px 10px",
});

const Actions = styled(Box)({
    display: "flex",
    width: "100%",
    marginBottom: "10px",
    alignItems: "center",
    justifyContent: "center"
});

const MyDatas = ({userVariables, accessToken}) => {

    const { data } = useSelector(state=>state.user.user);
    const PF = 'https://screening-programming-backend.herokuapp.com/images/';
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(()=>{
        if(userVariables.id && accessToken){
            getUserAttributes(dispatch, accessToken, userVariables.id);
        }
    }, [userVariables.id, accessToken, dispatch]);

    const handleClick = () => {
        navigate(`/changeData/${userVariables.id}`);
    }
    
    return (
        <BoxGeneralStyled>
            <BoxWrapperStyled>
                <BoxStyled component="h2">MEUS DADOS</BoxStyled>
                <BoxUserInfo>
                    <BoxImageStyled>
                        <Image
                            src={
                                PF + "noAvatar.webp"
                            }
                        />
                    </BoxImageStyled>
                    <BoxInfoStyled>
                        <Span>NOME: <b>{data?.username}</b></Span>
                        <Span>
                            MATRICULA: <b>
                                {
                                    data?.register ? 
                                    data.register : 
                                    "NÃO INFORMADO"
                                }
                            </b>
                        </Span>
                        <Span>
                            GÊNERO: <b>
                                {
                                    data?.gender ? 
                                    data?.gender : 
                                    "NÃO INFORMADO"
                                }
                            </b>
                        </Span>
                        <Span>EMAIL: <b>{data?.email}</b></Span>
                        <Span>
                            NASCIMENTO: <b>
                                {
                                    data.birthday ? 
                                    moment(data.birthday.substring(0, 10)).format("DD/MM/YYYY") :
                                    "NÃO INFORMADO"
                                }
                            </b>
                        </Span>
                        <Span>
                            PERÍODO: <b>
                                {
                                    data?.periode ?
                                    data?.periode : 
                                    "NÃO INFORMADO"
                                }
                            </b>
                        </Span>
                    </BoxInfoStyled>
                </BoxUserInfo>
                <Hr />
                <BoxOtherUserInfo>
                    <Span>
                        CIDADE: <b>
                            
                            {
                                data?.city ? 
                                data?.city : 
                                "NÃO INFORMADO"
                            }
                        </b>
                    </Span>
                    <Span>
                        ESTADO: <b>
                            {
                                data?.state ?
                                data.state : 
                                "NÃO INFORMADO"
                            }
                        </b>
                    </Span>
                    <Span>
                        INSTITUTO: <b>
                            {
                                data?.institute ?
                                data?.institute : 
                                "NÃO INFORMADO"
                            }
                        </b>
                    </Span>
                </BoxOtherUserInfo>
                <Actions>
                    <Button 
                        variant="contained"
                        onClick={handleClick}
                    >
                        ALTERAR DADOS
                    </Button>
                </Actions>
            </BoxWrapperStyled>
        </BoxGeneralStyled>
    )
}

export default MyDatas
