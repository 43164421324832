import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";

import PrivateRoute from "./routes/PrivateRoutes";
import ProtectedRoute from "./routes/ProtectedRoutes";

import { useSelector } from "react-redux";

//import styled from "styled-components";
//components
import TokenVerify from "./layout/TokenVerify";

//pages
import Home from "./layout/Home";
import Register from "./layout/Register";
import ChangeData from "./layout/ChangeData";
import ChangePass from "./layout/ChangePass";
import Contact from "./layout/Contact";
import Credits from "./layout/Credits";
import Login from "./layout/Login";
import RecoverAccount from "./layout/RecoverAccount";
import About from "./layout/About";
import TurmaDashboard from "./layout/TurmaDashboard";
import Navbar from "./components/Navbar";
import Activity from "./layout/Activity";
import { 
    Box, 
    styled 
} from "@mui/material";
import ActivityManage from "./layout/activity.manage";
import GeneralSnackbar from "./components/snackbar/GeneralSnackbar";

const BoxStyled = styled(Box)(({ theme }) => ({
    display: "flex",
    width: "100vw",
    height: "100vh",
    flexDirection: "column"
}));

/*
<img 
    style={{width: "100%", objectFit: "contain"}}
    src=`https://drive.google.com/uc?export=view&id=${question?.question_text}`
    alt="drive image"
    loading="lazy"
/>

resolução de problemas = 9
1-1myAbxqnPECU-dSdKXZLu3mIZEDtYXzjq
2-1FuW9SL_6sJm1TlQDnUCWWSj4i7g803t3
3-1Tb4VImiilD3PLhTesw_I_2RsCvrjVMjV
4-1Cx93YZZsg9NFocqS3mo9LVLbI4PDcToU
5-1hCmOjdTfig8RAC4n2aTMOjkjB4EmG7V_
6-1lQbFykynI6euKsUkc1kx1MGUh2cSIVRf
7-1lQbFykynI6euKsUkc1kx1MGUh2cSIVRf
8-1UVrE4zAWGav4rSDC5PulHwRh8n2CW6uG
9-1uO7BKmSoHBRe_dsddh7EN8dD063E0zJf
10-17cXEEbo9JQNKJOkeUNUXlMQw6USWSAUm

pensamento abstrato = 10
1-1RvBKxpTrGbjj348nwL5p5zdmPyAYojUq
2-1QjyzrSHM33L1GQGnyP-8WGGbB8rCSoFI
3-1ijWW11Qu5FAsnEPZg93_Hw-Jfr1nOPGz
4-1UCYgJj8THWIIatuTRXsHHgJOKoXotbcF
5-1Yw668WRKcryiylIu70UMrehwbjdPO4sU
6-1QmZM-DIzmijuoMHPc8XcCncS64pi4rWr
7-1d9rIIsNBhzeQGRMWw4rvAOCPlxnuqyZR
8-1s_Oeodo2vxoi3ChLMdTm3tKQw-p8HoNp
9-16G63n2J4zf2lJ5GdbiefWj44c272ZiB4
10-14-JlFu6Ga9PcocEiLCa42BaO5x0wzDOU

raciocínio matemático = 11
1-1baGnMCbrueUQKXzllq-yoPDwN7wsA-eZ
2-1xYv721ucEEmz1Xe6Brfxu_QXtiPz4uRU
3-16mCk8000su1NMRMe_gG_Hbrg0A98eD7F
4-13Fhp4UVkO7uCoJmAXkEcLXHNWDcZ7PiI
5-146G_GAXvh-_dhjpRFcK5rxKqj958ptO4
6-1B2eCGlJF2IF2pwg0ipBb5DqqdwbhQdco
7-1hTU5-3bXGJsUnmb7zwHArRSeKm1rXW5p
8-1ItNTDKAVC7FZ1o9CVMb5k7RbqGZoMuXv
9-1QPHSL5-F_B0PfvHYTAz18UIJ3OD-fO2v
10-16Vg_F4gqpoBw7RVlycMcZM2sw4PIAq1F

flexibilidade cognitiva = 12
1-1VX8tYt-KCN4cx3UN2fwe8_n0xboAul5F
2-1Ff7aDG9mZykruEbqAgrGFn5Jo2J0OR4M
3-1_IM0cPRvAyZ0pXa-B_dr89ZBtW_vwUi7
4-1W-rpdP9ZVb7BQDwjuw349UAbF9FmNob3
5-1NzRAOmXnxwy8YHEsYHrOphgdZmBu4eEv
6-1z2w7wyvUn1ohdrF6R4WRXNnKkcMQzhg0
7-1OsEyDI1UvX-rtmgUsZwM4f2xUHnvNaUq
8-1hzWZvDDnMDG_Ao7jk62j7hUQkks_VIZx
9-1rOmf2puhUkRk7qp09rouzwkH4QWFoxv4
10-1Tjru8PPISlXE8ZrklrX79PHHCwDuqlMa
*/

const App = () => {

    const { data } = useSelector(state=>state.user.accessToken);

    return <BoxStyled>
        <GeneralSnackbar />
        <Router>
            <Navbar />
            <Routes>
                {/*protected, routes that the user need to be logged out*/}
                <Route 
                    path="/"
                    element={
                        <ProtectedRoute redirectTo={"/home"} accessToken={data}>
                            <Login />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/register"
                    element={
                        <ProtectedRoute redirectTo={"/home"} accessToken={data}>
                            <Register />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/recoverAccount"
                    element={
                        <ProtectedRoute redirectTo={"/home"} accessToken={data}>
                            <RecoverAccount />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/changePass/:token/:id"
                    element={
                        <ProtectedRoute redirectTo={"/home"} accessToken={data}>
                            <ChangePass />
                        </ProtectedRoute>
                    }
                />
                {/*private, routes that the user needs to be logged in*/}
                <Route
                    path="/home"
                    element={
                        <PrivateRoute redirectTo={"/"} accessToken={data}>
                            <Home />
                        </PrivateRoute>
                    }
                />
                
                <Route
                    path="/changeData/:id"
                    element={
                        <PrivateRoute redirectTo={"/"} accessToken={data}>
                            <ChangeData />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/group_dashboard/:id"
                    element={
                        <PrivateRoute redirectTo={"/"} accessToken={data}>
                            <TurmaDashboard />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/manage_activity/:id"
                    element={
                        <PrivateRoute redirectTo={"/"} accessToken={data}>
                            <ActivityManage />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/activity/:id"
                    element={
                        <PrivateRoute redirectTo={"/"} accessToken={data}>
                            <Activity />
                        </PrivateRoute>
                    }
                />
                
                <Route
                    path="/finishSession"
                    element={
                        <PrivateRoute redirectTo={"/"} accessToken={data}>
                            <TokenVerify />
                        </PrivateRoute>
                    }
                />
                
                <Route
                    path="/contato"
                    element={
                        <Contact />
                    }
                />
                <Route
                    path="/creditos"
                    element={
                        <Credits />
                    }
                />
                <Route
                    path="/sobre"
                    element={
                        <About />
                    }
                />
                <Route 
                    path="*" 
                    element={
                        <Navigate to={"/"} />
                    }
                />
            </Routes>
        </Router>
    </BoxStyled>
}

export default App
