import { 
    Button,
    Dialog, 
    DialogActions, 
    DialogContent, 
    DialogContentText, 
    DialogTitle
} from '@mui/material';
import React from 'react'
import { useSelector } from 'react-redux'
import { deleteActivity, setActivityDelete } from '../../../apiCalls/activityCalls';

const DeleteActivityDialog = ({ dispatch, accessToken}) => {
    const { data, dialog } = useSelector(state=>state.activity.delete);

    const handleDialog = () => {
        setActivityDelete(dispatch, false, {});
    }

    const handleDelete = () => {
        deleteActivity(dispatch, accessToken, data.id);
    }

    return <Dialog 
        id="dialog_delete" 
        open={dialog}
        onClose={handleDialog}
    >
        <DialogTitle id="dialog_activity_delete_title" align="center">
            DELETE ACTIVITY
        </DialogTitle>
        <DialogContent>
            <DialogContentText align="center">
                {"Are you sure you want to delete "} 
                <b>PERMANENTLY</b>
                {" the activity: "}
                <b>
                    {
                        data?.title
                    }
                </b>
                ?
            </DialogContentText>
        </DialogContent>
        <DialogActions
            sx={{
                display: "flex",
                justifyContent: "space-between"
            }}
        >
            <Button
                variant="contained"
                onClick={handleDialog}
            >
                BACK
            </Button>
            <Button
                variant="contained"
                onClick={handleDelete}
            >
                DELETE
            </Button>
        </DialogActions>
    </Dialog>
}

export default DeleteActivityDialog