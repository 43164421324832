import Footer from "../components/Footer";

import {
    Avatar,
    Box,
    Paper,
    styled
} from "@mui/material";

const BoxStyled = styled(Box)(({ theme }) => ({
    display: "flex",
    width: "100%",
    height: "calc(100vh - 64px)",
    flexDirection: "column",
    justifyContent: "space-between",
    overflowY: "auto",
    overflowX: "hidden",

    [theme.breakpoints.down("sm")]: {
        height: "calc(100vh - 56px)"
    }
}));

const BoxHeader = styled(Box)({
    display: "flex",
    width: "100%",
    justifyContent: "flex-start",
    alignItems: "flex-end",
});

const AvatarStyled = styled("img")(({ theme }) => ({
    display: "flex",
    position: "relative",
    width: "150px",
    height: "80px",
    objectFit: "contain",
    marginRight: "5px",

    [theme.breakpoints.down("sm")]: {
        width: "130px",
    }
}));

const BoxTitle = styled(Box)({
    display: "flex",
    backgroundColor: "#c0c1c2",
    flex: 1,
    height: "40px",
    alignItems: "center"
});

const Title = styled("h3")({
    margin: "0px 10px"
});

const BoxContent = styled(Paper)(({ theme }) => ({
    display: "flex",
    width: "90%",
    flexDirection: "column",
    marginTop: "10px",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "10px",
    textAlign: "center",

    [theme.breakpoints.down("sm")]: {
        width: "95%"
    }
}));

const ContentTitle = styled("span")({
    margin: "5px 0px 5px 0px",
    fontSize: "16px",
    fontWeight: "bold"
});

const ContentText = styled("span")(({ theme }) => ({
    [theme.breakpoints.down("sm")]: {
        fontSize: "14px"
    }
}));

const ImageStyled = styled("img")(({ theme }) => ({
    width: "40%",
    marginBottom: "10px",
    objectFit: "contain",

    [theme.breakpoints.down("sm")]: {
        width: "80%"
    }
}));

export default function creditos() {
    return(
        <Box sx={{ width: "100%", height: "100%" }}>
            <BoxStyled>
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center" 
                    mb={.5}
                >
                    <BoxHeader>
                        <Box>
                            <AvatarStyled src="/assets/1638717038835Logotipo.webp" alt="" />
                        </Box>
                        <BoxTitle>
                            <Title>CRÉDITOS</Title>
                        </BoxTitle>
                    </BoxHeader>
                    <BoxContent>
                        <ContentTitle>DIREITOS INTELECTUAIS</ContentTitle>
                        <ContentText>Prof. Me. Jucelio Soares dos Santos (UEPB/UFCG)</ContentText> 
                        <ContentText>Prof. Dr(a). Kezia de Vasconcelos Oliveira Dantas (UEPB)</ContentText> 
                        <ContentText>Prof. Dr. Wilkerson de Lucena Andrade (UFCG)</ContentText> 
                        <ContentText>Prof. Dr. João Arthur Brunet Monteiro (UFCG)</ContentText> 
                        <ContentText>Prof. Dr(a) Monilly Ramos Araújo Melo (UFCG)</ContentText> 
                        
                        <ContentTitle>DIREITOS DE PRODUÇÂO E DESENVOLVIMENTO</ContentTitle>
                        <ContentText>Pedro Henrique de Andrade (UEPB)</ContentText> 
                        <ContentText>Danilo Medeiros Dantas (UEPB)</ContentText> 

                        <ImageStyled src="/assets/1638717114092Parceiros.webp" alt="" />
                    </BoxContent>
                </Box>
                <Footer />
            </BoxStyled>
        </Box>
    )
}



