import { useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import HomeAdmin from "./HomeAdmin";
import HomeAluno from "./HomeAluno";
import HomeProfessor from "./HomeProfessor";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import CreateUpdateGroupDialog from "../components/dialogs/group/create.update.group";
import DeleteGroupDialog from "../components/dialogs/group/delete.group";
import { useDispatch } from "react-redux";
import CreateUpdateUserDialog from "../components/dialogs/user/create.user";
import { useNavigate } from "react-router-dom";
import { resetUserList } from "../apiCalls/userCalls";
import { resetGroupList } from "../apiCalls/groupCalls";
import { resetActivityList } from "../apiCalls/activityCalls";
import CreateOrEditDialog from "../components/dialogs/activity/create.edit.dialog";
import DeleteActivityDialog from "../components/dialogs/activity/delete.activity.dialog";

const Home = () => {
    const { data } = useSelector(state=>state.user.accessToken);
    const [userVariables, setUserVariables] = useState({});
    const { finishSession } = useSelector(state=>state.user.finish);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(()=> {
        if(finishSession){
            navigate("/finishSession")
        }
    }, [finishSession, navigate]);

    useEffect(() => {
        if(data){
          const getDados = async () => {
            const decodedToken = jwt_decode(data);
            setUserVariables(decodedToken);
          }
          getDados();
        }
        resetUserList(dispatch);
        resetGroupList(dispatch);
        resetActivityList(dispatch);
    }, [data, dispatch]);

    return(
        <Box sx={{width: "100%", height: "100%"}}>
            <CreateUpdateUserDialog
                accessToken={data}
                dispatch={dispatch}
            />
            <CreateUpdateGroupDialog
                accessToken={data}
                dispatch={dispatch}
                userVariables={userVariables}
            />
            <DeleteGroupDialog
                accessToken={data}
                dispatch={dispatch}
                userVariables={userVariables}
            />
            <CreateOrEditDialog
                dispatch={dispatch}
                accessToken={data}
            />
            <DeleteActivityDialog
                dispatch={dispatch}
                accessToken={data}
            />
            {
                userVariables.is_admin
                ? <HomeAdmin userVariables={userVariables} accessToken={data} />
                : (
                    userVariables.is_teacher
                    ? <HomeProfessor userVariables={userVariables} accessToken={data} />
                    : <HomeAluno userVariables={userVariables} accessToken={data} />
                )
            }
        </Box>
    );

}

export default Home
