import { 
    loadActivityRequest,
    loadActivitySuccess,
    loadActivityFailure,
    changeActivity,

    loadActivitiesCountRequest,
    loadActivitiesCountSuccess,
    loadActivitiesCountFailure,

    loadActivitiesRequest,
    loadMoreActivitiesRequest,
    loadActivitiesSuccess,
    loadActivitiesFailure,

    changePaginator,
    loadActivityResultsRequest,
    loadActivityResultsFailure,
    loadActivityResultsSuccess,

    loadActivityAnswersRequest,
    loadActivityAnswersSuccess,
    loadActivityAnswersFailure,

    loadFinishedActivitiesRequest,
    loadFinishedActivitiesFailure,
    loadFinishedActivitiesSuccess,

    loadQuestionRequest,
    loadQuestionFailure,
    loadQuestionSuccess,

    loadSaveAnswerRequest,
    loadSaveAnswerSuccess,
    loadSaveAnswerFailure,

    loadAnswerRequest,
    loadAnswerFailure,
    loadAnswerSuccess,

    resetActivityLists,

    setOpenActivityDialog,
    loadChangeFinalDateRequest,
    loadChangeFinalDateSuccess,
    loadChangeFinalDateFailure,

    setCreateUpdateDialog,
    loadCreateActivityRequest,
    loadCreateActivitySuccess,
    loadCreateActivityFailure,

    setActivityToDelete,
    loadDeleteActivityRequest,
    loadDeleteActivitySuccess,
    loadDeleteActivityFailure,

    setCreateQuestionDialog,
    loadCreateQuestionRequest,
    loadCreateQuestionSuccess,
    loadCreateQuestionFailure,

    loadUpdateActivityRequest,
    loadUpdateActivitySuccess,
    loadUpdateActivityFailure,

    resetDeleteQuestions,
    insertQuestionToDelete,
    setDeleteQuestionsDialog,
    loadDeleteQuestionsRequest,
    loadDeleteQuestionsSuccess,
    loadDeleteQuestionsFailure
} from "../redux/activitySlice";

import { finishSession } from "../redux/userSlice";

import axiosInstance from "./axios";
import { openSnackbar } from "../redux/snackbarSlice";

export const resetActivityList = (dispatch) => {
    dispatch(resetActivityLists());
}

//id = id da atividade
export const getActivity = async (accessToken, dispatch, id) => {
    dispatch(loadActivityRequest());
    try{
        const res = await axiosInstance.get(`quiz/${id}`, {
            headers: {
                token: "Bearer " + accessToken
            }
        });
        if(res.data.Status){
            dispatch(loadActivityFailure());
            dispatch(openSnackbar({ severity: "error", message: res.data.Status }));
        } else {
            dispatch(loadActivitySuccess(res.data));
        }
    } catch(err){
        if(err === "Acesso finalizado") {
            dispatch(finishSession())
        } else {
            dispatch(loadActivityFailure());
            dispatch(openSnackbar({ severity: "error", message: "Erro interno" }));
        }
    }
}

export const getActivityWithQuestions = async (accessToken, dispatch, id) => {
    dispatch(loadActivityRequest());
    try{
        const res = await axiosInstance.get(`quizWithQuestions/${id}`, {
            headers: {
                token: "Bearer " + accessToken
            }
        });
        if(res.data.Status){
            dispatch(loadActivityFailure());
            dispatch(openSnackbar({ severity: "error", message: res.data.Status }));
        } else {
            dispatch(loadActivitySuccess(res.data));
        }
    } catch(err){
        if(err === "Acesso finalizado") {
            dispatch(finishSession())
        } else {
            dispatch(loadActivityFailure());
            dispatch(openSnackbar({ severity: "error", message: "Erro interno" }));
        }
    }
}

export const getGeneralStatistics = async (accessToken, dispatch, group_id, activity_id) => {
    dispatch(loadActivityResultsRequest());
    try{
        const res = await axiosInstance.get(`general_statistics/${group_id}/${activity_id}`, {
            headers: {
                token: "Bearer " + accessToken
            }
        });
        if(res.data?.length > 0){
            dispatch(loadActivityResultsSuccess(res.data[0]));
        } else {
            dispatch(loadActivityResultsFailure());
            dispatch(openSnackbar({ severity: "error", message: "Nenhuma estatística encontrada" }));
        }
    } catch(err){
        if(err === "Acesso finalizado") {
            dispatch(finishSession());
        } else {
            dispatch(loadActivityResultsFailure());
            dispatch(openSnackbar({ severity: "error", message: "Erro interno" }));
        }
    }
}

//id = id da turma
export const getActivities = async (accessToken, dispatch, id, last_id, activities) => {
    if (activities?.length > 0) {
        dispatch(loadMoreActivitiesRequest());
    } else {
        dispatch(loadActivitiesRequest());
    }
    try {
        const res = await axiosInstance.get(`quizAll/${id}?last_id=${last_id}`, {
            headers: {
                token : "Beader " + accessToken
            }
        });
        if(res.data.Status) {
            dispatch(loadActivitiesFailure());
            dispatch(openSnackbar({ severity: "error", message: res.data.Status }));
        } else {
            dispatch(loadActivitiesSuccess(activities.concat(res.data)));
        }
    } catch(err) {
        if(err === "Acesso finalizado") {
            dispatch(finishSession())
        } else {
            dispatch(loadActivitiesFailure("Erro interno"));
            dispatch(openSnackbar({ severity: "error", message: "Erro interno" }));
        }
    }
}

export const getActivitiesAlumn = async (accessToken, dispatch, id) => {
    dispatch(loadActivitiesRequest());
    try {
        const res = await axiosInstance.get(`quiz?group_id=${id}`, {
            headers: {
                token: "Bearer " + accessToken
            }
        });
        if(res.data.Status){
            dispatch(loadActivitiesFailure());
            dispatch(openSnackbar({ severity: "error", message: res.data.Status }));
        } else {
            dispatch(loadActivitiesSuccess(res.data.activities));
        }
    } catch(err) {
        if(err === "Acesso finalizado") {
            dispatch(finishSession())
        } else {
            dispatch(loadActivitiesFailure());
            dispatch(openSnackbar({ severity: "error", message: "Erro interno" }));
        }
    }
}

export const getAlumnAnswers = async (accessToken, dispatch, group_alumn_id) => {
    dispatch(loadActivityAnswersRequest());
    try {
        const res = await axiosInstance.get(`alumn_answers/${group_alumn_id}`, {
            headers: {
                token: "Bearer " + accessToken
            }
        });
        if(res.data.Status){
            dispatch(loadActivityAnswersFailure());
            dispatch(openSnackbar({ severity: "error", message: res.data.Status }));
        } else {
            dispatch(loadActivityAnswersSuccess(res.data.answers));
        }
    } catch(err) {
        if(err === "Acesso finalizado") {
            dispatch(finishSession());
        } else {
            dispatch(loadActivityAnswersFailure());
            dispatch(openSnackbar({ severity: "error", message: "Erro interno" }));
        }
    }
}

export const getLastAnswer = async (accessToken, dispatch, id, quiz_id) => {
    dispatch(loadAnswerRequest());
    try {
        const res = await axiosInstance.get(`/last_answer/${id}/${quiz_id}`, {
            headers: {
                token: "Bearer " + accessToken
            }
        });
        if(res.data.length > 0){
            dispatch(loadAnswerSuccess(res.data[0]));
        } else {
            dispatch(loadAnswerSuccess({}));
        }
    } catch(err) {
        if(err === "Acesso finalizado") {
            dispatch(finishSession());
        } else {
            dispatch(loadAnswerFailure());
            dispatch(openSnackbar({ severity: "error", message: "Erro interno" }));
        }
    }
}

//id=alumn_id
export const getFinishedActivities = async (accessToken, dispatch, id) => {
    dispatch(loadFinishedActivitiesRequest());
    try{
        const res = await axiosInstance.get(`finishedActivities/${id}`, {
            headers: {
                token: "Bearer " + accessToken
            }
        });
        if(res.data.Status){
            dispatch(loadFinishedActivitiesFailure());
            dispatch(openSnackbar({ severity: "error", message: res.data.Status }));
        } else {
            dispatch(loadFinishedActivitiesSuccess(res.data));
        }
    } catch(err) {
        if(err === "Acesso finalizado") {
            dispatch(finishSession())
        } else {
            dispatch(loadFinishedActivitiesFailure());
            dispatch(openSnackbar({ severity: "error", message: "Erro interno" }));
        }
    }
}

export const getActivitiesSummary = async (dispatch, accessToken, group_id) => {
    dispatch(loadActivitiesRequest());
    try {
        const res = await axiosInstance.get(`quiz_summary/${group_id}`, {
            headers: {
                'token' : "Beader " + accessToken
            }
        });
        if(res.data.Status){
            dispatch(loadActivitiesFailure());
            dispatch(openSnackbar({ severity: "error", message: res.data.Status }));
        } else {
            dispatch(loadActivitiesSuccess(res.data.activities));
        }
    } catch(err) {
        if(err === "Acesso finalizado") {
            dispatch(finishSession())
        } else {
            dispatch(loadActivitiesFailure());
            dispatch(openSnackbar({ severity: "error", message: "Erro interno" }));
        }
    }
}

export const getActivitiesAnswers = async (dispatch, accessToken, activity_id, group_id) => {
    dispatch(loadActivityAnswersRequest())
    try{
        const res = await axiosInstance.get(`quiz_answers/${activity_id}/${group_id}`, {
            headers: {
                token: "Bearer " + accessToken
            }
        });
        if(res.data.Status) {
            dispatch(loadActivityAnswersFailure());
            dispatch(openSnackbar({ severity: "error", message: res.data.Status }));
        } else {
            dispatch(loadActivityAnswersSuccess(res.data));
        }
    } catch(err) {
        if(err === "Acesso finalizado") {
            dispatch(finishSession())
        } else {
            dispatch(loadActivityAnswersFailure());
            dispatch(openSnackbar({ severity: "error", message: "Erro interno" }));
        }
    }
}

export const getActivitiesCount = async (dispatch, accessToken, type, group_id) => {
    dispatch(loadActivitiesCountRequest());
    try {
        const res = await axiosInstance.get(`/activities_count?type=${type}&group_id=${group_id}`, {
            headers: {
                token: "Bearer " + accessToken
            }
        });
        dispatch(loadActivitiesCountSuccess(res.data));
    } catch(err) {
        if (err === "Acesso finalizado") {
            dispatch(finishSession());
        } else {
            dispatch(loadActivitiesCountFailure());
        }
    }
}

export const getActivitiesAdmin = async (dispatch, accessToken, id, activities) => {
    if (activities?.length > 0) {
        dispatch(loadMoreActivitiesRequest());
    } else {
        dispatch(loadActivitiesRequest());
    }
    try{
        const res = await axiosInstance.get(`allQuiz?last_id=${id}`, {
            headers: {
                token : "Beader " + accessToken
            }
        });
        if(res.data.Status) {
            dispatch(loadActivitiesFailure());
            dispatch(openSnackbar({ severity: "error", message: res.data.Status }));
        } else {
            dispatch(loadActivitiesSuccess(activities.concat(res.data)));
        }
    } catch(err) {
        if(err === "Acesso finalizado") {
            dispatch(finishSession())
        } else {
            dispatch(loadActivitiesFailure());
            dispatch(openSnackbar({ severity: "error", message: "Erro interno" }));
        }
    }
}

export const changeActivityCall = (dispatch, activity) => {
    dispatch(changeActivity(activity));
}

export const changePagination = (dispatch, paginator) => {
    dispatch(changePaginator(paginator));
}

export const getQuestion = async(dispatch, accessToken, position, activity_id, group_alumn_id) => {
    dispatch(loadQuestionRequest());
    try{
        const res = await axiosInstance.get(`question?position=${position}&activity_id=${activity_id}&group_alumn_id=${group_alumn_id}`, {
            headers: {
                token : "Beader " + accessToken
            }
        });
        if(res.data.Status){
            if(res.data.Status === "Nenhuma questão encontrada") {
                dispatch(loadQuestionFailure());
                dispatch(openSnackbar({ severity: "success", message: "Atividade finalizada" }));
            } else {
                dispatch(loadQuestionFailure());
                dispatch(openSnackbar({ severity: "error", message: res.data.Status }));
            }
        } else {
            dispatch(loadQuestionSuccess(res.data));
        }
    } catch(err) {
        if(err === "Acesso finalizado") {
            dispatch(finishSession());
        } else {
            dispatch(loadQuestionFailure());
            dispatch(openSnackbar({ severity: "error", message: "Erro interno" }));
        }
    }
}

export const saveAnswer = async(dispatch, accessToken, answer) => {
    dispatch(loadSaveAnswerRequest());
    try {
        const res = await axiosInstance.post("quiz/answer", answer, {
            headers: {
                token : "Beader " + accessToken
            }
        });
        if(res.data.Status === "Resposta salva") {
            dispatch(loadSaveAnswerSuccess());
            dispatch(openSnackbar({severity: "success", message: res.data.Status}));
        } else {
            dispatch(loadSaveAnswerFailure());
            dispatch(openSnackbar({severity: "error", message: res.data.Status }));
        }
    } catch(err) {
        if(err === "Acesso finalizado") {
            dispatch(finishSession())
        } else {
            dispatch(loadSaveAnswerFailure());
            dispatch(openSnackbar({severity: "error", message: "Erro interno"}));
        }
    }
}

export const setOpenActivity = (dispatch, open, activity) => {
    dispatch(setOpenActivityDialog({dialog: open, activity}));
}

//id = id da quiz_group
export const changeFinalDate = async (dispatch, accessToken, id, data, group_id) => {
    dispatch(loadChangeFinalDateRequest());
    try{
        const res = await axiosInstance.put(`quizTurma/${id}`, data, {
            headers: {
                token : "Beader " + accessToken
            }
        });
        if(res.data.Status === "Dados alterados"){
            setOpenActivity(dispatch, false, {});
            dispatch(loadChangeFinalDateSuccess());
            getActivities(accessToken, dispatch, group_id, 0, []);
            dispatch(openSnackbar({ severity: "success", message: res.data.Status }));
        } else {
            dispatch(loadChangeFinalDateFailure());
            dispatch(openSnackbar({ severity: "error", message: res.data.Status }));
        }
    } catch(err) {
        if(err === "Acesso finalizado") {
            dispatch(finishSession());
        } else {
            dispatch(loadChangeFinalDateFailure());
            dispatch(openSnackbar({ severity: "error", message: "Erro interno" }));
        }
    }
}

export const createFinalDate = async (dispatch, accessToken, activity_id, data, group_id) => {
    dispatch(loadChangeFinalDateRequest());
    try {
        const res = await axiosInstance.post(`quiz/${activity_id}/${group_id}`, data, {
            headers: {
                token : "Beader " + accessToken
            }
        });
        if(res.data.Status === "Data alterada") {
            dispatch(loadChangeFinalDateSuccess());
            getActivities(accessToken, dispatch, group_id, 0, []);
            setOpenActivity(dispatch, false, {});
            dispatch(openSnackbar({ severity: "success", message: res.data.Status }));
        } else {
            dispatch(loadChangeFinalDateFailure());
            dispatch(openSnackbar({ severity: "error", message: res.data.Status }));
        }
    } catch(err) {
        if(err === "Acesso finalizado") {
            dispatch(finishSession());
        } else {
            dispatch(loadChangeFinalDateFailure());
            dispatch(openSnackbar({ severity: "error", message: "Erro interno" }));
        }
    }
}

export const setActivityDialog = (dispatch, open, activity) => {
    dispatch(setCreateUpdateDialog({dialog: open, activity}));
}

export const setActivityDelete = (dispatch, open, activity) => {
    dispatch(setActivityToDelete({ dialog: open, activity }));
}

export const createActivity = async (dispatch, accessToken, activity) => {
    dispatch(loadCreateActivityRequest());
    try {
        const res = await axiosInstance.post(`quiz`, activity, {
            headers: {
                token : "Beader " + accessToken
            }
        });
        if(res.data.Status === "Activity created") {
            dispatch(loadCreateActivitySuccess());
            getActivitiesAdmin(dispatch, accessToken);
            dispatch(openSnackbar({severity: "success", message: res.data.Status}));
        } else {
            dispatch(loadCreateActivityFailure());
            dispatch(openSnackbar({severity: "error", message: res.data.Status}));
        }
    } catch(err) {
        if(err === "Acesso finalizado"){
            dispatch(finishSession());
        } else {
            dispatch(loadCreateActivityFailure());
            dispatch(openSnackbar({severity: "error", message: "Erro interno"}));
        }
    }
}

export const updateActivity = async (dispatch, accessToken, activity) => {
    dispatch(loadUpdateActivityRequest());
    try {
        const res = await axiosInstance.put(`quiz/${activity.id}`, activity, {
            headers: {
                token: "Bearer " + accessToken
            }
        });
        if(res.data.Status === "Activity updated") {
            dispatch(loadUpdateActivitySuccess());
            dispatch(openSnackbar({severity: "success", message: res.data.Status}));
        } else {
            dispatch(loadUpdateActivityFailure());
            dispatch(openSnackbar({severity: "error", message: res.data.Status}));
        }
    } catch(err) {
        if(err === "Acesso finalizado") {
            dispatch(finishSession());
        } else {
            dispatch(loadUpdateActivityFailure());
            dispatch(openSnackbar({severity: "error", message: "Erro interno"}));
        }
    }
}

export const deleteActivity = async (dispatch, accessToken, id) => {
    dispatch(loadDeleteActivityRequest());
    try {
        const res = await axiosInstance.delete(`quiz/${id}`, {
            headers: {
                token : "Beader " + accessToken
            }
        });
        if(res.data.Status === "Activity deleted") {
            dispatch(loadDeleteActivitySuccess());
            getActivitiesAdmin(dispatch, accessToken);
            dispatch(openSnackbar({severity: "success", message: res.data.Status }));
        } else {
            dispatch(loadDeleteActivityFailure());
            dispatch(openSnackbar({severity: "error", message: res.data.Status }));
        }
    } catch(err) {
        if(err === "Acesso finalizado"){
            dispatch(finishSession());
        } else {
            dispatch(loadDeleteActivityFailure());
            dispatch(openSnackbar({severity: "error", message: "Erro interno"}));
        }
    }
}

export const setQuestionDialog = (dispatch, open, question) => {
    dispatch(setCreateQuestionDialog({ dialog: open, question}))
}

export const createQuestion = async (dispatch, accessToken, question) => {
    dispatch(loadCreateQuestionRequest());
    try {
        const res = await axiosInstance.post("create_question", question, {
            headers: {
                token: "Bearer " + accessToken
            }
        });
        if(res.data.Status === "Question created") {
            dispatch(loadCreateQuestionSuccess());
            dispatch(openSnackbar({ severity: "success", message: res.data.Status }));
            getActivityWithQuestions(accessToken, dispatch, question.quiz_id);
            resetDelQuestions(dispatch);
        } else {
            dispatch(loadCreateQuestionFailure());
            dispatch(openSnackbar({ severity: "error", message: res.data.Status }));
        }
    } catch(err) {
        if(err === "Acesso finalizado") {
            dispatch(finishSession());
        } else {
            dispatch(loadCreateQuestionFailure());
            dispatch(openSnackbar({ severity: "error", message: "Erro interno" }));
        }
    }
}

export const updateQuestion = async (dispatch, accessToken, question) => {
    dispatch(loadCreateQuestionRequest());
    try {
        const res = await axiosInstance.put(`update_question`, question, {
            headers: {
                token: "Bearer " + accessToken
            }
        });
        if(res.data.Status === "Question updated") {
            dispatch(loadCreateQuestionSuccess());
            dispatch(openSnackbar({ severity: "success", message: res.data.Status }));
            getActivityWithQuestions(accessToken, dispatch, question.quiz_id);
            resetDelQuestions(dispatch);
        } else {
            dispatch(loadCreateQuestionFailure());
            dispatch(openSnackbar({ severity: "error", message: res.data.Status }));
        }
    } catch(err) {
        if(err === "Acesso finalizado") {
            dispatch(finishSession());
        } else {
            dispatch(loadCreateQuestionFailure());
            dispatch(openSnackbar({ severity: "error", message: "Erro interno"}))
        }
    }
}

export const resetDelQuestions = (dispatch) => {
    dispatch(resetDeleteQuestions());
}

export const setQuestionsDelete = (dispatch, ids) => {
    dispatch(insertQuestionToDelete({ ids }))
}

export const setQuestionsDeleteDialog = (dispatch, ids, dialog) => {
    dispatch(setDeleteQuestionsDialog({ ids, dialog }))
}

export const deleteQuestions = async (dispatch, accessToken, ids, quiz_id) => {
    dispatch(loadDeleteQuestionsRequest());
    try {
        let aux
        for(let i=0; i<ids.length; i++){
            const res = await axiosInstance.delete(`delete_question/${ids[i]}`, {
                headers: {
                    token: "Bearer " + accessToken
                }
            });
            aux = res.data.Status
        }
        if(aux === "Question removed") {
            dispatch(loadDeleteQuestionsSuccess());
            dispatch(openSnackbar({ severity: "success", message: aux }));
            getActivityWithQuestions(accessToken, dispatch, quiz_id);
            resetDelQuestions(dispatch);
        } else {
            dispatch(loadDeleteQuestionsFailure());
            dispatch(openSnackbar({ severity: "error", message: aux }));
        }
    } catch(err) {
        if(err === "Acesso finalizado") {
            dispatch(finishSession());
        } else {
            dispatch(loadDeleteQuestionsFailure());
            dispatch(openSnackbar({ severity: "error", message: "Erro interno"}))
        }
    }
}