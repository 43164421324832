
import { 
    PieChart, 
    Pie, 
    Cell, 
    ResponsiveContainer, 
    Legend, 
    Tooltip 
} from 'recharts';

import { useEffect } from "react";
import { getGeneralStatistics } from "../apiCalls/activityCalls";
import { useSelector } from "react-redux";
import Skeleton from "./Skeleton";
import { 
    Box, 
    Paper, 
    styled 
} from "@mui/material";

const Container = styled(Box)(({ theme }) => ({
    display: "flex",
    width: "90%",
    flexDirection: "column",
    alignItems: "center",
    margin: "10px 0px",

    [theme.breakpoints.down("sm")]: {
        width: "100%",
        justifyContent: "center"
    }
}));

const Wrapper = styled(Paper)({
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center"
});

const SigleChart = styled(Box)(({ theme }) => ({
    display: "flex",
    width: "400px",
    height: "270px",
    flexDirection: "column",

    [theme.breakpoints.down("sm")]: {
        width: "90%",
        height: "270px",
        marginTop: "10px"
    }
}));

const Title = styled("h1")(({ theme }) => ({
    diplay: "flex",
    fontSize: "20px",
    marginLeft: "10px",

    [theme.breakpoints.down("sm")]: {
        marginLeft: "0px",
        fontSize: "18px"
    }
}));



const DatasWrapper = styled(Box)({
    display: "flex",
    flexDirection: "column",
    margin: "10px",
});

const Notice = styled(Box)({
    display: "flex",
    width: "100%",
    justifyContent: "center",
    textAlign: "center",
    marginBottom: "5px"
})

const GeneralStatistics = ({ quiz_id, accessToken, dispatch }) => {

    const { activity, results } = useSelector(state=>state.activity);
    const { group } = useSelector(state=>state.group);


    const COLORS = ['#00C49F', '#E4405F', '#FFBB28', '#FF8042'];

    useEffect(() => {
        if(!group.loading && !activity.loading) {
            getGeneralStatistics(accessToken, dispatch, group.data.id, activity.data.id);
        }
    }, [group.loading, activity.loading, group.data.id, activity.data.id, accessToken, dispatch]);

    return (
        <Container>
            {
                !activity.loading
                ? <Wrapper>
                    <SigleChart>
                        <Title>{activity.data?.title}</Title>
                        {
                            !results.loading 
                            && (
                                results.data?.answers_count !== 0
                                ? <ResponsiveContainer width="100%" height="70%" >
                                    <PieChart onMouseEnter={this?.onPieEnter}>
                                        <Pie
                                            data={[
                                                { name: 'Acertos', value: parseInt(results.data.hits)},
                                                { name: 'Erros', value: ((results.data.answers_count - parseInt(results.data.hits)) - results.data?.open_question_count || 0)},
                                            ]}
                                            cx={80}
                                            cy={75}
                                            innerRadius={60}
                                            outerRadius={80}
                                            fill="#8884d8"
                                            paddingAngle={3}
                                            dataKey="value"
                                        >
                                            {
                                                Array.from({ length: 2 }).map((entry, index) => (
                                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                                ))
                                            }
                                        </Pie>
                                        <Legend />
                                        <Tooltip />
                                    </PieChart>
                                </ResponsiveContainer> 
                                : <div style={{width: "100%", textAlign: "center"}}>NENHUMA RESPOSTA!</div>
                            )
                        }
                    </SigleChart>
                    <DatasWrapper>
                        <span>Quantidade de alunos: <b>{group.data?.alumns?.length || 0}</b></span>
                        <span>Quantidade de questoes: <b>{activity.data?.question_count || 0}</b></span>
                        <span>Respostas: <b>{(results.data?.answers_count || 0) + "/" + ((activity.data?.question_count * group.data?.alumns?.length) || 0)}</b></span>
                    </DatasWrapper>
                    <Notice><b>QUESTÕES ABERTAS NÃO SÃO COMPUTADAS NO GRÁFICO</b></Notice>
                </Wrapper>
                : <Skeleton type="statistics_general" />
            }
            
        </Container>
    )
}

export default GeneralStatistics
