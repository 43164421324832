import { 
    Button, 
    Dialog, 
    DialogActions, 
    DialogContent, 
    DialogContentText, 
    DialogTitle
} from '@mui/material';

import { useSelector } from 'react-redux';
import { deleteQuestions, setQuestionsDeleteDialog } from '../../../../apiCalls/activityCalls';

const DeleteQuestionDialog = ({ dispatch, accessToken, id }) => {

    const { ids, dialog } = useSelector(state=>state.activity.deleteQuestions);

    const handleDialog = () => {
        setQuestionsDeleteDialog(dispatch, ids, false);
    }

    const handleDelete = () => {
        deleteQuestions(dispatch, accessToken, ids, id);
    }

    return <Dialog 
        id="dialog_delete" 
        open={dialog}
        onClose={handleDialog}
    >
        <DialogTitle id="dialog_activity_delete_title" align="center">
            DELETE QUESTION
        </DialogTitle>
        <DialogContent>
            <DialogContentText align="center">
                {"Are you sure you want to delete "} 
                <b>PERMANENTLY</b>
                {" the following question(s): "}
                <b>
                    ids: 
                    {
                        ids.toString()
                    }
                </b>
            </DialogContentText>
        </DialogContent>
        <DialogActions
            sx={{
                display: "flex",
                justifyContent: "space-between"
            }}
        >
            <Button
                variant="contained"
                onClick={handleDialog}
            >
                BACK
            </Button>
            <Button
                variant="contained"
                onClick={handleDelete}
            >
                DELETE
            </Button>
        </DialogActions>
    </Dialog>
}

export default DeleteQuestionDialog