import moment from "moment";

import { useNavigate } from "react-router-dom";
import { Box, styled, Tooltip } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';

import { resetRemoveGroup, setGroupCreate, setGroupDelete } from '../apiCalls/groupCalls';

const Container = styled(Box)(({ theme }) => ({
    display: "flex",
    width: "200px",
    height: "200px",
    alignItems: "center",
    flexDirection: "column",
    backgroundColor: "#c0c1c2",
    margin: "20px",
    borderRadius: "10px",

    [theme.breakpoints.down("sm")]: {
        width: "180px",
        height: "180px",
        margin: "5px"
    }
}));

const Top = styled(Box)({
    display: "flex",
    width: "100%",
    height: "80%",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column"
});

const Header = styled(Box)({
    display: "flex",
    width: "100%",
    height: "30%",
    borderRadius: "10px 10px 0px 0px",
    backgroundColor: "#38B6FF",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    cursor: "pointer"
});

const Title = styled("span")(({ theme }) => ({
    width: "177px",
    whiteSpace: "nowrap",              
    overflow: "hidden", 
    textOverflow: "ellipsis",
    fontSize: "20px",
    fontWeight: "bold",
    color: "white",

    [theme.breakpoints.down("sm")]: {
        width: "165px",
        fontSize: "16px"
    }
}));

const Periode = styled("span")(({ theme }) => ({
    display: "flex",
    width: "90%",
    fontSize: "12px",
    color: "white",

    [theme.breakpoints.down("sm")]: {
        fontSize: "10px"
    }
}));

const Content = styled(Box)({
    display: "flex",
    width: "100%",
    height: "70%",
    alignItems: "center",
    flexDirection: "column",
    overflowY: "auto"
});

const ActivityInfos = styled(Box)({
    display: "flex",
    width: "95%",
    minHeight: "40px",
    flexDirection: "column",
    margin: "2px 0px",
    borderBottom: "1px solid lightgray"
});

const ActivityName = styled("span")({
    maxWidth: "165px",
    whiteSpace: "nowrap",              
    overflow: "hidden", 
    textOverflow: "ellipsis"
});

const FinalDate = styled("span")({
    maxWidth: "165px",
    fontSize: "12px",
    overflow: "hidden",
    textOverflow: "ellipsis"
})

const Bottom = styled(Box)({
    display: "flex",
    width: "100%",
    height: "20%",
    alignItems: "center",
    borderRadius: "0px 0px 10px 10px",
});

const Icon = styled(Box)(({ theme }) => ({
    display: "flex",
    width: "30px",
    height: "30px",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    borderRadius: "50%",
    margin: "0px 5px",

    "&:hover": {
        backgroundColor: "#b9e5ff"
    },

    [theme.breakpoints.down("sm")]: {
        width: "20px",
        height: "20px",
    }
}));

export default function TurmaDemo({group, userVariables, dispatch}) {
    
    const navigate = useNavigate();

    const clickTurma = (e) => {
        e.preventDefault();
        resetRemoveGroup(dispatch);
        navigate(`/group_dashboard/${group.id}`);
    }

    const handleEdit = () => {
        setGroupCreate(dispatch, true, false, false, group);
    }

    const handleDelete = () => {
        setGroupDelete(dispatch, group, true, false);
    }


    return (
        <Container>
            <Top>
                <Tooltip
                    title="Acessar grupo"
                >
                    <Header onClick ={clickTurma}>
                            {
                                group?.title
                                && <Title>{
                                    group.title.length > 18 ? 
                                    (group.title.substring(0,15) + "...").toUpperCase() :
                                    group.title.toUpperCase()
                                }</Title>
                            }
                            <Periode>{group?.periode}</Periode>
                    </Header>
                </Tooltip>
                <Content>
                    {
                        group?.dates?.map((d, index) => {
                            const quiz_date = moment(d.final_date);
                            const present_date = moment(new Date());
                            const remain_days = quiz_date.diff(present_date, 'days')
                            const remain_hours = quiz_date.diff(present_date, 'hours');
                            const is_minor_a_day = remain_hours < 24 && remain_hours > 0
                            return <Tooltip title="Informações sobre a atividade" key={index}>
                                <ActivityInfos>
                                    <ActivityName>{ d.quiz.title }</ActivityName>
                                    <FinalDate>Prazo: { 
                                        is_minor_a_day
                                        ? remain_hours + " hora(s) restante(s)"
                                        : (
                                            remain_days > 0 
                                            ? remain_days + " dia(s) restante(s)" 
                                            : "Encerrado"
                                        )
                                    }</FinalDate>
                                </ActivityInfos>
                            </Tooltip>
                        })
                    }
                </Content>
            </Top>
            <Bottom>
                {
                    userVariables.is_teacher
                    && <>
                        <Tooltip title="Editar">
                            <Icon onClick={handleEdit}>
                                <Edit />
                            </Icon>
                        </Tooltip>
                        <Tooltip title="Deletar">
                            <Icon onClick={handleDelete}>
                                <Delete />
                            </Icon>
                        </Tooltip>
                    </>
                }
            </Bottom>
        </Container>
    );
}