import { useEffect, useState } from "react"

export default function CalculateMultiple(answers){
    const [loadingMultiple, setLoadingMultiple] = useState(false);
    const [a, setA] = useState(0);
    const [b, setB] = useState(0);
    const [c, setC] = useState(0);
    const [d, setD] = useState(0);
    const [hasAnswersMultiple, setHasAnswersMultiple] = useState(false);

    useEffect(() => {
        if(answers.length > 0) {
            setHasAnswersMultiple(true);
            setLoadingMultiple(true);
            for (let val of answers) {
                if (val.question_answer === "A") {
                    setA(prev => prev+1)
                } else if (val.question_answer === "B") {
                    setB(prev => prev+1)
                } else if (val.question_answer === "C") {
                    setC(prev => prev+1)
                } else if (val.question_answer === "D") {
                    setD(prev => prev+1)
                }
            }
            setLoadingMultiple(false);
        }
    }, [answers]);

    return { 
        loadingMultiple, 
        valuesMultiple: [
            { title: "A", a},
            { title: "B", b},
            { title: "C", c},
            { title: "D", d},
        ],
        hasAnswersMultiple
    }
}