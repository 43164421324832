import axios from "axios";

import {
    loadSendContactRequest,
    loadSendContactSuccess,
    loadSendContactFailure
} from "../redux/contactSlice";

import { openSnackbar } from "../redux/snackbarSlice";

export const sendContact = async(dispatch, contact) => {
    dispatch(loadSendContactRequest());
    const PBD = 'https://screening-programming-backend.herokuapp.com/api/';
    //const PBD = 'http://localhost:5000/api/';
    try{
        const res = await axios.post(PBD + "contato", contact);
        if(res.data === "Mensagem enviada"){
            dispatch(loadSendContactSuccess());
            dispatch(openSnackbar({ severity: "success", message: res.data }));
        } else {
            dispatch(loadSendContactFailure());
            dispatch(openSnackbar({ severity: "error", message: "Contato não enviado" }));
        }
    } catch(err){
        dispatch(loadSendContactFailure());
        dispatch(openSnackbar({ severity: "error", message: "Erro interno" }));
    }
}