import { Navigate } from "react-router-dom";

//enabled to access only without login
const ProtectedRoute = ({ children, accessToken, redirectTo}) => {
    if (!accessToken) {
        return children
    }

    return <Navigate to={redirectTo} />
}

export default ProtectedRoute;