import { 
    Button, 
    Dialog, 
    DialogActions, 
    DialogContent, 
    DialogContentText, 
    DialogTitle, 
    styled, 
    Tooltip 
} from "@mui/material";
import { useSelector } from "react-redux";
import { removeGroup, setGroupDelete } from "../../../apiCalls/groupCalls";

const DialogActionsStyled = styled(DialogActions)({
    display: "flex",
    justifyContent: "space-between"
});

const DeleteGroup = ({ accessToken, dispatch, userVariables }) => {
    
    const { data, dialog, single } = useSelector(state=>state.group.remove);
    
    const handleDelete = () => {
        removeGroup(accessToken, dispatch, data.id, single, userVariables.is_admin, userVariables.id);
    }

    const handleDialog = () => {
        setGroupDelete(dispatch, {}, false, false);
    }

    return (
        <Dialog 
            id="dialog_delete" 
            open={dialog}
            onClose={() => handleDialog}
        >
            <DialogTitle id="dialog_delete_title" align="center">
                REMOVER GRUPO
            </DialogTitle>
            <DialogContent id="dialog_delete_content" >
                <DialogContentText align="center">Deseja realmente remover o grupo: <b>{data?.title}</b>?</DialogContentText>
            </DialogContent>
            <DialogActionsStyled id="dialog_delete_actions" >
                <Tooltip title="Cancelar remoção">
                    <Button
                        id="btn_cancel"
                        onClick={handleDialog}
                        size="small"
                        variant="contained"
                        disabled={false}
                    >
                        CANCELAR
                    </Button>
                </Tooltip>

                <Tooltip title="Remover o grupo">
                    <Button
                        id="btn_remove_yes"
                        onClick={handleDelete}
                        size="small"
                        variant="contained"
                        disabled={false}
                    >
                        REMOVER
                    </Button>
                </Tooltip>
            </DialogActionsStyled>
        </Dialog>
    )
}

export default DeleteGroup