import { 
    Box, 
    Dialog, 
    DialogContent, 
    DialogTitle, 
    Table, 
    TableBody, 
    TableCell, 
    TableContainer, 
    TableHead, 
    TableRow, 
    Typography 
} from "@mui/material"
import { useEffect, useState } from "react";
import { useSelector } from "react-redux"
import { getUserResults, showAlumnResultDialog } from "../../../apiCalls/userCalls";
import Skeleton from "../../Skeleton";


const AlumnResultDialog = ({ dispatch, userVariables, accessToken, id }) => {

    const { data, alumn, dialog, loading } = useSelector(state=>state.user.result);

    const [general, setGeneral] = useState({});

    useEffect(() => {
        if(alumn?.id && accessToken && id != null){
            getUserResults(dispatch, accessToken, id, alumn.id);
        }
    }, [alumn?.id, accessToken, dispatch, id]);

    useEffect(()=>{
        let idx = {
            acertos: 0,
            question_count: 0
        }
        if(data.length > 0){
            for(let i=0;i<data.length;i++){
                idx.acertos += parseInt(data[i].quiz.hit || 0);
                idx.question_count += (data[i].quiz.question_count || 0); 
            }
        }
        setGeneral(idx);
    }, [data]);

    const handleDialog = () => {
        showAlumnResultDialog(dispatch, false, 0);
    }

    return <Dialog
        id="dialog-create"
        open={dialog}
        onClose={handleDialog}
    >
        <Box sx={{overflowX: "auto"}}>
            <DialogTitle align="center">
            {alumn?.username}
            </DialogTitle>
            <DialogContent>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center" sx={{minWidth: "200px"}}>
                                        <b>
                                            {
                                                userVariables.is_admin ? "ACTIVITY" : "ATIVIDADE"
                                            }
                                        </b>
                                    </TableCell>
                                    <TableCell align="center">
                                        <b>
                                            {
                                                userVariables.is_admin ? "HIT(%)" : "ACERTO(%)"
                                            }
                                        </b>
                                    </TableCell>
                                    <TableCell align="center"><b>SCORE</b></TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {
                                    !loading
                                    ? data.map((item, index) => {
                                        return <TableRow key={index}>
                                            <TableCell align="center" sx={{minWidth: "200px"}}>
                                                <Typography variant="caption">{item?.quiz?.title}</Typography>
                                            </TableCell>
                                            <TableCell align="center">
                                                <Typography variant="caption">{
                                                    `${parseInt(((item?.quiz?.hit || 0)/(item?.quiz?.question_count || 1)) * 100)} %`
                                                }</Typography>
                                            </TableCell>
                                            <TableCell align="center">
                                                <Typography variant="caption">{
                                                        `${item?.quiz?.hit || 0}/${item?.quiz?.question_count}`
                                                    }</Typography>
                                            </TableCell>
                                        </TableRow>
                                    })
                                    : <TableRow>
                                        <TableCell align="center" sx={{ minWidth: "200px" }}>
                                            <Skeleton type="text" width="200px" />
                                        </TableCell>
                                        <TableCell align="center">
                                            <Skeleton type="text" width="120px" />
                                        </TableCell>
                                        <TableCell align="center">
                                            <Skeleton type="text" width="120px" />
                                        </TableCell>
                                    </TableRow>
                                }
                                <TableRow>
                                    <TableCell align="center" sx={{minWidth: "200px"}}>
                                        <Typography variant="caption">
                                            TOTAL
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        {
                                            !loading
                                            ? <Typography variant="caption">
                                                {`${parseInt((general?.acertos/general?.question_count) * 100) || 0} %`}
                                            </Typography>
                                            : <Skeleton type="text" width="100px" />
                                        }
                                    </TableCell>
                                    <TableCell align="center">
                                        {
                                            !loading
                                            ? <Typography variant="caption">{
                                                `${general?.acertos}/${general?.question_count}`
                                            }</Typography>
                                            : <Skeleton type="text" width="100px" />
                                        }
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
            </DialogContent>
        </Box>
    </Dialog>
}

export default AlumnResultDialog