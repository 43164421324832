import { Alert, Snackbar } from '@mui/material'
import React from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { close } from '../../apiCalls/snackbarCalls';

const GeneralSnackbar = () => {

    const { open, severity, message } = useSelector(state=>state.snackbar.data)
    const dispatch = useDispatch();

    return (
        <Snackbar 
            open={open} 
            autoHideDuration={3000}
            onClose={() => close(dispatch, false)}
            anchorOrigin={{ vertical: 'top',horizontal: 'right' }}
        >
            <Alert 
                onClose={() => close(dispatch, false)}
                severity={severity}
            >
                {
                    message + "!"
                }
            </Alert>
        </Snackbar>
    )
}

export default GeneralSnackbar