import { Box } from '@material-ui/core'
import { CloseOutlined } from '@mui/icons-material';
import { 
    Dialog, 
    DialogContent,
    styled 
} from '@mui/material'
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { setFile } from '../../../apiCalls/imageCalls'
import Skeleton from '../../Skeleton';

const BoxContent = styled(Box)(({ theme }) => ({
    width: "100%",
    minHeight: "300px",

    [theme.breakpoints.down("sm")]: {
        minHeight: "150px"
    }
}));

const BoxClose = styled(Box)(({ theme }) => ({
    display: "flex",
    width: "28px",
    height: "28px",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    position: "absolute",
    borderRadius: "50%",
    top: "5px",
    right: "10px",
    backgroundColor: "#1976d2",
    color: "white",

    [theme.breakpoints.down("sm")]: {
        width: "26px",
        height: "26px"
    }
}));


const ShowImageDialog = () => {

    const { data, dialog } = useSelector(state=>state.image.file);
    const [loaded, setLoaded] = useState(false);

    //const PF = 'https://screening-programming-backend.herokuapp.com/images/';

    const dispatch = useDispatch();

    const handleDialog = () => {
        setFile(dispatch, {}, false);
    }

    console.log(data);

    return (
        <Dialog
            id="dialog-show-image"
            open={dialog}
            onClose={handleDialog}
            fullWidth={true}
            maxWidth={'md'}
        >
            <DialogContent 
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    position: "relative"
                }}
            >
                <BoxClose onClick={handleDialog}>
                    <CloseOutlined />
                </BoxClose>
                <BoxContent>
                    {
                        data != null
                        && <img
                            style={ 
                                loaded ? {
                                    width: "100%",
                                    objectFit: "contain",
                                }
                                : { display: 'none' }
                            }
                            src = {`https://drive.google.com/uc?export=view&id=${data}`}
                            alt = ""
                            loading = "lazy"
                            onLoad={() => setLoaded(true)}
                        />
                    }
                </BoxContent>
            </DialogContent>
        </Dialog>
    )
}

export default ShowImageDialog