import { useEffect, useState } from 'react'
//import styled from "styled-components";
import jwt_decode from 'jwt-decode';
import { useParams, useNavigate } from 'react-router-dom';

import {
    Box,
    Tabs,
    Tab,
    styled
} from "@mui/material";



import Activities from '../components/Tabs/Activities';
import Statistics from '../components/Tabs/Statistics';
import Turma from '../components/Tabs/Turma';
import AlumnActivities from "../components/Tabs/AlumnActivities";
import AlunoResults from "../components/Tabs/AlunoResults"
import { useSelector } from 'react-redux';
import { 
    BarChart, 
    //EmojiEvents, 
    Home, 
    ListAlt 
} from '@mui/icons-material';
//import Achievements from '../components/Tabs/Achievements';

import { useDispatch } from 'react-redux';
import FullImageDialog from "../components/dialogs/image/show.image.dialog";
import AlumnResultDialog from '../components/dialogs/user/alumn.result.dialog';
import InviteAlumnDialog from '../components/dialogs/group/invite.alumn';
import Footer from '../components/Footer';
import ChangeFinalDateDialog from '../components/dialogs/activity/change.final.date.dialog';
import DeleteGroupDialog from "../components/dialogs/group/delete.group";
import { resetUserList } from '../apiCalls/userCalls';
import { resetGroupList } from '../apiCalls/groupCalls';
import { resetActivityList } from '../apiCalls/activityCalls';

const BoxStyled = styled(Box)({
    display: "flex",
    width: "100%",
    height: "100%",
    flexDirection: "column",
    overflowY: "visible",
    overflowX: "hidden",
    justifyContent: "space-between"
});

const TabsLG = styled(Tabs)(({ theme }) => ({
    display: "flex",

    [theme.breakpoints.down("sm")]: {
        display: "none"
    }
}));

const TabsSM = styled(Tabs)(({ theme }) => ({
    display: "none",
    
    [theme.breakpoints.down("sm")]: {
        display: "flex"
    }
}));

const TurmaDashboard = () => {

    const { id } = useParams();
    
    const { data } = useSelector(state=>state.user.accessToken);
    const { success } = useSelector(state=>state.group.remove);
    const { invite } = useSelector(state=>state.user);
    const { finishSession } = useSelector(state=>state.user.finish);
    
    const [activated, setActivated] = useState(0);
    //const [show, setShow] = useState(false);
    const [userVariables, setUserVariables] = useState({});


    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(()=> {
        if(finishSession){
            navigate("/finishSession")
        }
    }, [finishSession, navigate]);

    useEffect(() => {
        if(data){
          const getDados = async () => {
            const decodedToken = jwt_decode(data);
            setUserVariables(decodedToken);
          }
          getDados();
        }
        resetUserList(dispatch);
        resetGroupList(dispatch);
        resetActivityList(dispatch);
    }, [data, dispatch]);

    useEffect(() => {
        if (success) {
            navigate("/")
        }
    }, [success, invite, navigate]);

    /*useEffect(() => {
        if(activated === 0){
            clearActivities(dispatch)
            clearResults(dispatch)
            clearActivity(dispatch)
        } else if(activated === 1) {
            clearUsersList(dispatch)
            clearResults(dispatch)
            clearActivity(dispatch)
        } else if(activated === 2) {
            clearUsersList(dispatch)
        }
    }, [activated]);*/

    const handleChange = (e, newValue) => {
        setActivated(newValue);
    }

    return (
        <BoxStyled>
            <FullImageDialog />
            <AlumnResultDialog
                dispatch={dispatch}
                accessToken={data}
                userVariables={userVariables}
                id={id}
            />
            <InviteAlumnDialog 
                dispatch={dispatch}
                accessToken={data}
                userVariables={userVariables}
                id={parseInt(id)}
            />
            <ChangeFinalDateDialog
                userVariables={userVariables}
                dispatch={dispatch}
                accessToken={data}
            />
            <DeleteGroupDialog
                accessToken={data}
                dispatch={dispatch}
                userVariables={userVariables}
            />
            <Box mb={0.5}>
                <TabsLG value={activated} onChange={handleChange} centered>
                    <Tab label={userVariables.is_admin ? "HOME" : "INÍCIO"} />
                    <Tab label={userVariables.is_admin ? "ACTIVITIES" : "ATIVIDADES"} />
                    {/*<Tab label={userVariables.is_admin ? "ACHIEVEMENTS" : "CONQUISTAS"} />*/}
                    {
                        (userVariables.is_teacher || userVariables.is_admin)
                        ? <Tab label={userVariables.is_admin ? "STATISTICS" : "ESTATÍSTICAS"} />
                        : <Tab label="RESULTADOS" />
                    }
                </TabsLG>
                <TabsSM value={activated} onChange={handleChange} centered>
                    <Tab icon={<Home />} />
                    <Tab icon={<ListAlt />} />
                    {/*<Tab icon={<EmojiEvents />} />*/}
                    <Tab icon={<BarChart />} />
                </TabsSM>
                {
                    activated === 0 
                    && <Turma id={id} userVariables={userVariables} accessToken={data}/>
                }
                {
                    activated === 1 
                    && (userVariables.is_teacher || userVariables.is_admin
                    ? <Activities 
                        id={id} 
                        accessToken={data} 
                    />
                    : <AlumnActivities 
                        id={id}
                        userVariables={userVariables} 
                        //click={showResults} 
                        accessToken={data}
                    />)
                }
                {
                    activated === 2
                    && ((userVariables.is_teacher || userVariables.is_admin)
                    ? <Statistics id={id} accessToken={data} userVariables={userVariables} />
                    : <AlunoResults userVariables={userVariables} accessToken={data} id={id} />)
                }
                {/*
                    activated === 2
                    && <Achievements accessToken={data} userVariables={userVariables} />
                */}
            </Box>
            <Footer />
        </BoxStyled>
    )
}

export default TurmaDashboard
